<div class="aupris_main my-full-screen-dialog" *ngIf="ZahlungSpans && data.type != 'redirectToApp'">
    <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">
        {{languageData['Bestelloptionen']['Var_ratepayment']|| 'Ratenzahlung' }}
    </div>
    <form [formGroup]="ratenzahlungForm">
        <div style="display: flex;margin-top: 35px;">
            {{languageData['Bestelloptionen']['Var_duration']|| 'Laufzeit' }}:
            <div class="auform-group" style="width: 100%;margin-left: 19px;margin-top: -8px;">
                <select class="auform-control" formControlName="span">
                    <option value=""></option>
                    <option *ngFor="let span of ZahlungSpans" [ngValue]="span.zahlungRate">{{span.zahlungRate}}
                        {{languageData['Bestelloptionen']['Var_month']|| 'Monate' }}
                    </option>
                </select>
            </div>
        </div>

        <div class="text-right">
            <button class="au-btn btn-default" type="submit" [disabled]="!ratenzahlungForm.valid"
                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="close()"
                style="margin-top:5px"> {{languageData['Bestelloptionen']['Var_close']|| 'Schließen' }}</button>
        </div>
    </form>
</div>
<div class="aupris_main my-full-screen-dialog" *ngIf="!ZahlungSpans">
    <div style="font-size:20px;font-weight: bold;margin-bottom: 10px;text-align:center !important">
        {{languageData['Bestelloptionen']['Var_no_rates_available']|| 'Ratenzahlung nicht möglich' }}
    </div>

    <div style="display: flex;">
        {{languageData['Bestelloptionen']['Var_amount_ordered_tohigh']|| 'Eventuell ist die Bestellmenge zu hoch. Ändern Sie diese und versuchen Sie es erneut. Sollte der Fehler weiter bestehen, wählen Sie eine andere Zahlungsart.' }}
    </div>

    <div style="text-align: right !important;">
        <button class="au-btn btn-default" type="button"
            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="close()"
            style="margin-top:5px"> {{languageData['Bestelloptionen']['Var_close']|| 'Schließen' }}</button>
    </div>
</div>

<!-- Redirection to Customerapp for Play store/ App store popup Data -->

<div *ngIf="data?.type == 'redirectToApp'" panelClass="apppopup">
<h2 style="margin-top: -6px;text-align: center;margin-bottom: 11px;">
    Explore in...
</h2>
<div style="height: 37px;width: 300px;margin-bottom: 20px;">
    <div class="col-lg-3 col-md-3 col-sm-4 col-xs-4" style="height: 50px;">
   <b style="position: relative;top: 8px;"> {{'Aupris kudenapp'}}  </b>
<button class="btnpopup" style="background-color: var(--primaryColour);" (click)="redirectionToStore()">Open</button>
</div>

<div>
   <b style="position: relative;top: 8px;"> {{'Web Browser'}} </b>
    <button class="btnpopup" (click)="close()" style="color: blue;">Continue</button>
</div>
</div>