import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from '../../Services/language.service';

@Component({
  selector: 'app-rate-payment-rejected-info',
  templateUrl: './rate-payment-rejected-info.component.html',
  styleUrls: ['./rate-payment-rejected-info.component.css']
})
export class RatePaymentRejectedInfoComponent implements OnInit {
  languageData: any;
  colors: any;
  otherdata: any;
  validationErrorInfo: any;
  reverifyBankDetails: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private langService: LanguageService,
    public dialogRef: MatDialogRef<RatePaymentRejectedInfoComponent>) {
      console.log(data,300)

    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  }

  ngOnInit() {
  
  }
  onclick(firstStage: boolean) {
    this.dialogRef.close(firstStage);
  }

}
