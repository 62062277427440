import { Component, OnInit, Input, EventEmitter, Output, ViewChild, } from '@angular/core';
import { FormGroup, FormArray, ValidatorFn, AbstractControl, Validators, } from '@angular/forms';
import { AdminService } from '../../Services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { DataComponent } from '../../data/data.component';
import { DateAdapter } from '@angular/material/core';
import { UserAddress } from '../../models/portalModels/portalUserAddress.interface';
import { LocalstorgeService } from '../../Services/localstorge.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { BigpcService } from 'app/Services/bigpc.service';
import { environment } from 'environments/environment';
import { LanguageService } from 'app/Services/language.service';
import { SafePipe } from '../../pipes/safe.pipe';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete/autocomplete-trigger';
import { MatAutocomplete } from '@angular/material/autocomplete';

@Component({
  selector: 'app-stagetwo',
  templateUrl: './stagetwo.component.html',
  styleUrls: ['./stagetwo.component.css'],
  providers: [SafePipe]
})
export class StagetwoComponent implements OnInit {

  today = new Date();
  @Input() selectedBiilingOption: any;

  svgs = [
    environment.assetsUrl + 'assets/infoSign.svg',
    environment.assetsUrl + 'assets/svgs/tickMark.svg',
    environment.assetsUrl + 'assets/svgs/dobsvg.svg',
  ];

  @Input("productInfoForWebPC") productInfoForWebPC: any = {};
  @Input() languageData: any;
  @Input() addNewAddress: boolean = false;
  @Output() addNewAddressCancel = new EventEmitter();

  @Input() stationFormGroup: FormGroup;
  @Input() stationCount;
  @Input() dobRechnungsadresse: boolean;
  @Input() dobText: any;
  @Input() isLoggedIn: boolean;
  @Input() Geburtstag: boolean;
  @Input() clickedPage: string;

  @Output() changeAddress = new EventEmitter();
  @Input() colors: any;
  @Input() billingAddressEnable: boolean;
  @Input() mengecheck: boolean;
  @Input() RemainingMenge: number;
  @Input() selectedQuantity: any;
  @Output() geburtstagPopupEvent = new EventEmitter();
  @Output() rechnungCheckBoxEvent = new EventEmitter();
  @Output() rechnungtoggleEvent = new EventEmitter();
  @Output() onBlurEvent = new EventEmitter();
  @Output() page1Event = new EventEmitter();
  @Output() weiterEvent = new EventEmitter();
  @Output() addAdressStagetwo = new EventEmitter();
  @Output() setDefaultAddressesEvent = new EventEmitter();
  @Input() mandatoryCheck: boolean;
  @Input() primaryAddresses: UserAddress[];
  @Input() secondaryAddresses: UserAddress[];
  @Input() billingAddresses: UserAddress[];
  @Input() addressType: string;
  public gender = [
    { name: "" },
    { name: "Herr" },
    { name: "Frau" },
    { name: "Divers" },
  ];
  dob: boolean;
  isPortalUser: boolean = false;
  matchPlz: any;

  editableAddress: any;
  lieferIndex: any;
  editAddress: boolean = false;

  localSessionUpdatedData: any;

  plzInfo: any;
  plzId: any;
  languageVar: any;
  langData: any;
  langData1: any;
  dropdownError: boolean = false;
  @Input()
  set _localSessionUpdatedData(localSessionUpdatedData) {
    this.localSessionUpdatedData = localSessionUpdatedData;
  }
  get _localSessionUpdatedData() {
    return this.localSessionUpdatedData
  };

  public addresses: { deliveryAddress: any[], billingAddress: any[] } = {
    deliveryAddress: [],
    billingAddress: [],
  };

  sessionId: any;
  sessionData: any;
  addressList: any;
  selectedElement: UserAddress;
  @Input() loader: boolean;
  url: any;
  editDocId: any;
  editAddType: any;
  editAddIndex: any;

  maxDistance: any;
  constructor(
    private adminService: AdminService,
    public bigPCService: BigpcService,
    public dialog: MatDialog,
    public localstroage: LocalstorgeService,
    private dateAdapter: DateAdapter<Date>,
    public localstorage: LocalstorgeService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    private languageService: LanguageService,
    private SafePipe: SafePipe,
  ) {
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }

    this.dateAdapter.setLocale('de');
    let userData = JSON.parse(localStorage.getItem('portal_user') || '{}');
    Object.keys(userData).length ? this.isPortalUser = true : this.isPortalUser = false;
    let langData = this.languageService.getLocalLanguageData();
    this.langData = langData["unternehmen"] || {};
    this.langData1 = langData["web-pc"] || {};
    this.languageService.onLanguageChange.subscribe((data: any) => {
      this.langData = data["unternehmen"] || {};
      this.langData1 = data["web-pc"] || {};
    });
  };

  ngOnChanges(): void {
    this.languageVar = this.productInfoForWebPC?.language;
    if (this.addNewAddress) {
      let addSubPage = `&subPage=addAdressen`;
      this.addKeyInUrl(addSubPage);
    };
  };
  selectedPostalCode: string;

  async ngOnInit() {
    // this.enableSocket();
    // console.log(this.stationFormGroup)
    this.getDynamicSVG(this.svgs);
    this.sessionId = this.localstorage.getSessionId();
    this.sessionData = this.localstorage.getSessionData(this.sessionId);
    let placeName = `${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
    this.selectedPostalCode = this.sessionData.postalCode.postalcode + ' ' + this.sessionData.postalCode.city + ' ' + this.sessionData.postalCode.suburb;;
    let countryCode = `${this.sessionData.postalCode.postalcode}`;
    this.plzInfo = `${countryCode} ${placeName}`;
    this.plzId = `${this.sessionData.postalCode.plzId}`;
    this.addNewAddress = false;
    if (this.isPortalUser || this.isLoggedIn) {
      this.loader = true;
      this.adminService.getPortaladdresses().subscribe(result => {
        if (result.success) {
          this.loader = false;
          this.addressList = result.data;
          let plzData = `${this.sessionData.postalCode.postalcode} ${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
          plzData = plzData.replace(/\s/g, '');
          let exitAddess = this.sessionData.orderAddresses;

          if (exitAddess && (exitAddess?.billingAddress.length > 0 || exitAddess?.deliveryAddress.length > 0)) {
            this.setDefaultAddressesEvent.emit(this.sessionData.orderAddresses);
          } else {
            this.matchPlz = this.addressList.find(e => (`${e.zipCode} ${e.city} ${e.suburb}`).replace(/\s/g, '') == plzData);
            if (!this.matchPlz) {
              this.notMatchedAddress(null);
            };
            if (this.matchPlz && !this.stationArray().controls[0]?.get("deliveryAddress").value.firstName) {
              this.setQuantityBasedOnStations();
              this.stationArray().controls[0]?.get("deliveryAddress").reset();
              this.stationArray().controls[0].get("deliveryAddress").get('zipCode').setValue(`${this.matchPlz.zipCode}`);
              this.stationArray().controls[0].get("deliveryAddress").get('ort').setValue(`${this.matchPlz.city} ${this.matchPlz.suburb}`);
              this.stationArray().controls[0].get("deliveryAddress").get('city').setValue(`${this.matchPlz.city}`);
              this.stationArray().controls[0].get("deliveryAddress").get('suburb').setValue(`${this.matchPlz.suburb}`);
              this.addressType = this.billingAddressEnable ? 'billing' : 'primary';
              this.openDialog(this.addressType, null);
            };
          };

          this.url = window.location.href;
          if (this.url.includes("subPage=editAdressen")) {
            var params = new URLSearchParams(this.url);
            this.editDocId = decodeURIComponent(params.get("_id"));

            this.editDocId = decodeURIComponent(params.get("_id"));
            this.editAddType = decodeURIComponent(params.get("addressType"));
            this.editAddIndex = decodeURIComponent(params.get("addressIndex"));
            setTimeout(() => {
              let findEditDoc = (this.addressList || []).find((doc) => doc._id == this.editDocId);
              if (findEditDoc?._id) {
                this.editAddressMethod(findEditDoc, this.editAddType, this.editAddIndex);
              } else {
                let editPage = `&subPage=editAdressen`;
                this.removeKeyInUrl(editPage);
              };
            }, 700);
          };

          if (this.url.includes("subPage=addAdressen")) {
            let addSubPage = `&subPage=addAdressen`;
            this.addNewAddress = true;
            this.addKeyInUrl(addSubPage);
          };
        } else {
          this.loader = false;
        };
      }, (err) => {
        this.loader = false;
      });
    } else {

    };

    this.colors = this.productInfoForWebPC.colors;
    this.selectedBiilingOption = this.sessionData.selectedBiilingOption ? this.sessionData.selectedBiilingOption : "Einzelrechnungent";


    // let sessionData = this.localstroage.getSessionData(this.localstroage.getSessionId());
    // let dobData = sessionData.localOptions.find(obj => obj.lable == "Zahlungsart");
    // this.dob = dobData?.DOB;

    let dobData = this.sessionData.localOptions.find(obj => obj.lable == "Zahlungsart");
    this.dob = dobData?.DOB;
    this.maxDistance = this.productInfoForWebPC.maxDistance;

    this.stationArray().valueChanges.subscribe(formCon => {
      this.stationArray().controls.forEach((form, i) => {
        form.get("deliveryAddress").valueChanges.subscribe(val => {
          if (form.get("deliveryAddress").dirty) {
            Object.keys(form.get("deliveryAddress")['controls']).forEach(field => {
              form.get("deliveryAddress").value[`${field}`] = this.SafePipe.transform(form.get("deliveryAddress").value[`${field}`], 'html');
              if (form.get("deliveryAddress").value[`${field}`] == ' ' || form.get("deliveryAddress").value[`${field}`] == 'null') {
                form.get("deliveryAddress").value[`${field}`] = '';
              };
            });
          };
        });

        if (i == 0) {
          form.get("billingAddress").valueChanges.subscribe(val => {
            if (form.get("billingAddress").dirty) {
              Object.keys(form.get("billingAddress")['controls']).forEach(field => {
                form.get("billingAddress").value[`${field}`] = this.SafePipe.transform(form.get("billingAddress").value[`${field}`], 'html');
                if (form.get("billingAddress").value[`${field}`] == ' ' || form.get("billingAddress").value[`${field}`] == 'null') {
                  form.get("billingAddress").value[`${field}`] = '';
                };
              });
            };
          });
        };
      });
    });
  };

  telephonevalidation(e) {
    this.stationArray().controls[0]?.get("billingAddress").get('phoneNumber').setValidators(Validators.pattern(/^(?=.*\d)[\d\-\(\)\+\.\#\s]*$/));
    this.stationArray().controls[0]?.get("billingAddress").get('phoneNumber').updateValueAndValidity()
    return new RegExp(/^[\+\d]?(?:[\d-/\s()]*)$/).test(e);
  };


  notMatchedAddress(index) {
    let placeName = `${this.sessionData.postalCode.city} ${this.sessionData.postalCode.suburb}`;
    let countryCode = `${this.sessionData.postalCode.postalcode}`;
    this.editAddress = false;
    this.addressType = this.billingAddressEnable ? 'billing' : 'primary';
    this.lieferIndex = index;
    this.addNewAddress = true;
    if (!this.billingAddressEnable) {
      this.stationArray().controls[0]?.get("deliveryAddress").reset();
      this.setQuantityBasedOnStations();
      this.stationArray().controls[0].get("deliveryAddress").get('zipCode').setValue(`${countryCode}`);
      this.stationArray().controls[0].get("deliveryAddress").get('ort').setValue(`${placeName}`);
    } else {
      this.stationArray().controls[0]?.get("billingAddress").reset();
    };
  };

  setQuantityBasedOnStations() {
    if (this.stationCount == 1) {
      this.stationArray().controls[0]?.get("deliveryAddress")?.get('quantity').setValue(this.selectedQuantity);
    };
  };

  openDialog(addressType: string, index: any) {
    let addressData: any;
    this.lieferIndex = index;
    if (addressType == 'primary') {
      addressData = this.stationArray().controls[0]?.get('deliveryAddress').value;
    } else if (addressType == 'secondary') {
      addressData = this.stationArray().controls[index]?.get('deliveryAddress').value;
    } else {
      addressData = this.stationArray().controls[0]?.get('billingAddress').value;
    };

    this.changeAddress.emit({ addressType: addressType, lieferIndex: index, addressData: addressData });
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: 'text' })
        .subscribe((logo) => {
          logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
          logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
          this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
        })
    });
  };

  stationArray(): FormArray {
    return this.stationFormGroup.get('stations') as FormArray;
  };

  check() {
  };

  backButton() {
    this.page1Event.emit()
  };

  ngAfterViewInit(): void {

  };

  weiter() {
    this.weiterEvent.emit();
  };

  geburtstagPopup() {
    this.geburtstagPopupEvent.emit();
  };

  rechnungCheckBox(event) {
    this.rechnungCheckBoxEvent.emit(event);
  };

  billingAdressToggle(event) {
    this.rechnungtoggleEvent.emit(event);
  };

  onBlur() {
    this.onBlurEvent.emit();
  };

  cancelEdit = () => {
    this.editAddress = false;
    this.addNewAddress = false;
    let sendInfo = {
      newAdd: this.addNewAddress,
      addressType: this.addressType,
      index: this.lieferIndex,
    };
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);

    let subPageName = decodeURIComponent(params.get("subPage"));
    let removeSubpage;
    if (subPageName == 'addAdressen') {
      removeSubpage = "&subPage=addAdressen"
      this.removeKeyInUrl(removeSubpage);
    } else if (subPageName == 'editAdressen') {
      this.editDocId = decodeURIComponent(params.get("_id"));
      if (this.editDocId) {
        this.editAddType = decodeURIComponent(params.get("addressType"));
        this.editAddIndex = decodeURIComponent(params.get("addressIndex"));
        removeSubpage = `&subPage=editAdressen&_id=${this.editDocId}&addressType=${this.editAddType}&addressIndex=${this.editAddIndex}`;
        this.removeKeyInUrl(removeSubpage);
      } else {
        removeSubpage = "&subPage=editAdressen"
        this.removeKeyInUrl(removeSubpage);
      };
    };
    this.addNewAddressCancel.emit(sendInfo);
  };

  editAddressMethod(address, addresType, i) {
    this.addNewAddress = true;
    this.editableAddress = address;
    this.addressType = addresType;
    this.lieferIndex = i;
    this.editAddress = true;
    let addSubPage: any;
    if (address?._id) {
      addSubPage = `&subPage=editAdressen&_id=${address?._id}&addressType=${addresType}&addressIndex=${i}`;
    } else {
      addSubPage = `&subPage=editAdressen`;
    };
    this.addKeyInUrl(addSubPage);
  };

  addKeyInUrl(subPageName) {
    console.log(subPageName)
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    window.history.pushState("", document.title, url);
    if (!url.includes(subPageName)) {
      newUrl = `${newUrl}${subPageName}`;
      window.history.replaceState('', document.title, newUrl);
    };
  };

  removeKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    if (newUrl.includes(subPageName)) {
      newUrl = newUrl.replace(subPageName, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  addAdressInStagetwo(event) {
    this.addAdressStagetwo.emit(event);
  };

  msgg: any;
  msg: any;








  datenPopup(id) {
    let deleteDialog = this.dialog.open(DataComponent, {
      data: { id: id, colors: this.colors }
    })
    deleteDialog.afterClosed().subscribe(data => {
    });
  };

  agb() {
    window.open("https://www.easycredit-ratenkauf.de/wp-content/uploads/2022/02/Ergaenzende_AGB_ratenkauf_by_easyCredit-Feb2022.pdf")
  };

  checkMinQuantity(index) {
    let stationQuantity = this.stationArray().controls[index].get("deliveryAddress").value.quantity;
    if (this.productInfoForWebPC.minAmount <= stationQuantity) {
      this.stationArray().controls[index].get('deliveryAddress').get('checkQuantity').setValue(false);
    } else {
      this.stationArray().controls[index].get('deliveryAddress').get('checkQuantity').setValue(true);
    };
  };

  datePatternRegex = /^\d{1,2}\.\d{1,2}\.\d{4}$/;

  onDateChange(event: any, i, addressType) {
    let date = this.datePatternRegex.test(event);
    if (date) {
      let dateSplit = event.split(".");
      if (parseInt(dateSplit[1]) > 12) {
        this.geburtstagValidateEvent({ status: false, value: null, index: i });
      } else {
        let date = dateSplit[2] + '-' + ('0' + dateSplit[1]).slice(-2) + '-' + ('0' + dateSplit[0]).slice(-2);
        if (date) {
          this.geburtstagValidateEvent({ status: true, value: date, index: i, addressType: addressType });
        } else {
          this.geburtstagValidateEvent({ status: false, value: null, index: i, addressType: addressType });
        }
      };
    } else {
      if (event) {
        let takeDate = new Date(event);
        let takeMonth = takeDate.getMonth() + 1;
        let takeDay = takeDate.getDate();
        let takeYear = takeDate.getFullYear();
        let date = takeYear + '-' + ('0' + takeMonth).slice(-2) + '-' + ('0' + takeDay).slice(-2);
        if (date) {
          this.geburtstagValidateEvent({ status: true, value: date, index: i, addressType: addressType });
        } else {
          this.geburtstagValidateEvent({ status: false, value: null, index: i, addressType: addressType });
        };
      } else {
        this.geburtstagValidateEvent({ status: false, value: null, index: i, addressType: addressType });
      }
    };
  };

  geburtstagValidateEvent(event) {
    if (!event.status) {
      if (event.addressType == 'billingAddress') {
        this.stationArray().controls[0].get('billingAddress').get('dateOfBirth').setValue('');
      } else {
        this.stationArray().controls[event.index].get('deliveryAddress').get('dateOfBirth').setValue('');
      }
    } else {
      if (event.addressType == 'billingAddress') {
        this.stationArray().controls[0].get('billingAddress').get('dateOfBirth').setValue(event.value);
      } else {
        this.stationArray().controls[event.index].get('deliveryAddress').get('dateOfBirth').setValue(event.value);
      };
    };
  };

  plzOptions = [];
  // postalCodeErrorMsg: boolean = false;
  bulkorderIndex = 0;
  getPostalCode(e, index) {
    // console.log(e, index)
    if (e.length >= 4) {
      let sessionId = this.localstroage.getSessionId();
      let getSssionData: any = localStorage.getItem(sessionId);
      this.sessionData = JSON.parse(getSssionData);
      let objectname = {
        event: e,
        dynamicProductId: this.sessionData.product._id
      };
      this.bigPCService.postalCode(objectname).subscribe((data: any) => {
        if (data.success) {
          if (data.data && data.data.length > 0) {
            this.plzOptions = data.data;
            let Options = data.data
            this.plzOptions = Options.sort((a, b) => {
              if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
              if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
            });
            this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(false);

            // let postelAddress = this.sessionData.postalCode.postalcode + ' ' + this.sessionData.postalCode.city + ' ' + this.sessionData.postalCode.suburb;
            // let findPostalCode = this.plzOptions.filter(item => (item.ortPlz).trim() == this.selectedPostalCode.trim()) || false;
            // if (findPostalCode && findPostalCode.length == 0 && this.selectedPostalCode) {
            //   return this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(true);
            // }
            // this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(false);
            // console.log(findPostalCode, typeof findPostalCode, 531);
            // console.log(findPostalCode, 533);
            // if (typeof findPostalCode != 'object') {
            //   this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(true);
            // } else {
            //   this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(false);
            // };
          } else {
            this.plzOptions.length = 0;
            this.plzOptions = [];
            this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(true);
            this.setvalidationUpdation(index);
          };
          // this.postalCodeErrorMsg = false;
          // this.bulkorderIndex = index;
          // let postelAddress = this.sessionData.postalCode.postalcode + ' ' + this.sessionData.postalCode.city + ' ' + this.sessionData.postalCode.suburb;
          // let findPostalCode = this.plzOptions.find(item => (item.ortPlz).trim() == postelAddress.trim());
          // if (typeof findPostalCode != 'object') {
          // this.postalCodeErrorMsg = this.bulkorderIndex == index ? true : false;
          // this.bulkorderIndex = index;
          // this.postalCodeErrorMsg = true
          // }
        } else {
          this.plzOptions.length = 0;
          this.plzOptions = [];
          this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(true);
          // this.postalCodeErrorMsg = this.bulkorderIndex == index ? true : false;
          // this.postalCodeErrorMsg = true;
          // this.bulkorderIndex = index;
          this.setvalidationUpdation(index);
        };
      });
    } else {
      this.plzOptions.length = 0;
      this.plzOptions = [];
      // this.postalCodeErrorMsg = false;
      // this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(false);
      // this.stationArray().controls[index].get('deliveryAddress').get('checkRange').setValue(false);
      this.stationArray().controls[index].get('deliveryAddress').get('ort').setValue('')
      this.stationArray().controls[index].get('deliveryAddress').get('lat').setValue('')
      this.stationArray().controls[index].get('deliveryAddress').get('lng').setValue('')
      this.stationArray().controls[index].get('deliveryAddress').get('city').setValue('')
      this.setvalidationUpdation(index);
    };
  };

  setvalidationUpdation(index) {
    this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].setValidators([this.deafultdropdownErrorValidator()]);
  };

  updateError(event, index, inputTouched) {
    setTimeout(() => {
      //  console.log(this.currentIndex , this.previousindex ,5454);
      // console.log('event' , event ,event.target.value ,54545);
      if (!event.target.value) {
        this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].setValidators([this.deafultdropdownErrorValidator()]);
        this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].updateValueAndValidity();
        return
      }
      let value = this.stationArray().controls[index].get('deliveryAddress').get('ort').value;
      let zipcode = this.stationArray().controls[index].get('deliveryAddress').get('zipCode').value;
      if (zipcode && zipcode.length <= 4) {
        this.stationArray().controls[index].get('deliveryAddress').get('ort').setValue('')
        this.stationArray().controls[index].get('deliveryAddress').get('lat').setValue('')
        this.stationArray().controls[index].get('deliveryAddress').get('lng').setValue('')
        this.stationArray().controls[index].get('deliveryAddress').get('city').setValue('')
        this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].setValidators([this.dropdownErrorValidator()]);
        this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].updateValueAndValidity();
        return;
      }
      // console.log(this.stationArray().controls[index].get('deliveryAddress').get('zipCode').dirty ,545);
      if (!value && this.plzOptions.length > 0 && inputTouched) {
        this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].setValidators([this.dropdownErrorValidator()]);
        this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].updateValueAndValidity();
      }
    }, 500);
  };

  dropdownErrorValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const forbidden = control.value === '';
      return forbidden ? { dropdownError: true } : null;
    };
  };

  deafultdropdownErrorValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return null;
    };
  };

  matItemSelect(event, index) {
    this.selectedPostalCode = event.ortPlz;
    this.plzOptions = [];
    this.plzOptions.length = 0;
    // let findPostalCode = this.plzOptions.filter(item => (item.ortPlz).trim() == this.selectedPostalCode.trim()) || false;
    // if (findPostalCode && findPostalCode.length == 0 && this.selectedPostalCode) {
    //   return this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(true);
    // }
    // return this.stationArray().controls[index].get('deliveryAddress').get('wrongPostalCode').setValue(false);
    // event.title = event.country_code + " " + event.place_name + " " + event.suburb;
    // let postalCode = {
    //   postalcode: event.country_code,
    //   plzId: event.plzId,
    //   city: event.place_name,
    //   suburb: event.suburb,
    //   latitude: event.latitude,
    //   longitude: event.longitude,
    // };

    // event = postalCode;
    // let name = 'POSTALCODE';
    // this.updateOptionsEvent.emit({ event, name });
  };

  /** Reference to the autocomplete trigger. */


  selectORT(option, index) {
    // console.log("option", option);
    // console.log("index", index, option, 5711);
    // console.log(this.stationArray().controls[index].get('deliveryAddress').get('ort'));
    option.ort = option.ort ?? '';
    // let selectedPlzRange = Number(this.sessionData.postalCode.travelDistance);
    // let calcDistance = selectedPlzRange + this.maxDistance;
    // console.log(calcDistance, option.traveldistance);
    // if (calcDistance >= option.traveldistance) {
    //   this.stationArray().controls[index].get('deliveryAddress').get('checkRange').setValue(false);
    // } else {
    //   this.stationArray().controls[index].get('deliveryAddress').get('checkRange').setValue(true);
    // };
    this.stationArray().controls[index].get('deliveryAddress').get('ort').setValue(option.place_name + ' ' + option.suburb)
    this.stationArray().controls[index].get('deliveryAddress').get('lat').setValue(option.latitude)
    this.stationArray().controls[index].get('deliveryAddress').get('lng').setValue(option.longitude)
    this.stationArray().controls[index].get('deliveryAddress').get('city').setValue(option.place_name)
    this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].setValidators([this.deafultdropdownErrorValidator()]);
    this.stationArray().controls[index].get("deliveryAddress")['controls']['ort'].updateValueAndValidity();
    this.plzOptions = [];
    this.plzOptions.length = 0;
    // this.stationArray().controls[index].get('deliveryAddress').get('plzId').setValue(option.plzId)
  };

  // resetAutoInput(trigger: MatAutocompleteTrigger, auto: MatAutocomplete) {
  //   // setTimeout(_ => {
  //   auto.options.forEach((item) => {
  //     item.deselect()
  //   });
  //   // this.myControl.reset('');
  //   trigger.openPanel();
  //   // }, 100);
  // }
};