<div class="aupris_main" style="margin:auto" *ngIf="passwordReset">
  <div class="au-container">
    <div class="aurow " style="margin-right: 0px;margin-left: 0px;">
      <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12 ">
        <div class="card">
          <div style="min-height: 120px;">
            <h3>{{languageData?.Edit?.Var_passwort || 'Passwort vergessen?'}}</h3>
            <p style="text-align: initial;">{{languageData?.Edit?.Var_bitte || 'Bitte geben Sie Ihre E-Mail-Adresse ein
              und wir senden Ihnen einen Link, um Ihr Passwort zurückzusetzen'}}</p>
          </div>
          <div class="auform-group">
            <i style="position: absolute;padding-left: 18px;">

                <img [src]="'assets/svgs/mail.svg' | assetsUrl">
              </i>
              <input type="text" class="auform-control image" placeholder="Email" [(ngModel)]="value" #email ='ngModel' [pattern]="emailregex"  required>
            <div class=" alert-danger "  *ngIf="email.invalid && email.touched">{{languageData?.Edit?.Var_EMailisterforderlich || '
              E-Mail ist erforderlich'}}</div>
            <div *ngIf='verify == true' style="color:green;padding-top: 10px;">{{languageData?.Edit?.Var_Bitte || '
              Bitte überprüfen Sie Ihre E-Mails'}}</div>
            <div *ngIf='wrongemail == true' class=" alert-danger text-left">{{languageData?.login?.Var_notfound || 'Benutzer wurde nicht gefunden!'}}</div>
          </div>
          <div style="display: inline-flex;align-items: center;width:100%;">
            <a style="float:left;text-align: left;width: 45%;" (click)="abbrechen()">{{languageData?.Edit?.Var_Abb || '
              Abbrechen'}}</a>
            <span style="float:right;text-align: right;width:65%;">
              <button classh='au-btn' [disabled]="email.invalid" (click)="passwordverification(email)" style="border: 1px solid var(--primaryColour);color: var(--primaryTextColour);"
                [ngStyle]="{'background-color': 'var(--primaryColour)'}">{{languageData?.Edit?.Var_Passwordzurucksetzen
                || 'Passwort zurücksetzen'}}</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-verificationlink *ngIf="forgotPassword" [forgotPassword]="forgotPassword" (registered)="registeredHandler($event)">
</app-verificationlink>
<loader [full-screen]="true" *ngIf="showFullScreenLoading" [color]="'var(--primaryColour)'"></loader>