import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LanguageService } from 'app/language.service';

@Component({
  selector: 'app-payment-continue',
  templateUrl: './payment-continue.component.html',
  styleUrls: ['./payment-continue.component.css']
})
export class PaymentContinueComponent implements OnInit {

  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  popup: any = {};
  paymentError: any =' '
  constructor(public dialog: MatDialog,public dialogRef: MatDialogRef<PaymentContinueComponent>,private langService: LanguageService,) { }

  ngOnInit(): void {
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
      
    });
    console.log(this.languageData)
  }

  onclick(term) {
    if(term == 'continue'){
      this.dialogRef.close({ close: true });
    } else{
      this.dialogRef.close({close:false})
    }
    
  }

}
