import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { SessionData, SmallPcService } from '../Services/small-pc.service';
import { WindowRefService, ICustomWindow } from '../Services/window.service';
import { PortalAuthService } from './auth.service';
import { UserTokenDetails } from '../models/portalModels/userTokenDetails.interface'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { LanguageService } from '../Services/language.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from 'app/common.service';
import * as FileSaver from 'file-saver';
import { UserAuth } from '../models/portalModels/userAuth.interface'
import { InfoPasswordPopupComponent } from 'app/info-password-popup/info-password-popup.component';
import { BigpcService } from 'app/Services/bigpc.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { OrderService } from 'app/Services/order.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  ftpassword: boolean = false;
  @Output('register') register = false;
  @Output() registered = new EventEmitter<boolean>();
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any = {};
  hostname: any;
  url: any;
  private _window: ICustomWindow;
  user_registration: any;
  user_tracking: any;
  userAuth: UserAuth
  domainname: string;
  userDetailsForm: FormGroup;
  userLoginForm: FormGroup;
  public emailregex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public pswdregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/;
  user_exists = false;
  user_notVerified = false;
  user_not_found = false;
  loginErrorMessage: string = '';
  href: string;
  newPcStart: boolean = false;
  userCreatedMessage: string;
  google_auth: any = false;
  fb_auth: any = false;
  Product: string;
  Liter: string;
  view: string;
  Lieferort: string;
  fbemail: any;
  userCreated: boolean = false;
  user_existsalready: boolean = false;
  theme: string = '';
  @Input() registration_at_order: any;
  minMaxData: any;
  bigpclink: any;
  smallpclink: any;
  params: URLSearchParams;
  disableAnchor: boolean = false;
  pdf: any;
  pdf1: any;
  pdf2: any;
  unterName: any;
  passmismatch: boolean = false;
  isShow: boolean = false;

  @Output() emitSelectedProduct = new EventEmitter();
  mandatoryCheck: boolean = false;
  viewsnack2: boolean = false;
  showGuestBtn: boolean = false;
  pricealarmActivation: boolean = false;
  affliateDetails: { amount?: any; zip?: any; productId?: any; product?: any; unloadingPoints?: any; affiliateId?: any; callBackUrl?: any; consumerId?: any; };
  languageDataCheck: any;
  router: any;
  verification_url: any;

  AgbPdf: any;
  datenschutzPdf: any;

  consentNewsletter: any;
  tokenForVerification: any;
  passwordReset: boolean = false;
  verified: boolean = false;
  thirdparty: boolean = false;

  svgs = [
    environment.assetsUrl + 'assets/svgs/loginmask.svg',
    environment.assetsUrl + 'assets/svgs/order.svg',
    environment.assetsUrl + 'assets/svgs/orderHistory.svg',
    environment.assetsUrl + 'assets/svgs/address.svg',
    environment.assetsUrl + 'assets/svgs/weiter.svg'
  ];
  @Input() colors;
  @Input("productInfoForWebPC") productInfoForWebPC: any;

  portalTermsAndConditions: any;
  @Input() alaramSettings;
  termsAndConditions: any = [];
  checkedPdfs = [];
  sucessFailed: boolean = false;
  @Input() set _portalTermsAndConditions(portalTermsAndConditions) {
    this.portalTermsAndConditions = portalTermsAndConditions;
    this.portalDataAppending(this.portalTermsAndConditions, this.productInfoForWebPC?.portalSettings?.alaramSettings)
  };

  get _portalTermsAndConditions() {
    return this.portalTermsAndConditions;
  };

  allowRegistration: boolean = true;
  showGuestLogin: boolean = true;
  sessionId: string;
  sessionData: SessionData;
  abladestellen: any;

  iconColor1;
  iconColor2;
  iconColor3;

  resetPassword: boolean = false;
  tempPassCase: boolean = false;

  screenLoaderSet: boolean = false;
  loading: boolean;
  loaderTime: any = 10000;
  constructor(
    private langService: LanguageService,
    private SmallPcService: SmallPcService,
    private windowRef: WindowRefService,
    private authService: PortalAuthService,
    public fb: FormBuilder,
    private dialog: MatDialog,
    private newBigPc: BigpcService,
    private commonService: CommonService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public orderService: OrderService
  ) {
    this._window = this.windowRef.nativeWindow;
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;
    var params = new URLSearchParams(this.url);/*testcalc*/
    this.sessionId = params.get('sid');
    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    };

    let isUserLoggedIn = this.authService.isUserLoggedIn();
    if (!isUserLoggedIn) {
      this.companyInfo();
    };
  };

  async ngOnInit() {
    this.loginErrorMessage = '';
    this.tempPassCase = false;
    this.getDynamicSVG(this.svgs);
    this.showGuestLogin = true
    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }
    this.userAuth = { registration: false, login: true }

    this.userDetailsForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailregex)]],
      Vorname: ['', Validators.required],
      Name: ['', Validators.required],
      // agbCheck: ['', Validators.required],
      // dataProtectionCheck: ['', Validators.required],
      checkEmail: [''],
    });

    this.url = this._window.location.href;
    this.userLoginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(this.emailregex)]],
      password: ['', Validators.required],
    });

    this.params = new URLSearchParams(this.url);
    this.domainname = decodeURIComponent(this.params.get("Domainname"));
    this.Liter = decodeURIComponent(this.params.get("Liter"));
    this.Product = decodeURIComponent(this.params.get("Product"));
    this.view = decodeURIComponent(this.params.get("view"));
    this.Lieferort = decodeURIComponent(this.params.get("Lieferort"));
    let langData = this.langService.getLocalLanguageData();

    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this.languageDataCheck = langData.kundeportal || {};
    this.checkPriceAlarmParams(this.params);
    this.unterName = this.minMaxData?.unter_name;
    if (decodeURIComponent(this.params.get('page')) == 'registration' && this.allowRegistration) {
      this.user_registration = true;
      this.checkToggle('registration');
    } else {
      this.user_registration = false;
      this.checkToggle('login');
    };

    let affiliateDetailsExists = await this.orderService.checkForAffliateQueryParams();
    let affiliatedDetails = this.orderService.affliateDetails;
    if (affiliateDetailsExists.status) {
      let resp = await this.orderService.affiliateSessionCreation(this.hostname).catch(() => false);
      if (resp && resp.showGuestButton) {
        this.showGuestBtn = resp.showGuestButton;
        this.emitSelectedProduct.emit(affiliatedDetails.product);
      };

      if (resp && resp.sessionId) {
        this.sessionId = resp.sessionId;
      };
    };



    await this.SmallPcService.getNewSessionData(this.sessionId).then(async (data: any) => {
      if (data) {
        this.bigpclink = data.priceCalcUrl;
      };
    });
  };

  checkPriceAlarmParams(params) {
    let currentPage = decodeURIComponent(params.get("page"));
    if (currentPage == 'pricealarm') {
      this.showGuestLogin = false;
    } else {
      this.showGuestLogin = true;
    }
  };

  checkToggle = (type: keyof (UserAuth)) => {
    this.url = window.location.href;
    if (type == 'login') {
      this.userAuth['login'] = true;
      this.userAuth['registration'] = false;
      this.commonService.changeRouteParams('page', 'login')
    };

    if (type == 'registration') {
      this.userAuth['login'] = false;
      this.userAuth['registration'] = true;
      this.commonService.changeRouteParams('page', 'registration')
    };
  };

  pdfOnSelect(event, pdfData) {
    pdfData['checked'] = event;
    pdfData['touched'] = true;
    let existsPdfs = this.checkedPdfs?.find(ele => ele == pdfData._id);
    if (existsPdfs) {
      this.checkedPdfs = this.checkedPdfs.filter(ele => ele != pdfData._id)
    } else {
      this.checkedPdfs.push(pdfData._id);
    };
  };

  registerUser = async () => {
    let cond = this.termsAndConditions && this.termsAndConditions.filter(e => e.mandatory_checkbox && !e.checked);
    this.mandatoryCheck = cond?.length ? true : false;
    if (this.mandatoryCheck) {
      this.termsAndConditions = this.termsAndConditions.map(e => {
        if (e.mandatory_checkbox && !e.checked) {
          delete e.touched;
          return { checked: false, touched: true, ...e }
        } else {
          return { checked: true, touched: false, ...e }
        };
      });
      return false;
    };

    if (this.userDetailsForm.invalid) {
      return false;
    };

    let params = new URLSearchParams(this.url);
    let kundenKontoId
    if (params.get('kundenKontoId')) {
      kundenKontoId = params.get('kundenKontoId')
    };

    this.loading = true;
    this.thirdparty = !environment.testSite_or_thirdPartySites ? false : true;
    this.screenLoaderSet = true;
    let serRegistrationObj = {
      domainname: this.hostname,
      Vorname: this.userDetailsForm.controls['Vorname'].value,
      Name: this.userDetailsForm.controls['Name'].value,
      email: this.userDetailsForm.controls['email'].value,
      checkEmail: this.userDetailsForm.controls['checkEmail'].value,
      checkedPdfs: this.checkedPdfs,
      // agbCheck: this.userDetailsForm.controls['agbCheck'].value,
      // dataProtectionCheck: this.userDetailsForm.controls['dataProtectionCheck'].value,
      kundenKontoId: kundenKontoId,
      thirdparty: this.thirdparty,
      queryParams: {
        Liter: this.Liter || null,
        Product: this.Product || null,
        view: this.view || null,
        Lieferort: this.Lieferort || null,
        abladestellen: this.abladestellen || null,
        domianlink: this.SmallPcService.getMatchedDomainLink(),
      }
    };
    this.stopLoaderHere();
    this.authService.newUserRegistration(serRegistrationObj).subscribe((result: any) => {
      this.screenLoaderSet = false;
      if (!result.success) {
        this.user_exists = true;
        this.sucessFailed = true;
        // setTimeout(() => {
        //   this.user_exists = false;
        //   this.register = false;
        // }, 3000)
      } else {
        this.userDetailsForm.reset();
        this.register = true;
        this.userCreatedMessage = "successfully";
        setTimeout(() => {
          this.userCreatedMessage = ''
        }, 3000);
      };
    });
  };

  stopLoaderHere() {
    setTimeout(() => {
      this.screenLoaderSet = false;
    }, this.loaderTime);
  };

  userLogin = () => {
    if (this.userLoginForm.invalid) {
      return false;
    };
    this.loading = true;
    this.screenLoaderSet = true;

    let userCredential = {
      domainname: this.domainname,
      email: this.userLoginForm.controls['email'].value,
      password: this.userLoginForm.controls['password'].value,
    };

    this.stopLoaderHere();
    this.authService.userLogin(userCredential).subscribe(async (result: UserTokenDetails) => {
      this.loading = false;
      this.screenLoaderSet = false;
      if (!result.success) {
        // const res: any = result;
        if (result.error == "Wrong password" || result.error == "not found" || result.error == "Temp password expaired") {
          if (result.error == "Temp password expaired") {
            this.tempPassCase = true;
          } else {
            this.passmismatch = true;
            this.loginErrorMessage = result.error;
          };
        } else {
          this.loginErrorMessage = result.error;
        };

        this.user_not_found = true;
        // setTimeout(() => {
        //   this.user_not_found = false;
        // }, 3000)

      } else if (result.success && !result.user_details.tempPassword) {
        this.newBigPc.displayLoginPage(false);
        this.commonService.removeQueryParams("page=login");
        if (this.registration_at_order == 2) {
          let validSession: any = false;

          if (this.sessionId) {
            validSession = await this.SmallPcService.validateSessionData(this.sessionId);
          };

          if (!validSession) {
            //**  creating session automatically from bigpc service*/
          } else {
            this.newBigPc.showdashboard(true);
          };
        } else {
          localStorage.setItem('forceLogin', "false")
          let sessionId = localStorage.getItem("sid");
          let validSession = await this.SmallPcService.validateSessionData(sessionId);
          if (!validSession) {
            //** automatically creating session from bigpc service*/
          } else {
            this.newBigPc.displayLoginPage(false);
            this.newBigPc.showdashboard(true);
          };
        };
      } else if (result.success && result.user_details.tempPassword) {
        let url = environment.testSite_or_thirdPartySites ? `https://${this.bigpclink}` : environment.pricecalc;
        result.token = result.token.split(" ")[1];
        let token = result.token;
        let domainname = decodeURIComponent(this.params.get("Domainname"));
        if (url == undefined || url == 'undefined') {
          let tempUrl = window.origin;
          let urlPath = window.location.pathname;
          url = tempUrl + urlPath;
        }
        this.href = environment.testSite_or_thirdPartySites ? `${url}#&view=pwverified&resetpassword_user=${token}` : `${url}#&Domainname=${domainname}&view=pwverified&resetpassword_user=${token}`;
        this.tokenForVerification = result.token;
        this.ftpassword = true;
        if (this.commonService.isSafari()) {
          window.history.pushState("", document.title, this.href);
          window.location.reload();
          localStorage.setItem("routeUrl", JSON.stringify(this.href));
        } else {
          window.location.assign(this.href);
        };
      };

      let portal_user = JSON.parse(localStorage.getItem("portal_user"));
      if (portal_user) {
        portal_user.loginBeforePR = this.portalTermsAndConditions.show_registration_at;
        localStorage.setItem('portal_user', JSON.stringify(portal_user));
      };
    }, (error) => {
      // when rest api respond with 40X  then it comes to here  
      this.loading = false;
      this.screenLoaderSet = false;
      const res: any = error.error;
      if (res.error == "Wrong password" || res.error == "not found") {
        this.passmismatch = true;
        this.loginErrorMessage = res.error;
      } else {
        this.loginErrorMessage = res.error;
      };
      this.user_not_found = true;
      setTimeout(() => {
        this.user_not_found = false;
      }, 3000);
    });
  };


  guestUser = async () => {
    this.authService.storeGuestSession();
    this.newBigPc.displayLoginPage(false);
    this.commonService.removeQueryParams("page=login");
    let url = this._window.location.href;
    let params = new URLSearchParams(url);
    let sid = params.get("sid");
    let validateSession = await this.SmallPcService.validateSessionData(sid);
    if (!url.includes("sid") || !validateSession) {
      let obj = { primaryAdress: "", loginUser: false, selectedProduct: {} };
      this.newBigPc.createNewSession(obj);
    };
    // let tempOrderId = params.get("tempOrderId");
    // if (url.includes("sid") && url.includes("tempOrderId")) {
    //   this.newBigPc.getTempOrderData({ tempSaleId: tempOrderId }).subscribe(async (saleTempInfo: any) => {
    //     if (saleTempInfo.success) {
    //       this.newBigPc.tempOrderDataPrepareForSession(saleTempInfo.data, sid);
    //     };
    //   });
    // } else {
    //   let validateSession = await this.SmallPcService.validateSessionData(sid);
    //   if (!url.includes("sid") || !validateSession) {
    //     let obj = { primaryAdress: "", loginUser: false, selectedProduct: {} };
    //     this.newBigPc.createNewSession(obj);
    //   };
    // };
  };

  registeredHandler = (event: boolean) => {
    this.register = !event;
    this.userAuth['login'] = true;
    this.userAuth['registration'] = false;
    this.commonService.changeRouteParams('page', 'login')
    this.user_registration = false;
  };

  ftpasswordHandler = (event: boolean) => {
    this.passwordReset = event;
  };

  resetPasswordHandler = (event: boolean) => {
    this.resetPassword = event;
  };

  repPassword(event) {
    this.passwordReset = event;
  };

  async backToPriceCalculator() {
    await this.commonService.changeRouteParams('page', 'Preisrechner');
    this.url = this._window.location.href;
    var params = new URLSearchParams(this.url);
    this.checkPriceAlarmParams(params);
    this.newBigPc.displayLoginPage(false);
    let url = environment.testSite_or_thirdPartySites ? `https://${this.bigpclink}` : `${environment.pricecalc}#&Domainname=${this.hostname}`;
    window.location.href = url;
  };

  openDialog() {
    const dialogRef = this.dialog.open(InfoPasswordPopupComponent, {
      width: '445px',
      height: '267px',
      disableClose: true,
    });
    dialogRef.componentInstance.colors = this.colors;
  };


  downloadFile(data) {
    let object = { pdfId: data };
    this.disableAnchor = true;
    this.newBigPc.pdfiledownload(object, this.hostname).subscribe(
      (data123: any) => {
        this.disableAnchor = false;
        if (data123.sucess) {
          var pdfdata = data123.data.data.data;
          var byteCharacters = atob(pdfdata);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let fileName = `${data}.pdf`;

          if (data123.data.data.name) {
            if (fileName.includes("pdf")) {
              fileName = data123.data.data.name;
            }
          }
          var byteArray = new Uint8Array(byteNumbers);

          let newBlob = new Blob([byteArray], { type: "application/pdf" });
          let bloburl = URL.createObjectURL(newBlob);
          // if (navigator.userAgent.indexOf("Firefox") != -1) {
          //   // importedSaveAs(blob, pdfdata);
          //   return false
          // }
          let ua = window.navigator.userAgent;
          let msie = ua.indexOf("MSIE ");
          if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
            // If Internet Explorer
            FileSaver.saveAs(newBlob, `${fileName}`);
            return false;
          }
          if (!!navigator.userAgent.match(/Edge/)) {
            // If edge Explorer
            FileSaver.saveAs(newBlob, `${fileName}`);
            return false;
          }
          try {
            let win = window.open("about:blank", "_blank"); // window.open(bloburl, '_blank')
            win.document.write(
              '<iframe src="' +
              bloburl +
              '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
            );
            win.document.title = fileName;
            win.document.close();

            if (!win || win.closed || typeof win.closed == "undefined") {
              FileSaver.saveAs(newBlob, `${fileName}`);
              return false;
            }
          } catch (error) {
            FileSaver.saveAs(newBlob, `${fileName}`);
          }
        } else {
          this.viewsnack2 = true;
          setTimeout(() => {
            this.viewsnack2 = false;
          }, 4500);
        }
      }, () => {

      }
    );
  };

  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        logo = logo.replace(/#444/ig, 'var(--colorShade1)'); //shade1
        logo = logo.replace(/#555/ig, 'var(--colorShade2)'); //shade2
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      });
    });
  };

  companyInfo() {
    this.screenLoaderSet = true;
    this.stopLoaderHere();
    this.newBigPc.companyInfoForWebPc().subscribe((response: any) => {
      this.portalTermsAndConditions = response.data[0]?.portalTermsAndConditions;
      this.colors = response.data[0].loginColors;
      this.alaramSettings = response.data[0].alaramSettings;
      this.bigpclink = response.data[0]?.pcLink;
      this.portalDataAppending(this.portalTermsAndConditions, this.alaramSettings);
      this.screenLoaderSet = false;

    });
  };

  portalDataAppending(portalTermsAndConditions, alaramSettings) {
    this.AgbPdf = portalTermsAndConditions && portalTermsAndConditions?.portalPdfs && portalTermsAndConditions?.portalPdfs.find(it => it.termName == 'AgbPdf');
    this.datenschutzPdf = portalTermsAndConditions && portalTermsAndConditions?.portalPdfs && portalTermsAndConditions?.portalPdfs.find(it => it.termName == 'datenschutzPdf');
    this.termsAndConditions = portalTermsAndConditions?.termsAndConditions && portalTermsAndConditions?.termsAndConditions.map(e => {
      if (e.mandatory_checkbox) {
        return { checked: false, touched: false, ...e }
      } else {
        return { checked: false, touched: false, ...e }
      };
    });

    this.consentNewsletter = portalTermsAndConditions && portalTermsAndConditions?.consentNewsletter;
    this.allowRegistration = portalTermsAndConditions && portalTermsAndConditions?.registration_activation;
    this.pricealarmActivation = alaramSettings && alaramSettings.status;
  };

  navigateToLogin(event) {
    if (event) {
      return;
    };

    this.ftpassword = event;
    this.url = this._window.location.href;
    let url: string = this.url;
    let paramMap = new URLSearchParams(url);
    let view = paramMap.get("view");
    let resetpassword_user = paramMap.get("resetpassword_user");
    if (view) {
      url = url.replace(new RegExp(`&view=${view}`, "gi"), "");
    };

    if (resetpassword_user) {
      url = url.replace(new RegExp(`&resetpassword_user=${resetpassword_user}`, "gi"), "");
      url = `${url}&page=login`
      window.history.pushState("", document.title, url);
    };
  };



  updateErrorMsg(event) {
    if (event && this.sucessFailed) {
      this.user_exists = false;
    }
  }
};


