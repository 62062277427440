<!-- {{portalTermsAndConditions | json}} -->
<div *ngIf="passwordReset == false">
  <div class="aupris_main body" style="margin:auto; font-family: Arial, Helvetica, sans-serif;"
    *ngIf="ftpassword == false">
    <div class="au-container" *ngIf="register == false">
      <div class="aurow">
        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
          <h4 class="text-center">Willkommen zu unserem Kundenportal</h4>
        </div>
      </div>
      <br>
      <!-- <div class="aurow" *ngIf="theme == 'Theme2' || theme == 'Theme1'"> -->
      <div class="aucol-xs-12 aucol-sm-6 aucol-md-6 aucol-lg-6 divider-left">
        <div class="login-block">
          <!-- <div class="right-block"> -->

          <div class="panel-body">
            <div class="w3-bar" [ngStyle]="{'text-align': allowRegistration ? 'initial': 'center' }"
              style="padding-bottom: 10%;">

              <a class="w3-btn" style="cursor: pointer;" [ngClass]="{'w3_btn_theme2': userAuth?.login}"
                [ngStyle]="{'color': 'var(--primaryColour)','margin-left': allowRegistration ? '20px': '0px' }"
                (click)="user_registration = false; checkToggle('login')">
                Login
              </a>


              <a class="w3-btn" style="float: right;cursor: pointer;padding: 0px;" *ngIf="allowRegistration"
                [ngClass]="{'w3_btn_theme2': userAuth?.registration}" [ngStyle]="{'color': 'var(--primaryColour)'}"
                (click)="user_registration = true; checkToggle('registration')">
                {{languageData?.login?.Var_Jetzt || 'Jetzt registrieren'}}
              </a>


            </div>
            <!-- this is for user login  -->
            <form class="authenticationBlock" [formGroup]="userLoginForm" *ngIf="!user_registration && !user_tracking">
              <div class="input" style="padding-bottom: 10%;">
                <input placeholder="Email" type="email" formControlName="email" autocomplete="off" />
                <div class="alert-danger"
                  *ngIf="!userLoginForm?.controls?.email?.valid && userLoginForm?.controls?.email?.touched">
                  {{languageData?.login?.Var_erforderlich || 'E-Mail ist erforderlich'}}
                </div>
                <i style="margin-top:3px">
                  <img [src]="'assets/svgs/mail.svg' | assetsUrl">
                </i>
              </div>
              <div class="input" style="padding-bottom: 10%;">
                <input placeholder="{{languageData?.login?.Var_Passwort|| 'Passwort'}}"
                  formControlName="password" [type]="!isShow ? 'password' : 'text'" />
                  <img style="margin-left: -25px;" *ngIf="isShow" (click)="isShow = !isShow"
                src="../../../assets/show Password.svg">
            <img style="margin-left: -25px;" *ngIf="!isShow" (click)="isShow = !isShow"
                src="../../../assets/hide Password.svg">
                <div class=" alert-danger"
                  *ngIf="!userLoginForm?.controls?.password?.valid && userLoginForm?.controls?.password?.touched">
                  {{languageData?.login?.Var_benotigt || 'Passwort wird benötigt'}}</div>
                <div class=" alert-danger" *ngIf="google_auth || fb_auth">
                  <span *ngIf="google_auth == true && fb_auth ==false">
                    {{languageData?.login?.Var_googlesocial || ' Sie sind bereits sozial bei Google registriert'}}
                  </span>
                  <span *ngIf="fb_auth  == true && google_auth == false">
                    {{languageData?.login?.Var_fbsocial || ' Du bist schon sozial registriert bei FB'}}</span>
                  <span *ngIf="fb_auth  == true && google_auth == true">{{languageData?.login?.Var_fbgoogle || ' Du bist
                    schon sozial bei google und fb registriert'}}</span>
                </div>
                <i>
                  <img [src]="'assets/svgs/password.svg' | assetsUrl">
                </i>
              </div>


              <!-- use padding-bottom 10% for the below div when facebook and google logins are included -->
              <div class="aurow" style="padding-bottom: 0%;">
                <a class="aucol-sm-8  aucol-xs-4"
                  style="padding-top: 5px;color: #707070;width: 185px !important;cursor: pointer;text-decoration: underline;text-underline-position: under;margin-right:13px; padding-bottom: 20px;"
                  (click)="repPassword(true)">
                  {{languageData['login']?.Var_Passwort_vergessen || 'Passwort vergessen'}}?
                </a>
                <button style="text-align:center" class=" btn1 aucol-sm-4 aucol-xs-4" cyLogin="cylogin"
                  [disabled]="userLoginForm?.invalid && loading "
                  [style.cursor]="userLoginForm?.invalid ? 'no-drop' : ''" (click)="userLogin()"
                  [ngStyle]="{'background-color': 'var(--primaryColour)'}">
                  LOGIN
                </button>
              </div>
              <div *ngIf="passmismatch" class=" alert-danger">
                <div>
                  {{languageData?.login['Var_'+loginErrorMessage] || (loginErrorMessage)}}
                </div>
              </div>

              <div *ngIf="tempPassCase" class="alert-danger">
                <div>
                  {{languageDataCheck['Login']['VarKundeportalLogin2'] || 'Temporäres Passwort abgelaufen! Bitte
                  erstellen Sie ein neues Konto!'}}
                </div>
              </div>

              <!-- login with goodgle and facebokk commenting for release  -->
              <div class=" alert-danger" *ngIf="user_existsalready">
                {{languageData?.login?.Var_benutzer || ' Benutzer existiert bereits'}}
              </div>
              <div class=" alert-danger" *ngIf="user_notVerified" style="text-align: center;">
                {{languageData?.login?.Var_registriert || 'Benutzer bereits registriert, bitte überprüfen'}}
              </div>
              <div class="user_info" *ngIf="userCreated">
                {{languageData?.login?.Var_erfolgreich || 'Benutzer erfolgreich erstellt Bitte überprüfen Sie Ihr
                E-Mail'}}
              </div>
            </form>


            <!-- this is for user registration -->

            <form class="authenticationBlock" [formGroup]="userDetailsForm" *ngIf="user_registration && !user_tracking">
              <div class="input" style="padding-bottom: 10%;">
                <input placeholder="Vorname" type="text" autocomplete="new-password" removeAutoComplete
                  formControlName="Vorname" />
                <div class=" alert-danger"
                  *ngIf="!userDetailsForm.controls?.Vorname?.valid && userDetailsForm?.controls?.Vorname?.touched">
                  {{languageData?.login?.Var_vollstandiger || 'vollständiger Name ist erforderlich'}}</div>
                <i style="margin-top:3px">
                  <img [src]="'assets/svgs/user.svg' | assetsUrl">

                  <!-- <img src="../assets/svgs/user.svg"> -->
                </i>
              </div>
              <div class="input" style="padding-bottom: 10%;">
                <input placeholder="Nachname" type="text" formControlName="Name" aautocomplete="new-password"
                  removeAutoComplete />
                <div class=" alert-danger"
                  *ngIf="!userDetailsForm.controls?.Name?.valid && userDetailsForm.controls?.Name?.touched">
                  {{languageData?.login?.Var_vollstandiger || 'vollständiger Name ist erforderlich'}}</div>
                <i style="margin-top:3px">
                  <img [src]="'assets/svgs/user.svg' | assetsUrl">
                </i>
              </div>
              <div class="input">
                <input placeholder="Email" type="email" formControlName="email" autocomplete="new-password"
                  removeAutoComplete (input)="updateErrorMsg($event.target.value)" />
                <div class=" alert-danger"
                  *ngIf="!userDetailsForm.controls?.email?.valid && userDetailsForm.controls?.email?.touched">
                  {{languageData?.login?.Var_erforderlich|| 'E-Mail ist erforderlich'}}
                </div>
                <i style="margin-top:3px">
                  <img [src]="'assets/svgs/mail.svg' | assetsUrl">

                </i>
              </div>
              <div class=" alert-danger" *ngIf="user_exists">
                {{languageData?.login?.Var_Benutzer || 'Benutzer existiert bereits'}} </div>
              <div class="text-success" *ngIf="userCreatedMessage !=''">
                {{languageData?.login['Var_'+userCreatedMessage] || (userCreatedMessage)}}</div>

              <div style="margin-top: 10px;" *ngFor="let pdfData of termsAndConditions">
                <span style="font-size: 12px; margin-top: 8px;" *ngIf="pdfData.show_in_pricecalculator"
                  [ngStyle]="{'color':(!pdfData.checked &&  pdfData.touched && pdfData?.checkbox_in_the_pricecalculator &&  pdfData.mandatory_checkbox) ? 'red' : '#333' }">
                  <input class="checkBox" [checked]="pdfData.checked"
                    (change)="pdfOnSelect($event.target.checked, pdfData)"
                    *ngIf="pdfData.checkbox_in_the_pricecalculator" type="checkbox" style="cursor: pointer;" />
                  <span class="alignPdf">
                    {{pdfData.textarea1}}
                    <b>
                      <a *ngIf="!pdfData.isLink" [ngClass]="pdfData.filename ? 'colors' :'noColor'"
                        (click)="downloadFile(pdfData.refCentralizedFileId)">{{pdfData.name}}</a>
                      <a *ngIf="pdfData.isLink" [ngClass]="pdfData.link ? 'colors' :'noColor'"
                        [href]="pdfData.link | securitycheck" target="_blank">
                        {{pdfData.name}}</a>
                    </b>
                    {{pdfData.textarea2}}
                    <span *ngIf="pdfData?.checkbox_in_the_pricecalculator && pdfData?.mandatory_checkbox">*</span>
                  </span>
                </span>
              </div>
              <div class=" alert-danger" *ngIf="mandatoryCheck">
                {{languageDataCheck['Login']['VarKundeportalLogin1']
                || 'muss akzeptiert sein!'}}
              </div>
              <div style="margin-top: 3px;">
                <div style="font-size: 12px; margin-top: 8px;">
                  <input type="checkbox" class="checkBox" formControlName="checkEmail">
                  <span class="checkboxText">{{consentNewsletter}}
                  </span>
                </div>
              </div>

              <div class="aurow hidden-lg hidden-md" style="padding-bottom: 0%; margin-top: 18px;">
                <button style="text-align:center; margin: auto ;cursor: pointer;" class="au-btn"
                  [disabled]="userDetailsForm.invalid && loading " (click)="registerUser()"
                  [ngStyle]="{'background-color': 'var(--primaryColour)'}"
                  [style.cursor]="userDetailsForm.invalid ? 'no-drop' : ''">
                  {{languageData?.login?.Var_Jetzt || 'Jetzt registreiren'}}
                </button>
              </div>
              <div class="aurow hidden-sm hidden-xs" style="padding-bottom: 0%; margin-top: 18px;">
                <button style="text-align:center; margin: auto ;cursor: pointer;" class="au-btn"
                  [disabled]="userDetailsForm.invalid && loading " (click)="registerUser()"
                  [ngStyle]="{'background-color': 'var(--primaryColour)'}"
                  [style.cursor]="userDetailsForm.invalid ? 'no-drop' : ''">
                  {{languageData?.login?.Var_Jetzt || 'Jetzt registreiren'}}
                </button>
              </div>
              <!-- login with fb and google commenting for release -->
              <div class=" alert-danger" *ngIf="user_existsalready" style="text-align: center;">
                {{languageData?.login?.Var_Benutzer || 'Benutzer existiert bereits'}}</div>
              <div class="user_error" *ngIf="user_notVerified" style="text-align: center;">
                User already registered please verify
              </div>
              <div class="user_info" *ngIf="userCreated">
                User created successfuly please verify your mail
              </div>
            </form>


            <!--this is for user tracking-->
            <form class="authenticationBlock" [formGroup]="usertrackingForm" *ngIf="user_tracking">

            </form>
          </div>
        </div>
      </div>

      <div class="aucol-xs-12 aucol-sm-6 aucol-md-6 aucol-lg-6 flexBox">
        <div class="svgSec">
          <span [innerHTML]="loginmask" *ngIf="loginmask"></span>
        </div>
      </div>
      <!-- </div> -->
      <!-- this condition(*ngIf="sessionId && showGuestBtn") has to be place  in the *ngIf="showGuestLogin" below this , 
      harish placed it work purpose , if you don't understand please ask harish singamneni ,  -->
      <div class="aurow" *ngIf="showGuestLogin" style="display: block;">
        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12 text-center">

          <button class="au-btn" cyContinueAsGuest="cyContinueAsGuest" *ngIf="showGuestLogin"
            [ngStyle]="{'background-color': 'var(--primaryColour)'}" (click)="guestUser()">
            {{languageData?.login?.Var_Weiter || 'Weiter als Gast'}}
          </button>
        </div>
      </div>

      <div class="aurow" style="display: block;">
        <div class="aucol-lg-12 aucol-md-12 aucol-sm-12 aucol-xs-12">
          <div class="instSec">
            <div class="instHeading">
              Registrieren und Vorteile genießen:
            </div>
            <ul class="instructions">
              <li *ngIf="pricealarmActivation">
                <span>
                  <span [innerHTML]="orderHistory"></span>
                </span>
                <span style="margin-left: -6px;">
                  Preiswecker: informiert bei steigenden oder fallenden Preisen</span>
              </li>
              <li>
                <span>
                  <span [innerHTML]="order" *ngIf="order"></span>
                </span>
                <span>
                  Bestellhistorie: Ihre aktuellen und vergangenen Bestellungen sind für Sie einsehbar
                </span>
              </li>
              <li>
                <span>
                  <span [innerHTML]="address" *ngIf="address"></span>
                </span>
                <span>
                  Adressmanagement: noch bequemer bestellen durch abgespeicherte Lieferadressen
                </span>
              </li>
              <li>
                <span>
                  <span [innerHTML]="weiter" *ngIf="weiter"></span>
                </span>
                <span>Weitere Funktionen werden folgen!</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <div style="margin-top: 40px; text-align: center;">
          <button class="au-btn text-center" [ngStyle]="{'background-color': 'var(--primaryColour)'}"
            (click)="backToPriceCalculator()">
            <!-- {{'Back'}} -->
            {{languageData.Adresseingabe?.Var_Zuruck || 'Zurück'}}
          </button>
        </div>
      </div>

      <div class="aurow mt100" *ngIf="theme== 'oldtheme'">
        <!-- for login -->
        <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12" *ngIf="!user_registration">
          <div class="text-right font20">
            <span>{{languageData?.login?.Var_weiterals || 'weiter als'}}
              <a class="pointer" [ngStyle]="{'color': colors?.Highlight_Farbe}" (click)="guestUser()">
                {{languageData?.login?.Var_Gast || 'Gast'}}?
              </a>
            </span>
          </div>
          <div class="login-block2">
            <div class="panel-body2">
              <h3 class="loginLabel" [ngClass]="{'w3_btn_theme1': userAuth?.login}">Login</h3>
              <form class="authenticationBlock" [formGroup]="userLoginForm" *ngIf="!user_registration">
                <div class="mt25">
                  <input type="email" class="input2" placeholder="Email" formControlName="email" autocomplete="off">
                  <div class=" alert-danger"
                    *ngIf="!userLoginForm.controls.email.valid && userLoginForm.controls.email.touched">
                    {{languageData?.login?.Var_erforderlich || 'E-Mail ist erforderlich'}}
                  </div>
                </div>
                <div class="mt25">
                  <input type="password" class="input2" placeholder="{{languageData?.login?.Var_Passwort|| 'Passwort'}}"
                    formControlName="password">
                  <div class=" alert-danger"
                    *ngIf="!userLoginForm.controls.password.valid && userLoginForm.controls.password.touched">
                    {{languageData?.login?.Var_benotigt || 'Passwort wird benötigt'}}</div>
                  <div class="alert alert-danger" *ngIf="google_auth || fb_auth">
                    <span *ngIf="google_auth == true && fb_auth ==false">
                      {{languageData?.login?.Var_googlesocial || ' Sie sind bereits sozial bei Google registriert'}}
                      </span>
                    <span *ngIf="fb_auth  == true && google_auth == false">
                      {{languageData?.login?.Var_fbsocial || ' Du bist schon sozial registriert bei FB'}}</span>
                    <span *ngIf="fb_auth  == true && google_auth == true">
                      {{languageData?.login?.Var_fbgoogle || 'Du bist schon sozial bei google und fb registriert'}}
                    </span>
                  </div>
                </div>

                <div class="user_error" *ngIf="user_not_found">
                  {{languageData?.login['Var_'+loginErrorMessage] || (loginErrorMessage)}}
                </div>

                <div class="mt25 text-right" (click)="ftpassword = true">
                  <a class="pointer" style="color:#88867f">
                    {{languageData['login']?.Var_Passwort_vergessen || 'Passwort vergessen'}}?
                  </a>
                </div>

                <div class="mt25">
                  <button class="au-btn btn2" cyLogin="cylogin" [disabled]="userLoginForm.invalid && loading "
                    [style.cursor]="userLoginForm.invalid ? 'no-drop' : ''" (click)="userLogin()"
                    [ngStyle]="{'background-color': colors?.Highlight_Farbe}">Login</button>
                </div>
              </form>
            </div>
          </div>
          <div class="signup2 mt25">
            {{languageData?.login?.Var_ichhabe || 'Ich habe keinen Account '}}<a class="colBlack pointer font700;"
              style="margin-left:5px" (click)="user_registration = true;">
              {{languageData?.login?.Var_Anmelden || 'Anmelden?'}}</a>
          </div>
        </div>

        <!-- for registration -->
        <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12" *ngIf="user_registration && allowRegistration">
          <div class="text-right font20">
            <span>
              {{languageData?.login?.Var_weiterals || 'weiter als'}}
              <a class="pointer" [ngStyle]="{'color': colors?.Highlight_Farbe}" (click)="guestUser()">
                {{languageData?.login?.Var_Gast || 'Gast'}}?
              </a>
            </span>
          </div>
          <div class="login-block2">
            <div class="panel-body2">
              <h3 class="loginLabel" [ngClass]="{'w3_btn_theme1': userAuth?.login}">
                {{languageData?.login?.Var_ein || 'Ein neues Konto Erstellen'}}</h3>
              <form class="authenticationBlock" [formGroup]="userDetailsForm" *ngIf="user_registration">
                <div class="mt25">
                  <input type="text" class="input2" placeholder="Name" formControlName="name" autocomplete="off">
                </div>
                <div class="mt25">
                  <input type="email" class="input2" placeholder="Email" formControlName="email" autocomplete="off">
                  <div class=" alert-danger"
                    *ngIf="!userDetailsForm.controls.email.valid && userDetailsForm.controls.email.touched">
                    {{languageData?.login?.Var_erforderlich|| 'E-Mail ist erforderlich'}}
                  </div>
                </div>
                <div class="mt25">
                  <input type="password" class="input2" placeholder="{{languageData?.login?.Var_Passwort|| 'Passwort'}}"
                    formControlName="password">
                  <div class=" alert-danger"
                    *ngIf="!userDetailsForm.controls.password.valid && userDetailsForm.controls.password.touched">
                    {{languageData?.login?.Var_benotigt || 'Passwort wird benötigt'}}
                  </div>
                </div>
                <div class="mt25">
                  <input type="password" class="input2"
                    placeholder="{{languageData?.login?.Var_re_Passwort|| 'Wiederhole das Passwort'}}"
                    formControlName="re_password">
                  <div class="alert alert-danger"
                    *ngIf="!userDetailsForm.controls.re_password.valid && userDetailsForm.controls.re_password.touched">
                    {{languageData?.login?.Var_Wiederhole || 'Wiederhole das Passwort'}}
                  </div>

                  <div class=" alert-danger"
                    *ngIf="userDetailsForm.controls.re_password.valid && userDetailsForm.errors && userDetailsForm.controls.re_password.touched">
                    {{languageData?.login?.Var_Passworter || 'Passwörter stimmen nicht überein'}}</div>
                </div>
                <div class=" alert-danger" *ngIf="user_exists">
                  {{languageData?.login?.Var_Benutzer || 'Benutzer existiert bereits'}} </div>
                <div class="text-success" *ngIf="userCreatedMessage !=''">
                  {{languageData?.login['Var_'+userCreatedMessage] || (userCreatedMessage)}}</div>
                <div style="margin-top: 3px;">
                  <div style="font-size: 12px; margin-top: 8px;">
                    <input type="checkbox" class="checkBox" formControlName="checkEmail">
                    <span class="checkboxText">
                      {{consentNewsletter}}
                    </span>
                  </div>
                </div>
                <div class="mt25">
                  <button class="au-btn btn2" [disabled]="userDetailsForm.invalid && loading "
                    [ngStyle]="{'background-color': colors?.Highlight_Farbe}" (click)="registerUser()"
                    [style.cursor]="userDetailsForm.invalid ? 'no-drop' : ''">
                    {{languageData?.login?.Var_Jetzt || 'Jetzt registreiren'}}
                  </button>
                </div>
                <div class="mt25 ">
                  <div class="aurow">
                    <div class="aucol-md-12 text-center">
                      <span> {{languageData?.login?.Var_habe || 'habe bereits ein Konto'}}
                        <a class="pointer colred" (click)="user_registration = false;">
                          Login
                        </a>
                      </span>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>

          <div class="signup2 mt25" *ngIf="!user_registration">
            {{languageData?.login?.Var_ichhabe || 'Ich habe keinen Account '}}<a class="colBlack pointer font700;"
              style="margin-left:5px">{{languageData?.login?.Var_Anmelden || 'Anmelden?'}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<loader [full-screen]="true" *ngIf="screenLoaderSet" [color]="'var(--primaryColour)'"></loader>

<app-pw-verification *ngIf="ftpassword" [ftpassword]="ftpassword" [tokenForVerification]="tokenForVerification"
  (resetPass)="navigateToLogin($event)">
</app-pw-verification>

<app-email-varification *ngIf="passwordReset" [passwordReset]="passwordReset" (ftpassword)="ftpasswordHandler($event)">
</app-email-varification>

<app-verificationlink *ngIf="register" [register]="register" (registered)="registeredHandler($event)">
</app-verificationlink>