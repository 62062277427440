<div class="aupris_main my-full-screen-dialog">
    <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">
        {{ languageData?.Popups['VarWeb-PcPopups6']}}
    </div>

    <br />
    <div> {{ languageData.Popups['VarWeb-PcPopups7'] }} </div>
    <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">

        <button class="au-btn btn-default"
            [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}" (click)="onclick(false)"
            style="margin-top:5px">{{languageData['Popups']['VarWeb-PcPopups8']}}</button>
    </div>
</div>
<!--


    <div class="aupris_main my-full-screen-dialog">
    <div class="text-center" style="font-size:20px;font-weight: bold;margin-bottom: 10px;">
        {{ languageData?.Bestelloptionen['Var_rate_payment_reject_details'] || 'Ratenkauf abgelehnt'}} </div>

    <br />


    <div class="inline-block">
        <span class="dblock" *ngIf="!reverifyBankDetails">
            <ng-container *ngIf="validationErrorInfo; then thenTemplate; else elseTemplate"></ng-container>
            <ng-template #thenTemplate>
                <p>{{languageData?.Bestelloptionen['Var_mismatched_info_rate_payment_text'] || 'Es tut uns leid, der Ratenkauf wurde von der Bank abgelehnt. Bitte haben Sie Verständnis dafür, dass wir
                auf die Prüfung und den Ausgang keinen Einfluss haben.'}}</p>
                <br>
                <p>{{languageData?.Bestelloptionen['Var_ratepayment_change_payment_method']|| 'Bitte verwenden Sie eine andere Zahlungsart.'}}
                </p>
            </ng-template>
            <ng-template #elseTemplate>
                Var_rejected_for_creditWorthiness

                <p>{{languageData?.Bestelloptionen['Var_rejected_for_creditWorthiness'] || 'Der Ratenkauf wurde auf Grund fehlerhafter Daten abgelehnt. Bitte prüfen Sie Ihre Eingabe.'}}
                </p>
                <br>
                <p>{{languageData?.Bestelloptionen['Var_creditWorthiness_change_payment_mehod'] || 'Sollte der Fehler häufiger vorkommen, probieren Sie es zu einem anderen Zeitpunkt noch einmal oder
                ändern
                Sie die Zahlungsart'}} </p>
            </ng-template>
        </span>
        <span class="dblock" *ngIf="reverifyBankDetails">
            <p>{{languageData?.Bestelloptionen['Var_expired_info_rate_payment_text'] || 'Der Prozess für die Ratenzahlung ist abgelaufen. Bitte initialisieren Sie den neuen Prozess'}}
            </p>
        </span>
    </div>
    <div style="text-align: center;" *ngIf="!reverifyBankDetails">
        <button class="au-btn btn-default" [ngStyle]="{background: colors.primaryColour,color: colors.primaryTextColour}"
            (click)="onclick(true)"
            style="margin-top:5px">{{languageData?.Bestelloptionen['Var_Zahlungsart ändern'] ||'Zahlungsart ändern'}}</button>
    </div>
    <div style="text-align: center;" *ngIf="reverifyBankDetails">
        <button class="au-btn btn-default" [ngStyle]="{background: colors.Grundfarbe,color: colors.Schriftfarbe_Buttons}"
            (click)="onclick(true)"
            style="margin-top:5px">{{languageData?.Bestelloptionen['Var_expired_re_initialize_process'] || 'Prozess neu initialisieren'}}</button>
    </div>
    <div class="text-right">
        <button class="au-btn btn-default" [ngStyle]="{background: colors.primaryColour,color: colors.primaryTextColour}"
            (click)="onclick(false)"
            style="margin-top:5px">{{languageData['Popups'].Var_Schlieben || 'Schließen'}}</button>
    </div>
</div>
-->