import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { Router } from '@angular/router';
import { PortalAuthService } from '../login/auth.service';
import { environment } from '../../environments/environment';
import { CommonServiceLatest } from '../Services/common.service';
@Injectable({
    providedIn: 'root'
})
export class HttpRequestHandler implements HttpInterceptor {
    constructor(
        // protected router: Router,
        private authService: PortalAuthService,
        public commonService: CommonServiceLatest
    ) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        try {
            let isFormData = request.body instanceof FormData;
            let dynamicProductId: any = `${this.commonService.getDynamicProducId()}`;
            let ipAddress: any = sessionStorage.getItem('aupris_ipaddress');
            let plzId: any = `${this.commonService.getPostalCodeId()}`;
            let tagNumber: any = `${this.commonService.getTagNumber()}`;
            let setDomainName = `${this.commonService.getDomainName()}`;
            let quantity = `${this.commonService.getQuantity()}`;

            let headers = {
                'Cache-control': 'no-cache',
                'Expires': '0',
                'Pragma': 'no-cache',
                'webpc': 'true',
            };

            if (localStorage.getItem('aupris_token')) {
                let token = localStorage.getItem('aupris_token');
                headers = Object.assign(
                    headers,
                    {
                        "authorization": token,
                    }
                );
            };

            // set dynamic product id
            if (dynamicProductId) {
                headers['dynamicProductId'] = dynamicProductId;
            };

            // set quantity product id
            if (quantity) {
                headers['quantity'] = quantity;
            };

            var params = new URLSearchParams(window.location.href);
            
            // set ip address
            if (ipAddress && ipAddress != 'null' && Object.keys(ipAddress).length) {
                ipAddress = JSON.parse(ipAddress)
                headers['ip'] = ipAddress.sessionId;
            };

            // set plz id
            if (plzId) {
                headers['plzid'] = plzId;
            };

            // set tag number
            if (tagNumber) {
                headers['tag'] = tagNumber;
            };

            // set Domain name
            if (setDomainName) {
                headers['domainname'] = setDomainName;
            };

            if (this.authService.isUserLoggedIn() && (this.authService.getAuprisToken() && this.authService.getAuprisToken().includes('JWT'))) {
                headers['authorization'] = `${this.authService.getAuprisToken()}`;
                headers['thirdparty'] = (!environment.testSite_or_thirdPartySites).toString();
            };
            if (params.get("page") === 'pricealarm' && request.url.includes('getDynamicOptions')) {
                let priceAlarmInfo = JSON.parse(localStorage.getItem('priceAlarmInfo'));
                headers['dynamicProductId'] = priceAlarmInfo.product;
                headers['plzid'] = priceAlarmInfo.plzId;
            };

            if (isFormData) {
                headers['Accept'] = 'application/json';
            } else {
                headers['Content-Type'] = 'application/json';
            };


            if (request.url.match(/s3\.eu\-central\-1\.amazonaws\.com/ig)) {
                delete headers['authorization']
                delete headers['dynamicProductId']
                delete headers['domainname']
                delete headers['plzid']
                delete headers['tag']
                // headers['Origin'] = "https://www.aupris.com";
                // headers['Referer'] = "https://www.aupris.com/";
            };


            let nextRequest = request.clone({
                setHeaders: headers
            });

            return next.handle(nextRequest)
                .pipe(catchError((error: HttpErrorResponse) => {
                    return throwError(error);
                    // if (request.url == 'https://api.ipify.org?format=json') {
                    //     return throwError(error);
                    // }
                }
                ));
        } catch (err) {
            console.log(err);
        };
    };
};