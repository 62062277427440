import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { SmallPcService } from 'app/Services/small-pc.service';
import { AlternativeTextInfoComponent } from 'app/alternative-text-info/alternative-text-info.component';
import { CommonService } from 'app/common.service';
import { InfopopupComponent } from 'app/infopopup/infopopup.component';
import { LanguageService } from 'app/language.service';
import { PaymentContinueComponent } from 'app/popups/payment-continue/payment-continue.component';
import { SteuerhinweisPopupComponent } from 'app/popups/steuerhinweis-popup/steuerhinweis-popup.component';
import { environment } from 'environments/environment';
import { LocalstorgeService } from 'app/Services/localstorge.service';


@Component({
  selector: 'app-three',
  templateUrl: './three.component.html',
  styleUrls: ['./three.component.css']
})
export class ThreeComponent implements OnInit {
  priceCalculationData: any = [];
  localSessionUpdatedData: any;
  timeDepandentSurcharges: any;
  label = '';
  priceFactor = '';
  unitsSymbol = '';
  socket: any = null;
  //dynamic svg color adding
  svgs = [
    environment.assetsUrl + 'assets/star.svg',
    environment.assetsUrl + 'assets/infoSign.svg',
    environment.assetsUrl + 'assets/calculator1.svg',
    environment.assetsUrl + 'assets/svgs/voucherSVG.svg',
    environment.assetsUrl + 'assets/svgs/reload.svg'
  ];

  // gutscehinMaxLen: number;
  // addressess: any;
  // vatSettings: any;
  // households: any;
  localOptions: any;
  @Input() url: string;
  languageVar: any;


  sessionId: string;
  sessionData: any;

  // addresses: any;
  // alternativeMwst: number;
  // alternativeWarenpries: number = 0;
  orderPlaced: boolean = false;
  voucherCode: any = '';
  voucherNett: number;
  @Input() voucher: any;
  touchedVoucherInput: boolean = false;
  verifyPdfsAgian: boolean = false;

  @Input()
  set _priceCalculationData(priceCalculationData) {
    this.priceCalculationData = priceCalculationData;
    // this.restoreSelectedQuality(this.selectedIndex)
  };

  @Input()
  set _localSessionUpdatedData(localSessionUpdatedData) {
    this.localSessionUpdatedData = localSessionUpdatedData;
  };

  get _localSessionUpdatedData() {
    return this.localSessionUpdatedData
  };

  @Input() stationFormGroup: FormGroup;;
  @Output() updateOptionsEvent = new EventEmitter<any>();

  get _priceCalculationData() {
    return this.priceCalculationData;
  };



  @Input("thridStagePdfs") thridStagePdfs: any = [];
  @Input("productInfoForWebPC") productInfoForWebPC: any = {};
  // @Input() clickedPage;
  // @Input() options;
  @Input() PCOptions: any = [];
  @Input() localoptions;
  @Input() languageData;
  // @Input() ratePayment: boolean;
  // @Input() page_2: boolean;
  // @Input() page_3: boolean;
  // @Input() page_4: boolean;


  @Input() colors;

  // @Input() currency_symbol;
  // @Input() volume_short;
  // @Input() weight_short;

  @Input() agbAccepted;
  // @Input() vatAccepted;

  // @Input() co2CompensateValue;
  // @Input() show_hide_Gutschein;
  // @Input() gutschein;
  // @Input() isValidCode;
  @Input() third_stage_pricechange;
  @Input() showFullScreenLoading;
  @Output() page1Event = new EventEmitter();
  @Output() page2Event = new EventEmitter();
  // @Output() specialtextpopup1Event = new EventEmitter();
  // @Output() alternativePopupEvent = new EventEmitter();
  @Output() downloadFileEvent = new EventEmitter();
  @Output() ZahlungMailEvent = new EventEmitter();
  // @Output() co2CompensateEvent = new EventEmitter();
  @Output() priceChangeAcceptEvent = new EventEmitter();

  @Output() productTaxAcceptEvent = new EventEmitter();
  // @Output() vatChargesAcceptEvent = new EventEmitter();
  // @Output() onOfferTokenEnterEvent = new EventEmitter();
  // @Output() pricedetailsEvent = new EventEmitter<any>();
  // @Output() setDefaultAddressesEvent = new EventEmitter();
  // @Output() setDefaultBankDetailsEvent = new EventEmitter();
  // @Output() resendMtanEvent = new EventEmitter<any>();

  // @Input() lieferrange;
  // @Input() latestdate;
  // @Input() earliestdate;

  // @Input() pdf;
  // @Input() pdf1;
  // @Input() pdf2;
  // @Input() ausgewaehlterRatenplan;
  // @Input() mtanInput;
  // @Input() showResendmTan;
  // @Input() showMtanError;
  // @Input() gutscheinData: any;
  showPdfError: boolean = false;
  // starSvg: any;
  // infoSvg: any;
  // calculatrSvg: any;
  @Input() priceChangewithPaymentMode = false;

  orderAddressList: any = {
    billingAddress: [],
    deliveryAddress: [],
  };


  deliveryAddress: any = [];
  billingAddress: any = [];
  // @Input() stationCount;
  @Input() billingAddressEnable: any;
  @Input() selectedBiilingOption: any;
  @Input() loader: boolean;
  orderStatus = '';


  placeOrderBtn: any;
  easyCreditButton: Boolean = false;
  // localPdfCheckedData: any = []

  constructor(private smallPcService: SmallPcService,
    public languageService: LanguageService,
    public httpClient: HttpClient,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public ls: LocalstorgeService
  ) {

    if (window && typeof window.scrollTo === 'function') {
      window.scrollTo(0, 0);
    }
    let langData = this.languageService.getLocalLanguageData();
    this.languageData = langData["web-pc"] || {};
    this.languageService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data["web-pc"] || {};
    });
  };


  ratePaymentActive: any;
  async ngOnInit() {

    this.placeOrderBtn = this.languageData.Bestatigung.Var_Zahlungspflichtig_bestellen || 'Jetzt kaufen';
    this.getDynamicSVG(this.svgs);
    this.orderPlaced = false;

    this.label = this.productInfoForWebPC.unitsName;
    this.priceFactor = this.productInfoForWebPC.priceFactor;
    this.unitsSymbol = this.productInfoForWebPC.unitsSymbolForPc;
    this.languageVar = this.productInfoForWebPC.language;
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    this.orderStatus = decodeURIComponent(params.get("tempOrderStatus"));
    this.url.includes('tempOrderStatus') ? this.orderStatus = decodeURIComponent(params.get("tempOrderStatus")) : this.orderStatus = '';
    console.log(this.orderStatus, 'orderStatus');
    // alert(this.sessionId)
    //getting session data stored in localstorage
    this.updateBasicData();

    var params = new URLSearchParams(this.url);/*testcalc*/
    // this.sessionId = decodeURIComponent(params.get("sid"));

    // let addresses: any = await this.smallPcService.getRecentAddress(this.sessionId);
    // if (addresses) {
    //   this.addresses = { ...addresses };
    //   this.setDefaultAddressesEvent.emit(this.addresses);
    // };
  };

  trackByFn(index, item) {
    return index;
  };

  ngOnChanges() {
    // if(this.localPdfCheckedData.length) {
    //   this.localPdfCheckedData = this.localPdfCheckedData;
    // } else {
    //   this.localPdfCheckedData = this.thridStagePdfs;
    // };

    if (this.voucher.valid) {
      this.touchedVoucherInput = false;
    } else {
      this.smallPcService.getNewSessionData(this.sessionId).then(async (data: any) => {
        if (data?.voucher?.voucherCode) {
          this.touchedVoucherInput = true;
        } else {
          this.touchedVoucherInput = false;
        };
      });
    };
  };


  getDynamicSVG(svgs) {
    svgs.forEach((svgItem) => {
      let item = svgItem;
      let first = item.split('.svg')[0];
      let iconNameArray = first.split('/');
      let iconName = iconNameArray.pop();

      this.httpClient.get(item, { responseType: 'text' }).subscribe((logo) => {
        logo = logo.replace(/#333/ig, 'var(--primaryColour)'); //fill
        logo = logo.replace(/#111/ig, 'var(--highlightColour)'); //storke
        this[iconName] = this.sanitizer.bypassSecurityTrustHtml(logo);
      })
    });
  };

  alternativePopup(event) {
    let dialogRef = this.dialog.open(AlternativeTextInfoComponent, { panelClass: 'my-full-screen-dialog', width: '500px' });
    dialogRef.componentInstance.infotext = event;
  };

  page1() {
    this.page1Event.emit()
  };

  page2() {
    this.page2Event.emit()
  };

  specialtextpopup1(text) {
    let dialogRef = this.dialog.open(InfopopupComponent, {
      panelClass: "my-full-screen-dialog",
      width: "50vw",
    });

    dialogRef.componentInstance.infotext = text;
  };

  steuerhinweisPopup(text) {
    let dialogRef = this.dialog.open(SteuerhinweisPopupComponent, {
      panelClass: "my-full-screen-dialog",
      width: "500px",
    });
    dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.infotext = text;
  };

  downloadFile(event) {
    this.downloadFileEvent.emit(event);
  };

  ZahlungMail() {
    if (this.third_stage_pricechange) {
      this.orderPlaced = false;
    }
    /**
     * 
     * 
      this.orderPlaced = true;
       if (this.orderPlaced) {
      let cond = this.thridStagePdfs.filter(e => e.mandatory_checkbox && !e.checked);
      this.showPdfError = cond.length ? true : false;
      if (!this.showPdfError) {
        this.ZahlungMailEvent.emit();
      };
      };
     */

    console.log("zahlung mail");

    let finalCheckSid = localStorage.getItem('sid');

    console.log("finalCheckSid", finalCheckSid);
    if (finalCheckSid == 'undefined' || finalCheckSid == 'null' || finalCheckSid == null) {
      finalCheckSid = this.ls.getSessionId();
    }

    const finalSessionData = localStorage.getItem(finalCheckSid);
    console.log("finalSessionData", finalSessionData);
    // if (!finalSessionData) {
    //   return;
    // }

    if (this.sessionId !== finalCheckSid || !finalSessionData) {
      console.log("sessionidid and localstorage session id not matched");

      const params = new URLSearchParams(window.location.href);
      const tempOrderId = decodeURIComponent(params.get("tempOrderId") || '');
      console.log("tempOrderId", tempOrderId);
      console.log(finalCheckSid, 2021);
      this.smallPcService.getNewSessionData(finalCheckSid).then(async (data: any) => {
        this.sessionData = data;
      });
      return this.triggerOrderFunc();

    } else {
      //everthing looks good sames session id and url session id matched so placing order

      if (!this.orderPlaced) {
        return this.triggerOrderFunc();
      }

      return;
    }





  };

  co2Compensate(qualityId) {
    let name = "CO2COMPENSATE";
    let event = qualityId
    this.updateOptionsEvent.emit({ event, name });
  };

  priceChangeAccept(event) {
    this.priceChangeAcceptEvent.emit(event);
  };

  productTaxCheck(event) {
    this.productTaxAcceptEvent.emit(event);
  };

  // vatChangeAccept(event) {
  //   this.vatChargesAcceptEvent.emit(event);
  // };

  // onOfferTokenEnter(event) {
  //   this.onOfferTokenEnterEvent.emit(event);
  // };


  // resendMtan(tbVorgangskennung) {
  //   this.showResendmTan = false;
  //   setTimeout(() => {
  //     this.showResendmTan = true;
  //   }, 30000)
  //   this.resendMtanEvent.emit(tbVorgangskennung);
  // };

  check(id) {
    return (this.localSessionUpdatedData.co2Compensation && this.localSessionUpdatedData.co2Compensation.includes(id)) ? true : false;
  };

  pdfOnSelect(event, pdfData) {
    pdfData['checked'] = event;
    pdfData['touched'] = true;
    if (this.orderPlaced || this.verifyPdfsAgian) {
      let cond = this.thridStagePdfs.filter(e => e.mandatory_checkbox && !e.checked);
      this.showPdfError = cond.length ? true : false;
    };
  };

  voucherfun(event) {
    let name = "VOUCHER";
    event = event.target.value;
    this.voucherCode = event;
    this.updateOptionsEvent.emit({ event, name });
    setTimeout(() => {
      this.updateBasicData()
    }, 2000)
  };

  voucherValidity(code) {
    this.voucher['voucherCode'] = code;
    let event = this.voucher['voucherCode'];
    let name = 'VOUCHER';
    this.voucherCode = code;
    this.updateOptionsEvent.emit({ event, name });
  };
  paymentContinue() {
    let cond = this.thridStagePdfs.filter(e => e.mandatory_checkbox && !e.checked);
    this.showPdfError = cond.length ? true : false;
    if (!this.showPdfError) {
      const dialogRef = this.dialog.open(PaymentContinueComponent, {
        panelClass: 'my-full-screen-dialog',
        width: '500px',
        disableClose: true
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        console.log(res, 121)
        if (res?.close) {
          // this.dialog.closeAll();
          this.ZahlungMail();
        } else {

        }
      }, (err) => console.error(err));
    };
  };

  updateBasicData() {
    this.smallPcService.getNewSessionData(this.sessionId).then(async (data: any) => {
      console.log(data);
      this.sessionData = data;
      this.localOptions = this.sessionData.localOptions;
      this.timeDepandentSurcharges = this.sessionData.cart[0]?.timeDepandentSurcharges;
      this.voucherNett = Math.abs(this.sessionData?.cart[0]?.voucherNett);
      this.localSessionUpdatedData = this.sessionData;
      this.orderAddressList = this.sessionData?.orderAddresses ? this.sessionData?.orderAddresses : {};
      this.billingAddress = this.orderAddressList.billingAddress || [];
      this.deliveryAddress = this.orderAddressList.deliveryAddress || [];
      this.voucherCode = this.sessionData?.voucher?.voucherCode ? this.sessionData?.voucher?.voucherCode : '';
      this.voucher.valid = this.sessionData?.voucher?.valid ? this.sessionData?.voucher?.valid : '';
      // let ratePayEnabled = this.localOptions.filter((option: any) => option.name == 'Ratenzahlung');
      this.ratePaymentActive = this.sessionData.ratePaymentSettings;
      if (this.ratePaymentActive && this.ratePaymentActive.status) {
        this.placeOrderBtn = this.languageData.Bestellubersicht['VarWeb-PcBestellubersicht1'] || 'Weiter zum Ratenkauf';
        this.easyCreditButton = true;
      };
    });
  }

  triggerOrderFunc() {

    this.orderPlaced = true;
    if (this.orderPlaced) {
      let cond = this.thridStagePdfs.filter(e => e.mandatory_checkbox && !e.checked);
      this.showPdfError = cond.length ? true : false;
      if (!this.showPdfError) {
        this.ZahlungMailEvent.emit();
      } else {
        this.orderPlaced = false;
        this.verifyPdfsAgian = true ;
      };
    };
  }
};

