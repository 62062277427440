import { Component, OnInit } from '@angular/core';
import { SmallPcService } from 'app/Services/small-pc.service';
import { ICustomWindow, WindowRefService } from 'app/Services/window.service';

@Component({
  selector: 'Aupris-Kleiner-Preisrechner',
  templateUrl: './aupris-kleiner-preisrechner.component.html',
  styleUrls: ['./aupris-kleiner-preisrechner.component.css']
})
export class AuprisKleinerPreisrechnerComponent implements OnInit {
  tag = 1;
  tagData = [];

  private _window: ICustomWindow;
  tracking: Boolean = false;
  constructor(
    public smallPc: SmallPcService,
    private windowRef: WindowRefService,
  ) { }

  ngOnInit(): void {
    this.smallPc.getWebTags('1').subscribe((webLinkData) => {
      if (webLinkData.success) {
        this.tagData = webLinkData.data;
      };
    });
    
    //for liveTracking
    this._window = this.windowRef.nativeWindow;
    let url = this._window.location.href;
    let params = new URLSearchParams(url);
    if (decodeURIComponent(params.get("view")) == 'tracking') this.tracking = true;
  };
};
