import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { AdminService } from 'app/Services/admin.service';
import { LanguageService } from '../Services/language.service';
import { ICustomWindow, WindowRefService } from '../Services/window.service';
import { environment } from './../../environments/environment';
import { SmallPcService } from '../Services/small-pc.service';
import { MatDialog } from '@angular/material/dialog';
import { SteuerhinweisPopupComponent } from '../popups/steuerhinweis-popup/steuerhinweis-popup.component';
import { InfopopupComponent } from '../infopopup/infopopup.component';
import { PortalAuthService } from 'app/login/auth.service';
import { BigpcService } from '../Services/bigpc.service';
import { DatePipe } from '@angular/common';
import { PricedetailPopupComponent } from '../popups/pricedetail-popup/pricedetail-popup.component';
import { LocalstorgeService } from '../Services/localstorge.service';

@Component({
  selector: 'app-alarm-prices',
  templateUrl: './alarm-prices.component.html',
  styleUrls: ['./alarm-prices.component.css']
})
export class AlarmPricesComponent implements OnInit {
  @Input('theme') theme: string;
  minMaxData: any;
  viewsnack5: boolean = false;
  viewsnack1: boolean = false;
  viewsnack2: boolean = false;
  colors: any;
  bigpclink;
  hostname: any;
  url: any;
  plzOptions = [];
  qualityArray = [];
  public products = [];
  public product = [
    { name: "Heizol", value: "Heizol" },
    { name: "Pellets", value: "Pellets" }];

  selectedItem: any;
  clickedoption: string = 'selection';
  clickedPage: string = 'Page1';
  page_1: boolean = true;
  page_2: boolean = false;
  page_3: boolean = false;
  page_4: boolean = false;
  private _window: ICustomWindow;
  languageData: any;
  mandatoryCheck: boolean = false;
  Lieferort;
  volume: any;
  weight: any;
  currency: any;
  currency_symbol: any;
  currency_short: any;
  volume_short: any;
  weight_short: any;
  options;
  final_PC;
  offer: any;
  plzOptCheck: boolean = false;
  localoptions = this.setLocalOptions();
  showFullScreenLoading: boolean = false;
  isPriceFormValid: boolean;
  @Output() check = new EventEmitter();
  @Output() showOverview = new EventEmitter<boolean>();
  // @Output() pricealarm = new EventEmitter<boolean>();
  msg: any;
  expiryRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  max_heatoil: any;
  min_heatoil: any;
  max_pellets: any;
  min_pellets: any;
  phoneRegex = /^0[0-9].*$/;
  @Input() labels;
  desiredPriceLimit: any;
  emptyProduct: any;
  noData: boolean;
  productsBySPC: any;
  PCOptions = [];
  sessionId: any;
  sessionData: any;
  selectedQuantity: any;
  RemainingMenge: number;
  stationCount: any;
  postalCodeDetails: any;
  deliveryDates: any;
  priceCalculationData: any;
  @Input("productInfoForWebPC") productInfoForWebPC: any = {};
  @Input("langVariable") langVariable: String = "GEN";
  symbol: any;
  languageVar: any;
  SecoendSatgeData: boolean = false;
  priceFactor: any;
  dynamicOptionData: any = [];
  constructor(
    private windowRef: WindowRefService,
    private fb: FormBuilder,
    private admin: AdminService,
    private langService: LanguageService,
    private SmallPcService: SmallPcService,
    private portalAuthService: PortalAuthService,
    private bigPcNew: BigpcService,
    public dialog: MatDialog,
    private localStorage: LocalstorgeService
  ) {
    this._window = this.windowRef.nativeWindow;
    this.url = window.location.href;
    var params = new URLSearchParams(this.url);
    this.sessionId = decodeURIComponent(params.get("sid"));
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });
  };

  currentPage: any;
  currentStage: any;

  public priceAlarm: FormGroup;
  public notificationForm: FormGroup;
  public pricesForm: FormGroup;

  alarmLocalData: any;

  private scrollHelper: ScrollHelper = new ScrollHelper();
  async ngOnInit() {
    // this.selectedPage(this.clickedPage)
    this.symbol = this.productInfoForWebPC.unitsSymbolForPc;
    this.languageVar = this.productInfoForWebPC.language;
    this.langVariable = this.productInfoForWebPC.language;
    this.priceFactor = this.productInfoForWebPC.priceFactor;
    this.labels = this.productInfoForWebPC.unitsName;
    this.SmallPcService.getNewSessionData(this.sessionId).then(async (data: any) => {
      this.sessionData = data;
      this.selectedQuantity = this.sessionData.quantity ? this.sessionData.quantity : 0;
      this.RemainingMenge = Number(this.sessionData.quantity);
      this.stationCount = this.sessionData.stations ? this.sessionData.stations : 1;
      this.postalCodeDetails = this.sessionData.postalCode;
      this.deliveryDates = this.sessionData.desiredDateSettings;

      // this.getProductData(this.sessionData);
      // this.dynamicOptionsDataFetch(this.sessionData);
      // this.setFormControls();
      // this.deliveryDates.days = this.sessionData.desiredDateSettings.days;
      // this.deliveryDates.range = this.sessionData.desiredDateSettings.range;
      // this.deliveryDates.rangeAvailable = this.sessionData.desiredDateSettings.rangeAvailable;;
      // this.deliveryDates.fromDate = this.sessionData.desiredDateSettings.fromDate;
      // this.deliveryDates.toDate = this.sessionData.desiredDateSettings.toDate;
      // this.deliveryDates.wunstermin = this.sessionData.desiredDateSettings.wunstermin;
      // this.priceCalculatorDefaultOptionsFetch(this.sessionData);
    });

    this.emptyProduct = "Heizöl";

    this.priceAlarm = this.fb.group({
      product: ['', Validators.required],
      menge: ['', Validators.required],
      quality: ['', Validators.required],
      pincode: ['', Validators.required],
      plzId: ['']
    });

    this.notificationForm = this.fb.group({
      emailCheck: [''],
      Email: ['', Validators.pattern(this.expiryRegex)],
      smsCheck: [''],
      sms: [''],
      months: ['', Validators.required],
      agreeCheck: [''],
    });

    this.pricesForm = this.fb.group({
      desiredPriceType: ['', Validators.required],
      priceLimitType: ['', Validators.required],
      desiredPriceLimit: ['', Validators.required],
      priceLimit: ['', Validators.required],
    });

    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(this.url);/*testcalc*/
    this.addStageHere();
    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    }
    this.setProducts();
  };


  addStageHere() {
    this.alarmLocalData = this.localStorage.getAlarmData();
    let url: string = this.url;
    let params = new URLSearchParams(this.url);
    this.currentPage = decodeURIComponent(params.get("page"));
    this.currentStage = decodeURIComponent(params.get("priceAlarmStage"));
    if (this.currentStage != 'null') {
      this.pageIdentify();
    } else {
      let newUrl = url;
      newUrl = `${newUrl}&priceAlarmStage=one`;
      window.history.replaceState('', document.title, newUrl);
    };
  };


  pageIdentify() {
    if (this.currentStage == "two") {
      this.page_1 = this.page_3 = false;
      this.page_2 = true;
      this.clickedPage = 'Page2';
      this.clickedoption = 'setprice';

      if (this.alarmLocalData) {
        this.SecoendSatgeData = true;
        this.pricesForm.patchValue(this.alarmLocalData);
      };
    } else if (this.currentStage == "three") {
      this.page_1 = this.page_2 = false;
      this.page_3 = true;
      this.clickedPage = 'Page3'
      this.clickedoption = 'notification';
      if (this.alarmLocalData) {
        this.getpriceCalculatorEvent();
        this.notificationForm.patchValue(this.alarmLocalData);
      };
    } else {
      this.page_3 = this.page_2 = false;
      this.page_1 = true;
      this.clickedPage = 'Page1';
      this.clickedoption = 'selection';
    }
  };


  setProducts() {
    this.bigPcNew.products({ tag: '' }).subscribe((productsData) => {
      if (productsData.success) {
        this.products = productsData.data.dynamicProduct;
        if (this.alarmLocalData) {
          this.priceAlarm.patchValue(this.alarmLocalData);
          let zipCodeArray = this.alarmLocalData.pincode.split(' ');
          let selectedZipCode = zipCodeArray[0];
          this.getPostalCode(selectedZipCode);
          let checkExit = this.products.find(doc => doc._id == this.alarmLocalData.product);
          if (checkExit) {
            this.changeProduct(checkExit._id);
          } else {
            this.localStorage.removeAlarmFromLocal();
            this.priceAlarm.patchValue({});
            this.priceAlarm['controls']['product'].setValue(this.products[0]._id);
            this.changeProduct(this.products[0]._id);

          };
        } else {
          this.priceAlarm['controls']['product'].setValue(this.products[0]._id);
          this.changeProduct(this.products[0]._id);
        };
      };
    });
  };

  selectedPage(page) {
    let presentPageNo = parseInt(this.clickedPage.slice(3))
    let clickedPageNo = parseInt(page.slice(3)) || 1;
    if (clickedPageNo > presentPageNo) {
      return false
    }
    this[page]();
  };

  Page1() {
    this.clickedPage = 'Page1';
    this.clickedoption = 'selection';
    this.whileChangeBackwardPage();
  };

  // pageEmitHandler(event) {
  //   this.clickedPage = 'Page1';
  //   this.page_2 = false;
  //   this.page_3 = false;
  //   this.page_4 = false;
  //   this.clickedoption = 'selection';
  // }

  Page2() {
    if (this.plzOptCheck && this.priceAlarm.valid) {
      this.clickedPage = 'Page2';
      this.clickedoption = 'setprice';
    }
  };

  ngOnChanges() {
    // this.label = this.productInfoForWebPC.unitsName;

    this.symbol = this.productInfoForWebPC.unitsSymbolForPc;
    this.languageVar = this.productInfoForWebPC.language;
    this.langVariable = this.productInfoForWebPC.language;
  };

  Page3() {
    let price1 = this.pricesForm.controls['desiredPriceType'].valid && this.pricesForm.controls['desiredPriceLimit'].valid
    let price2 = this.pricesForm.controls['priceLimitType'].valid && this.pricesForm.controls['priceLimit'].valid
    if (price1 || price2) {
      this.clickedPage = 'Page3'
      this.clickedoption = 'notification';

    }
  };

  getPostalCode(e) {
    if (e.length >= 4) {
      this.localoptions.Produkt.name = this.priceAlarm.value.product;
      let product = this.localoptions.Produkt.name ? this.localoptions.Produkt.name : this.emptyProduct
      let objectname = { event: e, product };
      this.showFullScreenLoading = true;

      this.bigPcNew.postalCode(objectname).subscribe((data: any) => {
        this.showFullScreenLoading = false;
        if (data.success && data.data.length >= 0) {
          this.plzOptCheck = true;
          this.plzOptions = data.data;
          let Options = data.data
          this.plzOptions = Options.sort((a, b) => {
            if (`${a.country_code} ${a.place_name} ${a.suburb}` < `${b.country_code} ${b.place_name} ${b.suburb}`) { return -1; }
            if (`${a.country_code} ${a.place_name} ${a.suburb}` > `${b.country_code} ${b.place_name} ${b.suburb}`) { return 1; }
          })

          this.msg = "";
        }
        else {
          this.plzOptCheck = false;
          this.plzOptions.length = 0
          this.msg = "Nur Auswahl aus dem Dropdown Menü zulässig!"
        }
      }, () => {
        this.showFullScreenLoading = false;
      })
    } else {
      this.plzOptions = []
    }
  };

  selectPlz(e) {
    this.selectedItem = e;
    this.priceAlarm['controls']['pincode'].setValue(e.country_code + ' ' + e.place_name + ' ' + e.suburb)
    this.priceAlarm['controls']['plzId'].setValue(e._id)
    this.plzOptions.length = 0

    this.Lieferort = {
      _id: e._id,
      title: e.country_code + ' ' + e.place_name + ' ' + e.suburb
    }
  };

  changeProduct(product) {
    this.showFullScreenLoading = true;
    this.bigPcNew.dynamicProductInfoForWebPc({ tag: this.sessionData.tag, dynamicProductId: product, all: true }).subscribe((webPcData: any) => {
      this.showFullScreenLoading = false;
      this.theme = webPcData.data.themeModel == 1 ? 'Theme1' : 'Theme2';
      this.productInfoForWebPC = webPcData.data;
      this.getQualities(product);
      this.langVariable = this.productInfoForWebPC.language;
    }, () => {
      this.showFullScreenLoading = false;
    });
  };

  getQualities(product) {
    this.showFullScreenLoading = true;
    this.bigPcNew.getQualitiesList({ dynamicProductId: product, host: this.hostname, test: 'test' }).subscribe((data: any) => {
      this.showFullScreenLoading = false;
      if (data) {
        this.qualityArray = data.data;
      }
    }, () => {
      this.showFullScreenLoading = false;
    });
  };

 async submitValue() {
    let checkFirstStageValidation = await this.checkMinMaxValue(this.priceAlarm.value.menge);
    console.log(this.showErrorMsgQuantity, 3833);
    if(!this.showErrorMsgQuantity) {
      this.displayPage2();
    }
  };

  showErrorMsgQuantity:boolean = false;
  showMsgQuantity:any = '';
  checkMinMaxValue(event) {
    let min: number = this.productInfoForWebPC.minAmount;
    let max: number = this.productInfoForWebPC.maxAmount;
      let inputValue = this.priceAlarm.value.menge;
      inputValue = parseInt(inputValue);
      if (inputValue <= max && inputValue >= min) {
        this.showErrorMsgQuantity = false;
      } else {
        this.showErrorMsgQuantity = true;
        this.showMsgQuantity = ' Bestellmenge ' + '' + this.productInfoForWebPC.minAmount + ' ' + 'bis' + ' ' + this.productInfoForWebPC.maxAmount + ' ' + this.productInfoForWebPC.unitsName;
      };
  };

  displayPage2() {
    if (this.plzOptCheck && this.priceAlarm.valid) {
      let setData = {
        ...this.priceAlarm.value,
        ...this.pricesForm.value,
        ...this.notificationForm.value
      };
      this.setDataIntoLocal(setData);
      this.clickedoption = 'setprice';
      this.clickedPage = 'Page2';
      this.page_2 = true;
      this.page_3 = false;
      this.page_1 = false;
      this.whileChangeForwardPage();
    }
  };


  whileChangeForwardPage() {

    this.url = window.location.href; /*testcalc*/
    let newUrl: string = this.url;
    if (newUrl.includes("priceAlarmStage=one")) {
      newUrl = newUrl.replace("&priceAlarmStage=one", "&priceAlarmStage=two");
    } else if (newUrl.includes("&priceAlarmStage=two")) {
      newUrl = newUrl.replace("&priceAlarmStage=two", "&priceAlarmStage=three");
    };

    window.history.replaceState('', document.title, newUrl);

    this.scrollHelper.scrollToFirst("scrolltotopprogress");
    setTimeout(() => {
      var errorDivs = document.getElementsByClassName("scrolltotopprogress");
      if (errorDivs.length > 0) {
        errorDivs[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (window && typeof window.scrollBy === 'function') {
          window.scrollBy(0, -50);
        }
      }
    }, 50);
  };

  whileChangeBackwardPage() {
    this.url = window.location.href; /*testcalc*/
    let newUrl: string = this.url;
    if (newUrl.includes("priceAlarmStage=one")) {
      newUrl = newUrl.replace("&priceAlarmStage=one", "");
    } else if (newUrl.includes("&priceAlarmStage=two")) {
      newUrl = newUrl.replace("&priceAlarmStage=two", "&priceAlarmStage=one");
    } else if (newUrl.includes("&priceAlarmStage=three")) {
      newUrl = newUrl.replace("&priceAlarmStage=three", "&priceAlarmStage=two");
    }

    window.history.replaceState('', document.title, newUrl);

    this.scrollHelper.scrollToFirst("scrolltotopprogress");

    setTimeout(() => {
      var errorDivs = document.getElementsByClassName("scrolltotopprogress");
      if (errorDivs.length > 0) {
        errorDivs[0].scrollIntoView({ behavior: "smooth", block: "start" });
        if (window && typeof window.scrollBy === 'function') {
          window.scrollBy(0, -10);
        }
      };
    }, 50);
  };

  displayThridStage() {
    this.checkValidation();
    if (this.isPriceFormValid) {
      this.clickedPage = 'Page3';
      this.clickedoption = 'notification'
      this.page_1 = false;
      this.page_2 = false;
      this.page_3 = true;
      let setData = {
        ...this.priceAlarm.value,
        ...this.pricesForm.value,
        ...this.notificationForm.value
      };
      this.setDataIntoLocal(setData);
      this.whileChangeForwardPage();
    }
  };

  stageThreeEventHandler() {
    this.displayThridStage()
  };

  setDataIntoLocal(formData) {
    this.localStorage.setPriceAlarm(formData);
  };

  gotoPage2() {
    this.clickedoption = 'setprice';
    this.clickedPage = 'Page2';
    this.page_2 = true;
    this.page_3 = false;
    this.page_1 = false;
    this.alarmLocalData = this.localStorage.getAlarmData();
    if (this.alarmLocalData) {
      this.SecoendSatgeData = true;
      this.pricesForm.patchValue(this.alarmLocalData);
    }
    this.whileChangeBackwardPage();
  };

  change(event) {
    this.whileChangeBackwardPage();
    this.check.next(event);
  };

  getPostalCodeEvent(e: any) {
    this.getPostalCode(e)
  };

  selectPlzEvent(e: any) {
    this.selectPlz(e)
  };

  changeProductEvent(product: any) {
    this.changeProduct(product);
  };

  submitValueEvent() {
    this.submitValue();
  };

  page1Event() {
    this.Page1()
  };

  page2Event() {
    this.Page2()
  };

  viewsnack: boolean = false;

  page3Event() {
    if (!this.notificationForm.value.agreeCheck) {
      // this.viewsnack = true;
      // setTimeout(() => {
      //   this.viewsnack = false;
      // }, 4500)
      return false
    };
    let setData = {
      ...this.priceAlarm.value,
      ...this.pricesForm.value,
      ...this.notificationForm.value
    }
    this.setDataIntoLocal(setData);

    this.setalarm();
  };

  prepareExpirydate(date, months) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };

  async setalarm() {
    let productName = this.products.filter(e => e._id == this.priceAlarm.value.product);
    let expiryDate = await this.prepareExpirydate(new Date(), this.notificationForm.value.months);
    let alarmInfo = {
      plzId: this.priceAlarm.value.plzId,
      plzOrt: this.priceAlarm.value.pincode,
      quantity: parseFloat(this.priceAlarm.value.menge),

      product: productName[0]?.appName,
      dynamicProductId: productName[0]?._id,
      qualityId: this.priceAlarm.value.quality.productId,
      qualityName: this.offer.product.label,

      priceLimit: parseFloat(this.convertToNormalPrice(this.pricesForm.value.priceLimit)),
      priceLimitType: this.pricesForm.value.priceLimitType,
      desiredPriceLimit: parseFloat(this.convertToNormalPrice(this.pricesForm.value.desiredPriceLimit)),
      desiredPriceType: this.pricesForm.value.desiredPriceType,
      Email: this.notificationForm.value.Email,
      emailCheck: this.notificationForm.value.emailCheck,
      sms: this.notificationForm.value.sms,
      smsCheck: this.notificationForm.value.smsCheck,
      months: this.notificationForm.value.months,
      createdDate: new Date(),
      expiryDate: expiryDate,
      volume: this.productInfoForWebPC.unitsName,
      volume_short: this.productInfoForWebPC.unitsSymbolForPc,
      currency: this.productInfoForWebPC.currencyName,
      currency_symbol: this.productInfoForWebPC.currencySymbol,
      currency_short: this.productInfoForWebPC.currencyCode,
      priceFactor: parseFloat(this.productInfoForWebPC.priceFactor),
      mode: 'prod',
      thirdPartySite: environment.testSite_or_thirdPartySites

    };
    this.showFullScreenLoading = true;
    if (this.priceAlarm.valid && this.notificationForm.valid && this.isPriceFormValid) {
      this.admin.setAlarm(alarmInfo).subscribe((response: any) => {
        this.showFullScreenLoading = false;
        if (response.success) {
          let keyName = "&priceAlarmStage";
          this.removeKeyFromUrl(keyName);
          this.localStorage.removeAlarmFromLocal();
          this.showOverview.emit();
          this.portalAuthService.updateSession();
          this.clickedPage = 'Page1';
          this.page_3 = this.page_2 = false;
          this.page_1 = true;
          this.clickedoption = 'selection';
          this.currentPage = 'one';
          this.currentStage = 'one';
          let url = this.url.replace(/three/ig, 'one')
          let newUrl: string = url;
          if (newUrl.includes("priceAlarmStage=one")) {
            newUrl = newUrl.replace("&priceAlarmStage=one", "");
          }

          window.history.replaceState('', document.title, newUrl);

          this.scrollHelper.scrollToFirst("scrolltotopprogress");

          setTimeout(() => {
            var errorDivs = document.getElementsByClassName("scrolltotopprogress");
            if (errorDivs.length > 0) {
              errorDivs[0].scrollIntoView({ behavior: "smooth", block: "start" });
              if (window && typeof window.scrollBy === 'function') {
                window.scrollBy(0, -10);
              }
            };
          }, 50);
        }
      }, () => {
        this.showFullScreenLoading = false;
      })
    } else {
      this.showFullScreenLoading = false;
    };
  };

  removeKeyFromUrl(key) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;

    if (newUrl.includes(key)) {
      newUrl = newUrl.replace(`${key}=${this.currentStage}`, "");
      window.history.replaceState('', document.title, newUrl);
    };
  };

  convertToNormalPrice(value) {
    if (value != undefined && value != null)
      value = value.replace(',', '.');
    if (value.split(".").length - 1 > 1) {
      if (value.indexOf('.') <= 3) {
        var x = value.indexOf('.');
        value = value.replace(value[x], '');
      }
    }
    return value;
  };

  gotoPage2Event() {
    this.gotoPage2();
  };

  steuerhinweisPopupEvent() {
    let textHere = this.offer.productTax.infoText;
    this.steuerhinweisPopup(textHere)
  };

  steuerhinweisPopup(text) {
    let dialogRef = this.dialog.open(
      SteuerhinweisPopupComponent,
      {
        panelClass: "my-full-screen-dialog",
        width: "500px",
      });
    dialogRef.componentInstance.infotext = text;
  };


  // steuerhinweisPopupEvent() {
  //   this.steuerhinweisPopup()
  // };

  // steuerhinweisPopup() {
  //   this.dialog.open(SteuerhinweisPopupComponent, { panelClass: 'my-full-screen-dialog', width: '500px', data: { ...this.minMaxData, product: this.localoptions.Produkt.name } });
  //   // dialogRef.componentInstance.colors = this.colors;
  // };

  specialtextpopup1Event(event) {
    this.specialtextpopup1(event)
  };

  specialtextpopup1(text) {
    let dialogRef = this.dialog.open(InfopopupComponent, { panelClass: 'my-full-screen-dialog', width: '50vw' });
    // dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.infotext = text;
  };

  getpriceCalculatorEvent() {
    this.dynamicOptionsDataFetch(this.sessionData);
  };


  settingPcDataInLocalStorage(optionsData) {
    //setting local options initially
    let localOptionSets = [];
    let previousLocalOptionsSets = this.sessionData.localOptions;
    // let dynamicProductId = localStorage.getItem("product");
    optionsData.forEach((newFetchedOptionSet) => {
      let optionData = previousLocalOptionsSets.find(existingLocalOptions => existingLocalOptions.optionSetId == newFetchedOptionSet['refCentralizedOptionSetId']);
      if (optionData && optionData.optionSetId == newFetchedOptionSet.refCentralizedOptionSetId && optionData.dynamicPcOptionsSetId == newFetchedOptionSet._id) {
        return localOptionSets.push(optionData);
      } else {
        let dynamicOptionsObj = newFetchedOptionSet.dynamicOptions[0];
        let dynamicCentralizedOptionsObj = newFetchedOptionSet.dynamicOptions[0].centralizedOptions;

        return localOptionSets.push({
          optionSetId: newFetchedOptionSet['refCentralizedOptionSetId'],
          optionId: dynamicOptionsObj['refCentralizedOptionId'],
          sort: dynamicOptionsObj['sortOrder'],
          dynamicPcOptionsSetId: newFetchedOptionSet['_id'],
          lable: newFetchedOptionSet['name'],
          name: dynamicCentralizedOptionsObj['name']
        });
      }
    });
    if (optionsData.length) {
      let sessionData = localStorage.getItem(this.sessionId);
      sessionData = JSON.parse(sessionData);
      sessionData['localOptions'] = localOptionSets;
      sessionData = JSON.stringify(sessionData);
      localStorage.setItem(this.sessionId, sessionData);
    }
  };

  lierferOptionDateSpan(optionInfo) {
    let lieferOption = {
      lieferOpionId: optionInfo._id
    };
    this.showFullScreenLoading = true;
    this.bigPcNew.calcuLieferTerminDates(lieferOption).subscribe((deliveryDates: any) => {
      this.showFullScreenLoading = false;
      if (deliveryDates.success) {
        this.formatDateRange(deliveryDates.data);
        this.fetchCalculatedQualities();
      } else {
        this.deliveryDates.fromDate = '';
        this.deliveryDates.toDate = '';
        this.deliveryDates.range = '';
        this.deliveryDates.rangeAvailable = false;
        this.deliveryDates.wunstermin = false;
        this.deliveryDates.days = 0;
        this.fetchCalculatedQualities();
      }
    }, err => {
      this.showFullScreenLoading = false;
    });
  };

  formatDateRange(deliverydates: { fromDate: any; toDate: any }) {
    let datepipe = new DatePipe("de-DE");
    let fromDate = deliverydates.fromDate
      ? datepipe.transform(deliverydates.fromDate, "dd.MM.yyyy")
      : null;
    let toDate = deliverydates.toDate
      ? datepipe.transform(deliverydates.toDate, "dd.MM.yyyy")
      : null;
    if (fromDate && toDate) {
      this.deliveryDates.range = fromDate + "-" + toDate;
      this.deliveryDates.rangeAvailable = true;
      this.deliveryDates.fromDate = fromDate;
      this.deliveryDates.toDate = toDate;
      this.deliveryDates.wunstermin = false;
      this.deliveryDates.days = 0;
    } else {
      this.deliveryDates.fromDate = '';
      this.deliveryDates.toDate = '';
      this.deliveryDates.range = '';
      this.deliveryDates.rangeAvailable = false;
      this.deliveryDates.wunstermin = false;
      this.deliveryDates.days = 0;
    };

    let sessionData = localStorage.getItem(this.sessionId);
    sessionData = JSON.parse(sessionData);
    sessionData['desiredDateSettings'] = this.deliveryDates;
    sessionData = JSON.stringify(sessionData);
    localStorage.setItem(this.sessionId, sessionData);
  };


  async dynamicOptionsDataFetch(sessionData) {
    this.alarmLocalData = await this.localStorage.getAlarmData();
    this.showFullScreenLoading = true;
    //dynamic pc options fetching
    let sendDocs = {
      dynamicProductId: this.alarmLocalData?.product,
      plzId: this.alarmLocalData.plzId,
    };
    this.bigPcNew.dynamicOptions().subscribe(async (dynamicOptions: any) => {
      this.showFullScreenLoading = false;
      if (dynamicOptions.success) {
        this.PCOptions = [];
        this.PCOptions = dynamicOptions.data;

        this.settingPcDataInLocalStorage(this.PCOptions);
        let setData: any = localStorage.getItem(this.sessionId);
        this.dynamicOptionData = [];
        this.PCOptions.map(each => {
          let prepareLocalOptions = {
            optionSetId: each.refCentralizedOptionSetId,
            optionId: each.dynamicOptions[0].refCentralizedOptionId,
            sort: each.dynamicOptions[0].sortOrder,
            dynamicPcOptionsSetId: each.dynamicOptions[0].refOptionSetId,
            lable: each.name,
            name: each.dynamicOptions[0].centralizedOptions.name,
            DOB: each.dynamicOptions[0].centralizedOptions.DOBStatus
          }
          this.dynamicOptionData.push(prepareLocalOptions);
        })

        this.sessionData = JSON.parse(setData);
        if (this.sessionData) {
          let getLieferOptions: any = this.PCOptions.filter(option => option.optionType == 7);
          let lieferOptionData;
          // if (getLieferOptions.length > 0 && this.sessionData.localOptions) {
          //   getLieferOptions[0].dynamicOptions.map((option: any) => {
          //     this.sessionData.localOptions.forEach((localOPtions: any) => {
          //       if (option.refCentralizedOptionSetId == localOPtions.optionSetId && option.refCentralizedOptionId == localOPtions.optionId) {
          //         lieferOptionData = option;
          //       }
          //     });
          //   });
          // };
          lieferOptionData = getLieferOptions[0].dynamicOptions[0];
          if (lieferOptionData) {
            this.lierferOptionDateSpan(lieferOptionData);
          } else {
            this.deliveryDates.fromDate = '';
            this.deliveryDates.toDate = '';
            this.deliveryDates.range = '';
            this.deliveryDates.rangeAvailable = false;
            this.deliveryDates.wunstermin = false;
            this.deliveryDates.days = 0;
            this.fetchCalculatedQualities();
          };
        } else {
          this.PCOptions = [];
        }
      }
    }, (err) => {
      this.showFullScreenLoading = false;
    });

  };


  async fetchCalculatedQualities() {
    // for co2componensation value copied from this.sessionData.co2Compensation
    // this.loader = true;
    this.showFullScreenLoading = true;
    let getSessionData = localStorage.getItem(this.sessionId);
    this.sessionData = JSON.parse(getSessionData);
    this.alarmLocalData = await this.localStorage.getAlarmData();
    let sendInputIds = {
      dynamicProductId: this.alarmLocalData.product,
      plzId: this.alarmLocalData.plzId,
      quantity: this.alarmLocalData.menge,

      localOptions: this.dynamicOptionData,
      stations: 1,

      co2Compensation: [],

      desiredDate: {
        days: this.sessionData.desiredDateSettings.days,
        fromDate: this.sessionData.desiredDateSettings.fromDate,
        toDate: this.sessionData.desiredDateSettings.toDate
      },
    }


    if (this.sessionData['ratePaymentSettings']['status']) {
      sendInputIds = Object.assign(sendInputIds, { zahlungSpan: this.sessionData['ratePaymentSettings']['zahlungRate'] });
    };

    this.bigPcNew.calculatedQualities(sendInputIds).subscribe((calculatedQualitiesData: any) => {
      this.showFullScreenLoading = false;
      if (calculatedQualitiesData.success) {
        this.final_PC = calculatedQualitiesData.data;
        let selectedQuality = (this.qualityArray || []).find(doc => doc.productId == this.priceAlarm.value.quality.productId);
        this.offer = calculatedQualitiesData.data.find(md => md.qualities.productId == selectedQuality.productId);
        this.SecoendSatgeData = true;
        this.bigPcNew.updatePriceAlramdata(this.offer);

        if (!this.offer) {
          this.noData = true;
        };
      } else {
        this.final_PC = [];
      }
    }, () => {
      this.showFullScreenLoading = false;
    })
  };


  pricedetailsEvent(event) {
    this.pricedetails(event);
  };

  pricedetails(offer) {
    console.log(offer)
    offer.quantity = this.alarmLocalData.menge;
    let dialogRef = this.dialog.open(
      PricedetailPopupComponent,
      {
        panelClass: "my-full-screen-dialog",
        width: "500px",
        data: offer,
      });
    // dialogRef.componentInstance.colors = this.colors;
    dialogRef.componentInstance.symbol = offer.units.symbol;
    dialogRef.componentInstance.languageVar = offer.language;
    dialogRef.componentInstance.priceFactor = offer.priceFactor;
  };

  setLocalOptions() {
    return {
      Produkt: { name: '' },
      Lieferort: { _id: '', title: '' },
      Liter: { value: '' },
      Abladestellen: { number: 1 },
      Liefertermin: { _id: "", name: "" },
      Lieferzeiten: { _id: "", name: "" },
      Tankwagen: { _id: "", name: "" },
      Schlauchlange: { _id: "", name: "" },
      Zahlungsart: { _id: "", name: "" },
      Schlauchlange_pellets: { _id: "", name: "" },
      Gutschein: '',
      Wunschtermin: { name: "" },
      fromDate: '',
      toDate: '',
      latestDate: '',
      earliestDate: '',
    };
  };

  checkValidation() {

    console.log(this.pricesForm.controls['priceLimit'].value, 'priceLimit value', 9844);
    console.log(this.pricesForm.controls['priceLimit'].valid, 'priceLimit', 9844);
    console.log(this.pricesForm.controls['priceLimitType'].valid, 'priceLimitType', 9844);

    if ((this.pricesForm.controls['desiredPriceType'].valid && this.pricesForm.controls['desiredPriceLimit'].valid)
      || (this.pricesForm.controls['priceLimitType'].valid && this.pricesForm.controls['priceLimit'].valid)) {
      this.isPriceFormValid = true;
      if (this.pricesForm.controls['desiredPriceLimit'].value && !(this.pricesForm.controls['desiredPriceType'].valid && this.pricesForm.controls['desiredPriceLimit'].valid)) {
        this.isPriceFormValid = false;
        this.viewsnack1 = true;
        // setTimeout(() => {
        //   this.viewsnack1 = false;
        // }, 4500)
      } else {
        this.viewsnack1 = false;
      }

      if (this.pricesForm.controls['priceLimit'].value && !(this.pricesForm.controls['priceLimitType'].valid && this.pricesForm.controls['priceLimit'].valid)) {
        this.isPriceFormValid = false;
        this.viewsnack2 = true;
        // setTimeout(() => {
        //   this.viewsnack2 = false;
        // }, 4500)
      } else {
        this.viewsnack2 = false;
      };

    } else {
      this.isPriceFormValid = false;
      if (this.pricesForm.controls['desiredPriceType'].valid && !this.pricesForm.controls['desiredPriceLimit'].valid) {
        this.viewsnack1 = true;
      } else {
        this.viewsnack1 = false;
      };

      if (this.pricesForm.controls['priceLimit'].value && !this.pricesForm.controls['priceLimit'].valid) {
        this.viewsnack2 = true;
      } else {
        this.viewsnack2 = false;
      };
    };
    // console.log(this.viewsnack1, this.viewsnack2, 9844);
  };

}



export class ScrollHelper {
  private classToScrollTo: string = null;

  scrollToFirst(className: string) {
    this.classToScrollTo = className;
  }

  doScroll() {
    if (!this.classToScrollTo) {
      return;
    }
    try {
      var elements = document.getElementsByClassName(this.classToScrollTo);
      if (elements.length == 0) {
        return;
      }
      elements[0].scrollIntoView();
    } finally {
      this.classToScrollTo = null;
    }
  }
}