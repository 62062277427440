import { Component, OnInit, ElementRef, OnDestroy, OnChanges } from '@angular/core';
import { WindowRefService, ICustomWindow } from '../Services/window.service';
import { LanguageService } from '../Services/language.service';
import { environment } from '../../environments/environment';
import { CommonService } from 'app/common.service';
import { PortalAuthService } from 'app/login/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import cssVars from 'css-vars-ponyfill';
import { SmallPcService } from '../Services/small-pc.service'
import { SessionDataNew } from 'app/models/newSession.interface';
import { BigpcService } from 'app/Services/bigpc.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectionService } from 'app/Services/device-detection.service';
import { MatDialog } from '@angular/material/dialog';
import { RatePaymentSelectionPopupComponent } from 'app/popups/rate-payment-selection-popup/rate-payment-selection-popup.component';
import { OrderService } from 'app/Services/order.service';

@Component({
  selector: 'Aupris-Bestell-Preisrechner',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})

export class AppComponent2 implements OnInit, OnDestroy {
  language: string = localStorage.getItem('appLanguage') || 'GEN';
  languageData: any;
  hostname: any;
  url: any;
  colors: any;
  private _window: ICustomWindow;
  bigpc: boolean = false;
  verification: boolean = false;
  datasecurity_verification: boolean = false;
  isLoggedIn: boolean = false;
  dataSecurity_View: boolean = false;
  sideBar: boolean = false;
  userVerification: boolean = false;
  updateEmailVerification: boolean = false;
  private $unsubscriber: Subject<any> = new Subject();
  isGuestUser: Boolean = false;
  resetpassword: boolean = false;
  pcOff_Pbbackend: boolean = false;
  activation: boolean = false;
  registration_at_order: any;
  portalTermsAndConditions: any;
  testmode: boolean = false;
  forceShowLogin: boolean = false;
  minMaxData: any;
  hotlineStatus: any;
  allowRegistration: boolean = true;
  isLoading: boolean;
  bigpcUrl: any;
  consentNewsletter: any;
  emailMarketing: boolean;
  localColors: any
  loginPage: boolean = false;
  newsLetterCancellation: boolean = false;
  loginFromNewsletter: boolean = false;
  productName: any;
  sessionId: any;
  sessionData: SessionDataNew;
  localoptions;
  productDetails = {
    name: " ",
    _id: " "
  };

  productInfoForWebPC: any;
  datenschutzPdf: any;
  AgbPdf: any;
  thridStagePdfs: any;
  ftpassword: boolean = false;
  tokenForVerification: string;

  affliateDetails: {
    amount?: any;
    zip?: any;
    productId?: any;
    unloadingPoints?: any;
    product?: any;
    productName?: any,
    affiliateId?: any;
    callBackUrl?: any;
    consumerId?: any;
  };

  companyInformation: any;
  bigpclink: any;
  loginColors: any;
  customersLiveTracking: boolean = false;
  domainUrls: { priceCalcUrl: any; domainName: any; };
  previewObj: {
    primaryAdress: any;
    loginUser: boolean;
    quantity: any;
    selectedProduct: {
      _id: string;
      tag: any;
      product: string;
    };
    preview: any;
  };
  isPreview: boolean = false;
  alarmSettings: any;

  constructor(
    private el: ElementRef,
    private windowRef: WindowRefService,
    private bigPcService: BigpcService,
    private langService: LanguageService,
    private commonService: CommonService,
    private authService: PortalAuthService,
    private smallPCService: SmallPcService,
    public deviceDetetect: DeviceDetectionService,
    public dialog: MatDialog,
    public orderService: OrderService
  ) {

    localStorage.setItem('forceLogin', "false");
    this._window = this.windowRef.nativeWindow;
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['web-pc'] || {};

    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['web-pc'] || {};
    });

    this.url = this._window.location.href;
    var params = new URLSearchParams(this.url);

    // let getData = sessionStorage.getItem('aupris_ipaddress');
    // getData = JSON.parse(getData)

    // get ip address here
    this.smallPCService.getIP().subscribe((data) => {
      if (data && Object.keys(data).length) {
        this.smallPCService.updateIpAdress({ Iplog: data }).subscribe((data) => {
        });
      };
    });

    // get session ID from URL
    this.sessionId = decodeURIComponent(params.get("sid"));

    if (JSON.parse(params.get("forPreview"))) {
      localStorage.setItem('forPreview', 'yes')
    } else {
      localStorage.removeItem('forPreview')
    };

    this.isPreview = JSON.parse(params.get("preview"));

    if (this.isPreview) {
      localStorage.clear()
      let plzData = JSON.parse(params.get("plzData"))
      this.previewObj = {
        primaryAdress: plzData,
        loginUser: true,
        quantity: JSON.parse(params.get("quantity")),
        selectedProduct: {
          _id: params.get("dynamicProductId"),
          tag: JSON.parse(params.get("tag")),
          product: params.get("product")
        },
        preview: this.isPreview
      };
      return;
    };

    if (localStorage.getItem('aupris_token') && params.get('stage') == 'four') {
      this.commonService.removeQueryParams('stage=four');
      if (params.get('view')) {
        this.commonService.changeRouteParams('page', 'Dashboard');
        this.bigPcService.showdashboard(true);
      };
    } else {
      if (!sessionStorage.getItem("aupris_user_type") && params.get('stage') == 'four') {
        this.commonService.removeQueryParams(`sid=${this.sessionId}`);
        this.commonService.removeQueryParams('stage=four');
        if (params.get('view')) {
          this.bigPcService.displayLoginPage(true);
          this.forceShowLogin = true;
        };
      };
    };
    if (this.url.match(/tempOrderId/gi)) {
      let prefetchValue = localStorage.getItem('prefetch');
      if (prefetchValue) {
        localStorage.removeItem('prefetch');
      }
    }
    let view = decodeURIComponent(params.get("view"));
    if (view == "pwverified") {
      let page = decodeURIComponent(params.get("page"));
      let newUrl = this.url;
      if (page) {
        newUrl = newUrl.replace(new RegExp(`&page=${page}`, 'gi'), '');
      };

      this.commonService.isSafari() && localStorage.setItem("Pwverify", 'started');

      window.history.replaceState('', document.title, newUrl);

      this.authService.pwverify({ token: decodeURIComponent(params.get('resetpassword_user')) || null }).subscribe((data: any) => {
        if (data.success) {
          this.commonService.isSafari() && localStorage.setItem("Pwverify", 'finished');
          this.ftpassword = true;
          this.forceShowLogin = false;
          this.loginPage = false;
          this.tokenForVerification = decodeURIComponent(params.get('resetpassword_user'))
          if (this.commonService.isSafari()) {
            return;
          };
        } else {
          this.ftpassword = false;
        };
      });
    };

    if (view == 'tracking') {
      this.bigpc = false;
      this.customersLiveTracking = true;
      this.forceShowLogin = false;
    };

    if (view == 'updateemail') {
      this.bigpc = false;
      this.forceShowLogin = false;
      this.loginPage = false;
      this.ftpassword = false;
      this.updateEmailVerification = true;
      return;
    };


    if (!params.get('sid') && !params.get('view') && !params.get('page')) {
      sessionStorage.clear();
    };

    let page = decodeURIComponent(params.get("page"));

    if (((page == "login" || page == "registration") && view !== "pwverified" && view !== 'tracking' && view !== "updateemail")) {
      this.bigpc = false;
      this.forceShowLogin = true;
    };

    if (!params.get("sid") && view != "pwverified" && view !== "updateemail") {
      this.checkAndNavigate();
    } else if (!params.get("sid") && !params.get("view") && !params.get("page")) {
      this.checkAndClear();
    };

    this.smallPCService.getNewSessionData(this.sessionId).then(async (data: any) => {
      let sessionExists: any = await this.smallPCService.validateSessionData(this.sessionId);
      // if (!data) {
      //   //** Occasionally, Safari encountering issues where data does not load or display correctly.*/
      //   //** To slove that issue here including the code again inside a method is to fix a specific problem that occurs in the Safari web browser. */
      //   let localSid = localStorage.getItem("sid")
      //   if (this.sessionId != localSid) {
      //     let validSessionLocal = await this.smallPCService.validateSessionData(localSid);
      //     let validSessionfromUrl = await this.smallPCService.validateSessionData(this.sessionData);
      //     if (validSessionLocal && !validSessionfromUrl) {
      //       this.sessionId = localSid;
      //       data = await this.smallPCService.getNewSessionData(this.sessionId);
      //     }
      //   }
      // }
      if (data) {
        this.sessionData = data;
        this.loginPage = false;
        if (this.sessionData && this.sessionData.localOptions) {
          this.localoptions = this.sessionData.localOptions;
          this.productDetails = Object.assign(this.productDetails, this.sessionData.product);
          let sid = localStorage.getItem("sid");
          let validSession = await this.smallPCService.validateSessionData(sid);
          let checkLoginedUser = JSON.parse(localStorage.getItem('portal_user')) || {};
          if (checkLoginedUser && !this.productDetails._id) {
            this.bigPcService.displayLoginPage(false);
            this.bigpc = true;
          } else {
            this.getProductInfo(this.productDetails);
          };
        };
      } else {
        if (params.get('view') && params.get('view') != 'pwverified' && !params.get('page') && view !== 'tracking' && view !== "updateemail") {
          this.bigPcService.displayLoginPage(true);
          this.forceShowLogin = true;
        } else if (sessionExists) {
          this.bigpc = true;
          this.sideBar = true;
        };
      };
    });

    this.smallPCService.showStageOne$.subscribe(async (data) => {
      if (data.show) {
        this.navigateToBigPc();
      };
    });

    this.bigPcService.showLoginPage$.pipe(takeUntil(this.$unsubscriber)).subscribe((show) => {
      let navigateLoginPage = this.productInfoForWebPC?.portalSettings?.position == 2 ? true : false;
      if (show.login) {
        this.loginPage = true;
        if (navigateLoginPage) {
          this.forceShowLogin = true;
          this.bigpc = false;
        };
      } else {
        this.loginPage = false;
        this.bigpc = true;
        this.forceShowLogin = false;
      };

      this.loginFromNewsletter = false;
      sessionStorage.setItem('forceLogin', JSON.stringify(show))
    });

    this.bigPcService.redirectLoginPage$.pipe(takeUntil(this.$unsubscriber)).subscribe((data) => {
      if (data.login) {
        this.forceShowLogin = true;
        this.loginPage = true;
        this.bigpc = false;
      } else {
        this.loginPage = false;
        this.bigpc = true;
        this.forceShowLogin = false;
      };
      sessionStorage.setItem('forceLogin', JSON.stringify(data));
    });

    window.onhashchange = (data) => {
      var params = new URLSearchParams(data.newURL)
      let view = decodeURIComponent(params.get("view"));
      if (view != "null") {
        window.location.reload();
      };
    };
  };

  async checkAndClear() {

    let affiliateDetailsExists = await this.orderService.checkForAffliateQueryParams();
    if (affiliateDetailsExists.status) {
      this.affiliateDetailsMethod();
    } else {
      sessionStorage.clear();
    }
  }


  async ngOnInit() {
    this.redirectToAppPopup();
    if (this.deviceDetetect.isAndroid() && this.deviceDetetect.checkIsMobile()) {
      // alert("android");
      let url = 'https://play.google.com/store/apps/details?id=com.kiloo.subwaysurf';
      // window.location.replace(url)
    } else if (this.deviceDetetect.isIos() && this.deviceDetetect.checkIsMobile()) {
      // alert("ios");
      let url = 'https://apps.apple.com/in/app/subway-surfers/id512939461';
      // window.location.replace(url)
    } else {
      // alert("Desktop");
    }
    this.companyInfo();
    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(this.url);/*testcalc*/
    this.isGuestUser = this.authService.isGuestUser();

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    };

    this.hostname = this._window.location.hostname;
    this.url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(this.url);/*testcalc*/

    if (!environment.testSite_or_thirdPartySites) {
      this.hostname = decodeURIComponent(params.get("Domainname"));/*testcalc*/
    };

    // let view = this.commonService.getViewType();
    // console.log(view, 3877777);
    // if (view == 'updateemail') {
    //   this.bigpc = false;
    //   this.updateEmailVerification = true;
    // };
  };

  ngOnDestroy() {
    this.$unsubscriber.complete();
  };

  webPcData = [];
  clientData = {};
  //get products api

  getProductInfo(product) {
    this.smallPCService.products({ dynamicProductId: product._id, tag: 1 }).subscribe((productsData: any) => {
      if (productsData.success) {
        this.clientData = productsData.data[0];
      };
    });

    //dynmaic product info for  webpc
    let getProdcutInfo = {
      dynamicProductId: product._id,
      tag: this.sessionData['tag']
    };
    this.getDynamicProductInfo(getProdcutInfo);
  };

  //company info for  webpc
  async companyInfo() {
    var params = new URLSearchParams(this.url);
    let page = decodeURIComponent(params.get("page"));
    let sid = decodeURIComponent(params.get("sid"));
    let sessionExists = await this.smallPCService.validateSessionData(sid);
    let guestOrder = localStorage.getItem("guestOrder");
    guestOrder = JSON.parse(guestOrder);
    this.bigPcService.companyInfoForWebPc().subscribe((response: any) => {
      if (response && response.success) {

        this.portalTermsAndConditions = response?.data[0]?.portalTermsAndConditions;
        this.alarmSettings = response?.data[0]?.alaramSettings;
        let FindLoginUser = this.authService.isUserLoggedIn();
        this.loginColors = response?.data[0]?.loginColors;
        this.companyInformation = response?.data[0];
        if (environment.testSite_or_thirdPartySites) {
          this.testmode = response?.data[0]?.portalTermsAndConditions?.testmode;
        };
        this.activation = response?.data[0]?.portalTermsAndConditions?.activation;
        this.bigpclink = response?.data[0]?.pcLink;
        if (environment.testSite_or_thirdPartySites && this.testmode && !sessionExists && guestOrder) {
          localStorage.removeItem('guestOrder');
          let url = `https://${this.bigpclink}`;
          window.location.replace(url);
        };

        this.domainUrls = {
          priceCalcUrl: this.bigpclink,
          domainName: response?.data[0]?.domainName,
        };
        let exists = JSON.parse(localStorage.getItem('domainUrls'));
        if (exists) {
          localStorage.removeItem("domainUrls");
        };
        let setItem = localStorage.setItem('domainUrls', JSON.stringify(this.domainUrls));
        if (this.isPreview) this.bigPcService.createNewSession(this.previewObj);
        environment.testSite_or_thirdPartySites && this.showLogInPage()
        if (!FindLoginUser || page == 'login') {
          let colors = this.loginColors;
          this.bigPcService.pcColours.next(colors)
          this.setColors({ colors });
        };
      } else {
        console.log("something went wrong with company data")
      }
    });
  };

  getDynamicProductInfo(getProdcutInfo) {
    this.bigPcService.dynamicProductInfoForWebPc(getProdcutInfo).subscribe((_data: any) => {
      // console.log(_data['success'])
      if (_data && _data.hasOwnProperty('success') && _data['success']) {
        this.webPcData = _data.data;
        this.localColors = _data.data.colors;
        if (this.webPcData) {
          this.thridStagePdfs = this.webPcData['pdfs'] || [];
          this.productInfoForWebPC = this.webPcData;
          this.minMaxData = this.webPcData;
          this.setColors(this.webPcData);
          this.webPcData = Object.assign(this.webPcData, { pcLink: this.sessionData.priceCalcUrl });
          this.activation = _data.data['portalSettings']['status'];
          localStorage.setItem('portalActive', _data.data['portalSettings']['status']);
          this.registration_at_order = this.webPcData['portalSettings']['position'];
          this.isPreview = localStorage.getItem('forPreview') == 'yes' ? true : false;
          if (!this.activation) {
            //this is used if portal contract expired removing loggedin user data and making him guest user
            localStorage.removeItem('portal_user');
            this.isGuestUser = true;
          }
          if (this.isPreview) {
            this.registration_at_order = 2;
            this.isGuestUser = true;
          }
          this.testmode = this.webPcData['portalSettings']['testMode'];
          let page = new URLSearchParams(this.url).get('page');
          // this if condition works only when in live and their portal have test mode is on .
          //  we're navigating user directly to the fist stage after that 2nd stage no login form appears here.
          // please don't change here , if you want to change please remember all these conditions & work accordingly
          if (environment.testSite_or_thirdPartySites && this.testmode) {
            this.loginPage = false;
            // console.log("show bigpc 11111")
            this.showBigPcPage(this.webPcData);
            this.bigPcService.displayLoginPage(false);
            this.updateUrlParams();
            this.bigpc = true;
            return;
          } else {
            this.testmode = false;
          };

          if ((this.webPcData['portalSettings']['position'] == 2 && !this.forceShowLogin) || (this.isGuestUser && !(page == 'login' || page == 'registration'))) {
            this.loginPage = false;
            // console.log("show bigpc 22222");
            this.showBigPcPage(this.webPcData);
            this.bigPcService.displayLoginPage(false);
            this.bigpc = true;
          } else {
            if (this.activation) {
              // console.log("show bigpc 33333");
              this.showBigPcPage(this.webPcData);
              if (this.authService.getPortalUserInfo()) {
                this.loginPage = false;
                this.bigpc = true;
              } else {
                this.loginPage = true;
                this.bigpc = false;
              };
            } else {
              this.loginPage = false;
              this.bigpc = true;
            };
          };
        };
      } else {
        console.log("something went wrong")
      }
    });
  };

  userLoggedIn(event: { userLogin: boolean, guestLogin: boolean }) {
    this.isLoggedIn = event.userLogin;
    this.isGuestUser = event.guestLogin;
  };

  logout() {
    this.bigpc = false;
    this.url = this._window.location.href;
    let url: string = this.url;
    window.history.pushState('', document.title, url);
    if (url.includes('preiswecker')) {
      url = url.replace(RegExp(/&page=preiswecker/gi), '');
    };

    let paramMap = new URLSearchParams(url);
    let page = paramMap.get('page');
    let stage = paramMap.get('stage');
    let sid = paramMap.get('sid');
    let view = paramMap.get('view');

    if (page) {
      url = url.replace(new RegExp(`&page=${page}`, 'gi'), '');
    };

    if (view) {
      url = url.replace(new RegExp(`&view=${view}`, 'gi'), '');
    }

    if (stage) {
      url = url.replace(new RegExp(`&stage=${stage}`, 'gi'), '');
    };

    if (sid) {
      // alert("logout function sid remove")
      localStorage.removeItem(sid);
      localStorage.removeItem('sid');
      url = url.replace(new RegExp(`&sid=${sid}`, 'gi'), '');
    };

    let subPageName = decodeURIComponent(paramMap.get("subPage"));
    if (subPageName) {
      this.removeKeyInUrl(`&subPage=${subPageName}`);
    };

    let _id = decodeURIComponent(paramMap.get("_id"));
    if (_id) {
      this.removeKeyInUrl(`&_id=${_id}`);
    };

    let addressType = decodeURIComponent(paramMap.get("addressType"));
    if (addressType) {
      this.removeKeyInUrl(`&addressType=${addressType}`);
    };

    let addressIndex = decodeURIComponent(paramMap.get("addressIndex"));
    if (addressIndex) {
      this.removeKeyInUrl(`&addressIndex=${addressIndex}`);
    };

    let orderIndex = decodeURIComponent(paramMap.get("orderIndex"));
    if (orderIndex) {
      this.removeKeyInUrl(`&orderIndex=${orderIndex}`);
    };

    let priceAlarmStage = decodeURIComponent(paramMap.get("priceAlarmStage"));
    if (priceAlarmStage) {
      this.removeKeyInUrl(`&priceAlarmStage=${priceAlarmStage}`);
    };

    let newUrl = url;
    newUrl = `${newUrl}&view=bigpc&page=login`;
    window.history.replaceState('', document.title, newUrl);
    this.removeRatePayUrlParams();
    this.authService.logoutUser();
    this.bigPcService.displayLoginPage(true);
    this.forceShowLogin = true;
  };

  removeKeyInUrl(subPageName) {
    this.url = window.location.href; /*testcalc*/
    let url: string = this.url;
    let newUrl = url;
    if (newUrl.includes(subPageName)) {
      newUrl = newUrl.replace(subPageName, "");
    };
  };

  showBigPcPage(data) {
    this.bigpcUrl = data['pcLink'];
    if (environment.testSite_or_thirdPartySites) {
      this.url = this._window.location.href;
      let url = this.url;
      let splitString = '';
      if (url.includes('http://')) {
        splitString = 'http://'
      } else {
        splitString = 'https://'
      };
      let urlArray = url.split(splitString);
      url = urlArray[1].split('/').join('');
      url = url.toLowerCase();
      let bigpcUrl = this.bigpcUrl.split('/').join('');
      bigpcUrl = bigpcUrl.toLowerCase();
      if (url.includes(bigpcUrl)) {
        this.bigpc = true;
        this.sideBar = true;
      };
    } else {
      if (this.commonService.getViewType() == 'bigpc') {
        this.bigpc = true;
        this.sideBar = true;
      } else {
        this.bigpc = false;
        this.sideBar = false;
      };
    };

    let view = this.commonService.getViewType();
    if (view == 'verification') {
      this.bigpc = false;
      this.verification = true;
    };

    if (view == 'data_security') {
      this.datasecurity_verification = true;
      this.bigpc = false;
    };

    if (view == 'pwverified') {
      this.resetpassword = true;
      this.bigpc = false;
    };

    if (view == 'verified') {
      this.userVerification = true;
      this.bigpc = false;
    };

    if (view == 'updateemail') {
      this.updateEmailVerification = true;
      this.bigpc = false;
    };

    if (view == 'dataSecurityForm') {
      this.dataSecurity_View = true;
      this.bigpc = false;
    };

    if (view == 'emailMarketing') {
      this.emailMarketing = true;
      this.bigpc = false;
    };
  };

  toUseraccount() {
    this.newsLetterCancellation = true;
    this.url = this._window.location.href;
    window.history.pushState('', document.title, this.url);
    this.commonService.changeRouteParams('view', 'newsLetterCancellation');
    this.emailMarketing = false;
  };

  gotoUser() {
    this.newsLetterCancellation = false;
    this.loginFromNewsletter = true;
    this.url = this._window.location.href;
    window.history.pushState('', document.title, this.url);
    this.commonService.getCustomerId();
    this.commonService.changeRouteParams('view', 'bigpc');
    this.commonService.changeRouteParams('id', '');
  };

  emitSelectedProduct(e) {
    this.productName = e;
  };

  setColors(data) {
    //setting small pc link
    this.el.nativeElement.style.setProperty('--primaryColour', data.colors?.primaryColour);
    this.el.nativeElement.style.setProperty('--primaryTextColour', data.colors?.primaryTextColour);
    this.el.nativeElement.style.setProperty('--secondaryColour', data.colors?.secondaryColour);
    this.el.nativeElement.style.setProperty('--secondaryTextColour', data.colors?.secondaryTextColour);
    this.el.nativeElement.style.setProperty('--highlightColour', data.colors?.highlightColour);
    this.el.nativeElement.style.setProperty('--co2Colour', data.colors?.co2Colour);
    this.el.nativeElement.style.setProperty('--colorShade1', this.convertToColorsShade1(data.colors?.primaryColour));
    this.el.nativeElement.style.setProperty('--colorShade2', this.convertToColorsShade2(data.colors?.primaryColour));

    cssVars({
      variables: {
        '--primaryColour': data.colors?.primaryColour,
        '--primaryTextColour': data.colors?.primaryTextColour,
        '--secondaryColour': data.colors?.secondaryColour,
        '--secondaryTextColour': data.colors?.secondaryTextColour
      }
    });
    this.colors = data?.colors;
  };

  convertToColorsShade1(color) {
    let rgb = this.hextoRGB(color);
    if (rgb) {

      let r = (255 - Number(rgb.r)) / 3;
      let g = (255 - Number(rgb.g)) / 3;
      let b = (255 - Number(rgb.b)) / 3;

      let shade1 = 'rgb(' + (Number(rgb.r) + Number(r)) + ',' + (Number(rgb.g) + Number(g)) + ',' + (Number(rgb.b) + Number(b)) + ')';
      return shade1
    };
  };

  convertToColorsShade2(color) {
    let rgb = this.hextoRGB(color);
    if (rgb) {

      let r = (255 - Number(rgb.r)) / 3;
      let g = (255 - Number(rgb.g)) / 3;
      let b = (255 - Number(rgb.b)) / 3;

      let shade2 = 'rgb(' + (Number(rgb.r) + Number(r) + Number(r)) + ',' + (Number(rgb.g) + Number(g) + Number(g)) + ',' + (Number(rgb.b) + Number(b) + Number(b)) + ')';
      return shade2;
    }
  };

  hextoRGB(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : this.rgbToArray(hex);
  };

  rgbToArray(hex) {
    let result = hex.split("(")[1].split(")")[0];
    result = result.split(',');
    return {
      r: parseInt(result[0]),
      g: parseInt(result[1]),
      b: parseInt(result[2])
    };
  };

  //** This Method is using For checking Sid if it's missed in the url , but local Storage was sucessfully created */.
  //** Then this method automatically updates url with the created sessionId */.
  async checkAndNavigate() {
    let affiliateDetailsExists = await this.orderService.checkForAffliateQueryParams();
    if (affiliateDetailsExists.status) {
      this.affiliateDetailsMethod();
    } else {
      if (this.ftpassword) {
        return;
      };
      let sid = localStorage.getItem("sid");
      let validSession = await this.smallPCService.validateSessionData(sid);
      let checkLoginedUser = JSON.parse(localStorage.getItem('portal_user')) || {};
      let navigationUrl;
      let params = new URLSearchParams(this.url);

      let showlogIn = (params.get("view") == "bigpc" || params.get("page") == "login") && (sid == null || sid == "null") && !validSession ? true : false;
      if (showlogIn && (checkLoginedUser && Object.keys(checkLoginedUser).length == 0)) {
        this.logout();
        return;
      }
      if ((params.get("page") == "login") || (params.get("view") == "pwverified")) {
        return;
      }
      let stage = params.get("stage")
      let stages = [
        "one",
        "two",
        "three",
        "four"
      ]
      if ((sid && params.get("view") == "bigpc") || (sid && stages.includes(stage))) {
        if (validSession) {
          let data = localStorage.getItem(sid);
          this.sessionData = JSON.parse(data);
          if (environment.testSite_or_thirdPartySites && this.sessionData?.priceCalcUrl) {
            navigationUrl = checkLoginedUser && Object.keys(checkLoginedUser).length ? `https://${this.sessionData?.priceCalcUrl}#&view=bigpc&sid=${sid}&page=dynamicPcs&stage=one` : `https://${this.sessionData.priceCalcUrl}#&view=bigpc&sid=${sid}&stage=one`;
            window.location.replace(navigationUrl);
          } else {
            navigationUrl = checkLoginedUser && Object.keys(checkLoginedUser).length ? `${environment.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${sid}&page=dynamicPcs&stage=one` : `${environment.pricecalc}#&Domainname=${this.hostname}&view=bigpc&sid=${sid}&stage=one`;
            window.location.replace(navigationUrl);
          };
        } else {
          // alert("not a valid session");
          console.log("not a valid session");
        }
      };
    }
  };

  async showLogInPage() {
    let params = new URLSearchParams(this.url);
    let bigpc = params.get("view") == "bigpc" ? true : false;
    let view = params.get("view");
    let url = window.location.href.toLowerCase();
    let sid = params.get("sid") || localStorage.getItem("sid");
    let page = params.get("page");
    let stages = ["one", "two", "three", "four"];
    let stageVal = params.get('stage')
    if (page == 'login') {
      return;
    };
    let falseLoginpage = true;
    if (view == 'pwverified' || view == 'updateemail') {
      falseLoginpage = false;
    }
    // console.log("customersLiveTracking" , this.customersLiveTracking);
    let validSession = await this.smallPCService.validateSessionData(sid);
    if (!validSession && params.get("sid") && stages.includes(stageVal)) {
      bigpc = false;
    }

    if (environment.testSite_or_thirdPartySites && this.activation && !bigpc && !this.customersLiveTracking && !this.testmode && falseLoginpage) {
      if (sid == null && url.includes(this.bigpclink.toLowerCase())) {
        let pcUrl = `https://${this.bigpclink}&view=bigpc&page=login`
        window.history.pushState("", document.title, pcUrl);
        this.forceShowLogin = true;
        let colors = this.loginColors;
        this.setColors({ colors })
      } else {
        if ((!validSession && !this.testmode && url.includes(this.bigpclink.toLowerCase())) || (url == "https://" + this.bigpclink.toLowerCase()) || (url == "https://" + this.bigpclink.toLowerCase() + '/')) {
          let pcUrl = `https://${this.bigpclink}&view=bigpc&page=login`
          window.history.pushState("", document.title, pcUrl);
          this.forceShowLogin = true;
          let colors = this.loginColors;
          this.setColors({ colors })
        }
      }
    } else if (!validSession && !this.testmode && (url.includes(this.bigpclink.toLowerCase()) && !this.customersLiveTracking) || (url == "https://" + this.bigpclink.toLowerCase() && !this.customersLiveTracking) || (url == "https://" + this.bigpclink.toLowerCase() + '/' && !this.customersLiveTracking) && environment.testSite_or_thirdPartySites && !this.activation && !bigpc && !this.customersLiveTracking) {
      //  console.log( "showPcCond", !validSession && !this.testmode && (url.includes(this.bigpclink.toLowerCase()) && !this.customersLiveTracking) || (url == "https://" + this.bigpclink.toLowerCase() && !this.customersLiveTracking) || (url == "https://" + this.bigpclink.toLowerCase() + '/' && !this.customersLiveTracking) && environment.testSite_or_thirdPartySites && !this.activation && !bigpc && !this.customersLiveTracking);
      if (falseLoginpage) {
        this.authService.storeGuestSession();
        let obj = { primaryAdress: {}, loginUser: false, selectedProduct: {} };
        this.bigPcService.createNewSession(obj);
      };
    } else if (!validSession && this.testmode && (url.includes(this.bigpclink.toLowerCase()) && !this.customersLiveTracking) || (url == "https://" + this.bigpclink.toLowerCase() && !this.customersLiveTracking) || (url == "https://" + this.bigpclink.toLowerCase() + '/' && !this.customersLiveTracking) && environment.testSite_or_thirdPartySites && !this.activation && !bigpc && !this.customersLiveTracking) {
      //iif user in test mode and session is not valid then we're creating new session for the user
      if (falseLoginpage) {
        this.authService.storeGuestSession();
        let obj = { primaryAdress: {}, loginUser: false, selectedProduct: {} };
        this.bigPcService.createNewSession(obj);
      };
    };
  };

  navigateToLogin(event) {
    if (event) {
      return;
    };

    this.ftpassword = event;
    this.forceShowLogin = true;
    this.url = this._window.location.href;
    let url: string = this.url;
    let paramMap = new URLSearchParams(url);
    let view = paramMap.get("view");
    let resetpassword_user = paramMap.get("resetpassword_user");
    if (view) {
      url = url.replace(new RegExp(`&view=${view}`, "gi"), "");
    };

    if (resetpassword_user) {
      url = url.replace(new RegExp(`&resetpassword_user=${resetpassword_user}`, "gi"), "");
      url = `${url}&page=login`;
      window.history.pushState("", document.title, url);
    }
  };

  // showLoginAfterEmailVerification(){
  //   this.forceShowLogin = true;
  //   this.updateEmailVerification = false ;
  //    let url = environment.testSite_or_thirdPartySites ? `https://${this.bigpclink}#&view=bigpc&page=login` : `${environment.pricecalc}#&Domainname=${this.domainUrls.domainName}&view=bigpc&page=login`
  //     window.history.pushState('', document.title, url);
  //     window.location.reload();
  // }

  navigateToBigPc() {
    this.bigpc = true;
    this.ftpassword = false;
    this.smallPCService.getNewSessionData(this.sessionId).then(async (data: any) => {
      if (data) {
        this.sessionData = data;
        let reqObj = {
          dynamicProductId: this.sessionData?.product?._id,
          tag: this.sessionData["tag"],
        };
        this.getDynamicProductInfo(reqObj);
      } else {
        this.logout();
      };
    });
  };

  updateUrlParams() {
    let url = this.url;
    let page = new URLSearchParams(this.url).get('page');
    if (page == 'login') {
      url = url.replace(new RegExp(`&page=${page}`, 'gi'), '');
      window.history.pushState('', document.title, url);
    }
  };

  showLoginAfterEmailVerification() {
    this.forceShowLogin = true;
    this.updateEmailVerification = false;
    let url = environment.testSite_or_thirdPartySites ? `https://${this.bigpclink}#&view=bigpc&page=login` : `${environment.pricecalc}#&Domainname=${this.domainUrls.domainName}&view=bigpc&page=login`
    window.history.pushState('', document.title, url);
    window.location.reload();
  };

  async affiliateDetailsMethod() {
    this.affliateDetails = this.orderService.affliateDetails;
    let resp = await this.orderService.affiliateSessionCreation(this.hostname).catch(() => false);
    if (resp && resp.sessionId) {
      this.sessionId = resp.sessionId;
      await this.smallPCService.getNewSessionData(this.sessionId).then(async (data: any) => {
        if (!data) {
          this.bigpc = false;
          return;
        } else {
          // if no session data stored in localstorage showing small pc
          if (this.sessionData && !Object.keys(this.sessionData).length) {
            this.bigpc = false;
            return;
          };

          this.sessionData = data;
          if (!this.productInfoForWebPC) {
            let getProdcutInfo = {
              dynamicProductId: this.sessionData.product._id,
              colorsTag: this.sessionData['tag'],
              all: true
            };

            await this.bigPcService.dynamicProductInfoForWebPc(getProdcutInfo).subscribe((_data: any) => {
              if (_data['success']) {
                this.productInfoForWebPC = _data.data;
                // console.log(this.productInfoForWebPC, 83222)
                this.thridStagePdfs = this.productInfoForWebPC['pdfs'] || [];
              };
            });
          };
          // this.bigpc = true;
          this.commonService.changeRouteParams('view', 'bigpc');
          await this.smallPCService.validateSessionData(this.sessionId);

          if (environment.testSite_or_thirdPartySites && this.testmode) {
            this.loginPage = false;
            this.bigPcService.displayLoginPage(false);
            this.bigpc = true;
            return;
          } else {
            this.testmode = false;
          };
          window.location.reload();
        };
      });
    };
  };

  redirectToAppPopup() {
    this.bigPcService.getNativeAppId({ unterId: '' }).subscribe((data: any) => {
      // console.log(data, 9999);
      if ((data.data?.customerAppToggle && checkStore(data.data.androidSettings.websiteURL)) || (data.data?.customerAppToggle && checkStore(data.data.iosSettings.websiteURL))) {
        let appPopup = this.dialog.open(RatePaymentSelectionPopupComponent, {
          data: {
            type: 'redirectToApp'
          }
        });
        appPopup.afterClosed().subscribe(async (data: any) => {
          if (data) {
          }
        })
      }
    });
  }

  removeRatePayUrlParams() {
    this.url = window.location.href;
    const paramMap = new URLSearchParams(this.url);
    const stage = paramMap.get('stage')

    const paramsToReplace = {
      'tempOrderId': paramMap.get('tempOrderId'),
      'callback': paramMap.get('callback'),
      'tempOrderStatus': paramMap.get('tempOrderStatus'),
      'socketId': paramMap.get('socketId')
    };

    Object.entries(paramsToReplace).forEach(([param, value]) => {
      if (value) {
        this.url = this.url.replace(new RegExp(`&${param}=${value}`, 'gi'), '');
      }
    });
    window.history.replaceState('', document.title, this.url);
  }
}


const applePattern = /^https:\/\/apps\.apple\.com\//;
const googlePattern = /^https:\/\/play\.google\.com\//;

function checkStore(url) {
  return applePattern.test(url) || googlePattern.test(url);
}