<!-- Theme 1 Start -->
<div class="aucol-md-6 aucol-lg-4 aucol-sm-12 aucol-xs-12 calcTheme inputsize" style="padding-left: 0px;" id="formhoriz"
  *ngIf="productInfoForWebPC?.themeModel == 1 && languageData">
  <form [formGroup]="pcOptionForm" class="aurow form-horizontal form-calc-options" id="tabview">
    <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0">
      <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
        {{languageData['Bestelloptionen'].Var_Produkt||'Produkt'}}
      </div>
      <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0" *ngIf="products.length">
        <select name="product" class="auform-control infield  " formControlName="product"
          (change)="updateoptions($event,'PRODUCT')">
          <option *ngFor="let prod of products" [ngValue]="prod._id">
            {{prod.appName}}
          </option>
        </select>
      </div>
    </div>

    <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0">
      <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
        {{languageData['Bestelloptionen'].Var_Lieferort||'Lieferort'}}
      </div>
      <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0"
        [ngStyle]="{'height': tabErrorMsg ? 'unset' : 'unset'}">
        <span class="field">
          <span style="width:100%">
            <input type="text" formControlName="postalCode" class="auform-control infield" name="lieferort"
              [matAutocomplete]="autoPlz" (ngModelChange)="getPostalCode($event)" (keypress)="validate($event)"
              (keydown)="onKeyy($event)">
            <mat-autocomplete #autoPlz="matAutocomplete">
              <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="matItemSelect(option)"
                [value]="option.country_code +' ' + option.place_name +' '+option.suburb" class="dropdownBottom">
                {{option.place_name}} {{option.country_code}} {{option.suburb}}
              </mat-option>
            </mat-autocomplete>

            <span class="alert-danger infield" *ngIf="postalCodeErrorMsg">
              Nur Auswahl aus dem Dropdown Menü zulässig!
            </span>

            <span class="alert-danger infield" *ngIf="tabErrorMsg">
              Bitte prüfen Sie Ihre Eingabe und wählen Sie einen Lieferort aus dem Auswahlfeld aus
            </span>
          </span>
        </span>
      </div>
    </div>


    <!-- Hellooo----{{dynamicPcActive}} {{productInfoForWebPC.activatePc || productInfoForWebPC.webCalculator}} -->
    <!--  -->
    <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0">
      <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
        {{languageData['pc-first'].Var_Liter||'Menge'}}
      </div>
      <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
        <input autocomplete='foo' name="quantity" id="quantity" formControlName="quantity"
          class="auform-control infield" OnlyNumberwithoutdot required placeholder="Menge" autocomplete="off"
          (input)="updateoptionsQuantity($event,'QUANTITY')">
        <span class="alert-danger infield" *ngIf="isLitterValidation()" style="width: 100%;">Bestellmenge
          {{productInfoForWebPC.minAmount | replaced}} bis {{ productInfoForWebPC.maxAmount | replaced}}
          {{productInfoForWebPC.unitsName}}!
        </span>
        <span class="alert-danger infield" *ngIf="validateMeng()" style="width: 100%;">
          Die
          Mindestbestellmenge für
          {{pcOptionForm.controls['stations'].value}} liegt bei
          {{productInfoForWebPC.minAmount * pcOptionForm.controls['stations'].value | replaced}}. Bitte passen Sie
          Ihre Werte in der Auswahl an!
        </span>
      </div>
    </div>

    <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0"
      *ngIf="productInfoForWebPC.voucher">
      <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
        {{languageData['Bestelloptionen'].Var_Gutschein||'Gutschein'}}</div>
      <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
        <div class="input-container gutsc " style="position:relative;">
          <input autocomplete='foo' name="voucher" id="voucher" formControlName="voucher" class="auform-control infield"
            minlength="4" (blur)="voucherfun($event)" required
            placeholder="{{languageData['Bestelloptionen'].Var_Gutschein||'Gutschein'}}" autocomplete="off">
          <span style="position: absolute; right: 1%;cursor: pointer; padding: 5px 0px;top:6px"
            *ngIf="pcOptionForm.get('voucher').valid">
            <span [innerHTML]="reload" *ngIf="reload"></span>
            <img *ngIf="!reload" [src]="'assets/svgs/reload.svg' | assetsUrl" (click)="voucherValidity()">
          </span>
        </div>
        <div class="mfont12" class="alert-danger" style="width: 100%;"
          *ngIf="pcOptionForm.get('voucher').value!='' && !voucher.valid && touchedVoucherInput">
          {{languageData['Bestellubersicht'].Var_Ungultiger_Gutschein || 'Ungültiger Gutschein'}}
        </div>
      </div>
    </div>

    <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0">
      <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
        {{languageData['Bestelloptionen'].Var_Haushalte ||'Haushalte'}}
      </div>
      <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
        <select name="product" class="auform-control infield" (change)="updateoptions($event,'STATIONS');validateMeng()"
          formControlName="stations">
          <option *ngFor="let delivery of deliveryStations" [ngValue]="delivery?.value">
            {{delivery.value}}
            <span *ngIf="delivery?.value == 1">
              {{ languageData['Bestelloptionen'].Var_Haushalt || 'Haushalt' }}
            </span>
            <span *ngIf="delivery?.value >= 2">
              {{ languageData['Bestelloptionen'].Var_Haushalte || 'Haushalte' }}
            </span>
          </option>
        </select>
      </div>
    </div>

    <span formArrayName="dynamicOptionsSets">
      <div *ngFor="let item of getDynamicControls().controls; let i = index;">

        <div *ngIf="item.value.dynamicOptions.length > 0"
          class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0" [formGroupName]="i">

          <div class="control_label aucol-xl-8 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
            {{item.value.optionSetName}}
          </div>

          <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
            <select name="dynamicOptions" class="auform-control infield" formControlName="optionId"
              (change)="dynamicOptionUpdate($event.target.value,'DYNAMICOPTIONS', item.value)">
              <option *ngFor="let option of item.value.dynamicOptions" [value]="option.refCentralizedOptionId">
                {{option?.centralizedOptions?.name}}
              </option>
            </select>
          </div>
        </div>

        <div *ngIf="item.value.dynamicOptions.length == 0"
          class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0" [formGroupName]="i">

          <div class="control_label aucol-xl-8 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
            {{item.value.optionSetName}}
          </div>

          <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
            <select name="dynamicOptions" class="auform-control infield" formControlName="optionId">
              <option>
                <!-- {{option?.centralizedOptions?.name}} -->
              </option>
            </select>
            <div class="alert-danger">
              {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
            </div>
          </div>
        </div>


        <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0"
          *ngIf="item.value.optionType == 7 && deliveryDate?.rangeAvailable && deliveryDate?.dayType == 'multiple'">
          <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
            {{languageData['Bestelloptionen'].Var_Date || 'Datum'}}:
          </div>
          <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0"
            *ngIf="!deliveryDate?.wunstermin">
            <input type="text" class="auform-control infield" [disabled]="true" [value]="deliveryDate?.range">
          </div>
          <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0"
            *ngIf="deliveryDate?.wunstermin" (click)="dynamicOptionUpdate('WUNSTERMIN','DYNAMICOPTIONS', item.value)">
            <input type="text" class="auform-control" style="cursor: pointer;background: #fff;" [disabled]="false"
              [value]="deliveryDate?.range">
          </div>
        </div>


        <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0"
          *ngIf="item.value.optionType == 7 && deliveryDate?.dayType == 'single'">
          <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 infieldd">
            {{languageData['Bestelloptionen'].Var_Date || 'Datum'}}
          </div>
          <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
            <input type="text" class="auform-control infield" style="cursor: pointer;" [disabled]="true" id="dateinput"
              [value]="deliveryDate?.toDate">
          </div>
        </div>

      </div>
    </span>

    <div class="auform-group inputBox aucol-sm-6 aucol-md-12 aucol-lg-12 aucol-xs-12 p-0"
      *ngIf="localSessionUpdatedData && localSessionUpdatedData?.ratePaymentSettings?.status ">
      <div class="control_label aucol-xl-4 aucol-lg-5 aucol-md-6 aucol-sm-5 aucol-xs-3 smallscreens pl-0 field">
        Raten :
      </div>
      <div class="aucol-xl-8 aucol-lg-7 aucol-md-6 aucol-sm-7 aucol-xs-9 smallscreenwidth p-0">
        <select name="Ratenzahlung" class="auform-control infield" formControlName="raten"
          (change)="dynamicOptionUpdate($event,'RATEN','')" [disabled]="showFullScreenLoading">
          <!-- <option [value]="'5ade00a02cb3a95cca554882'"></option> -->
          <option *ngFor="let prod of localSessionUpdatedData?.ratePaymentSettings?.zahlungspans"
            [ngValue]="prod.zahlungRate">
            {{ prod.zahlungRate }} {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}</option>
        </select>
      </div>
    </div>
  </form>
</div>
<!-- Theme 1 end -->


<!-- Theme 2 start -->
<div class="aucol-md-4 aucol-lg-4 aucol-sm-12 aucol-xs-12 aupris_main section-container calcTheme inputsize"
  *ngIf="productInfoForWebPC?.themeModel == 2 && languageData">
  <div class="divSection divnew" style="margin-left:16px; margin-right: 8px">
    <div class="aurow boxShadow  leftside inputalign" style="margin-top:1px">
      <form [formGroup]="pcOptionForm" class="aurow form-horizontal form-calc-options">
        <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12">
          <div *ngIf="products.length">
            <label class="posAbs aupLabel">{{languageData['Bestelloptionen'].Var_Produkt||'Produkt'}}</label>
            <select name="product" class="auform-control forLabel inptCls" formControlName="product"
              (change)="updateoptions($event,'PRODUCT')">
              <option *ngFor="let prod of products" [ngValue]="prod._id">
                {{prod.appName}}
              </option>
            </select>
          </div>
        </div>
        <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12">
          <div>
            <label class="posAbs aupLabel">{{languageData['Bestelloptionen'].Var_Lieferort||'Lieferort'}}</label>
            <span style="width:100%">
              <input type="text" formControlName="postalCode" class="auform-control forLabel inptCls" name="lieferort"
                [matAutocomplete]="autoPlz" (ngModelChange)="getPostalCode($event)" (keypress)="validate($event)"
                (keydown)="onKeyy($event)">
              <mat-autocomplete #autoPlz="matAutocomplete">
                <mat-option *ngFor="let option of plzOptions" (onSelectionChange)="matItemSelect(option)"
                  [value]="option.country_code +' ' + option.place_name +' '+option.suburb" class="dropdownBottom">
                  {{option.place_name}} {{option.country_code}} {{option.suburb}}
                </mat-option>
              </mat-autocomplete>
              <span class="alert-danger" *ngIf="postalCodeErrorMsg">
                Nur Auswahl aus dem Dropdown Menü zulässig!
              </span>
              <span class="alert-danger" *ngIf="tabErrorMsg">
                <!-- Bitte prüfen Sie Ihre Eingabe und wählen Sie einen Lieferort aus dem Auswahlfeld aus -->
                {{languageData['Small-pc'].Var_empty_deliveryarea_error ||'Bitte prüfen Sie Ihre Eingabe und wählen
                Sie einen Lieferort aus dem Auswahlfeld aus'}}
              </span>
            </span>
          </div>
        </div>
        <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12">
          <div>
            <label class="posAbs aupLabel" for="Liter">{{languageData['pc-first'].Var_Liter||'Menge'}}</label>
            <input autocomplete='foo' name="quantity" id="quantity" formControlName="quantity"
              class="auform-control forLabel inptCls" OnlyNumberwithoutdot required placeholder="Menge"
              autocomplete="off" (input)="updateoptionsQuantity($event,'QUANTITY')">
            <span class="alert-danger" *ngIf="isLitterValidation()" style="width: 100%;">
              Bestellmenge
              {{productInfoForWebPC.minAmount | replaced}} bis {{ productInfoForWebPC.maxAmount | replaced}}
              {{productInfoForWebPC.unitsName}}!
            </span>

            <span class="alert-danger" *ngIf="validateMeng()" style="width: 100%;">
              Die
              Mindestbestellmenge für
              {{pcOptionForm.controls['stations'].value}} liegt bei
              {{productInfoForWebPC.minAmount * pcOptionForm.controls['stations'].value | replaced}}. Bitte passen Sie
              Ihre Werte in der Auswahl an!
            </span>
          </div>
        </div>
        <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12" *ngIf="productInfoForWebPC.voucher">
          <div>
            <label class="posAbs aupLabel"
              for="Liter">{{languageData['Bestelloptionen'].Var_Gutschein||'Gutschein'}}</label>
            <input autocomplete='foo' name="voucher" id="voucher" formControlName="voucher" class="auform-control"
              minlength="4" (blur)="voucherfun($event)" required
              placeholder="{{languageData['Bestelloptionen'].Var_Gutschein||'Gutschein'}}" autocomplete="off">
            <span style="position: absolute;cursor: pointer;right: 22px;padding: 5px 0px;top:6px"
              *ngIf="pcOptionForm.get('voucher').valid">
              <span [innerHTML]="reload" *ngIf="reload"></span>
              <img *ngIf="!reload" [src]="'assets/svgs/reload.svg' | assetsUrl" (click)="voucherValidity()">
            </span>
            <div class="mfont12" class="alert-danger" style="width: 100%;"
              *ngIf="pcOptionForm.get('voucher').value!='' && !voucher.valid && touchedVoucherInput">
              {{languageData['Bestellubersicht'].Var_Ungultiger_Gutschein || 'Ungültiger Gutschein'}}
            </div>
          </div>
        </div>
        <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12">

          <div>
            <label class="posAbs aupLabel">{{languageData['Bestelloptionen'].Var_Haushalte ||'Haushalte'}}</label>
            <select name="product" class="forLabel auform-control inptCls"
              (change)="updateoptions($event,'STATIONS');validateMeng()" formControlName="stations">
              <option *ngFor="let delivery of deliveryStations" [ngValue]="delivery?.value">
                {{delivery.value}}
                <span *ngIf="delivery?.value == 1">
                  {{ languageData['Bestelloptionen'].Var_Haushalt || 'Haushalt' }}
                </span>
                <span *ngIf="delivery?.value >= 2">
                  {{ languageData['Bestelloptionen'].Var_Haushalte || 'Haushalte' }}
                </span>
              </option>
            </select>
          </div>
        </div>

        <span formArrayName="dynamicOptionsSets">
          <div *ngFor="let item of getDynamicControls().controls; let i = index;">
            <div *ngIf="item.value.dynamicOptions.length > 0" class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12"
              [formGroupName]="i">
              <div>
                <label class="posAbs aupLabel">
                  {{item.value.optionSetName}}
                </label>
                <div>
                  <select name="dynamicOptions" class="auform-control" formControlName="optionId"
                    (change)="dynamicOptionUpdate($event.target.value,'DYNAMICOPTIONS', item.value)">
                    <option *ngFor="let option of item.value.dynamicOptions" [value]="option?.refCentralizedOptionId">
                      {{option.centralizedOptions?.name}}
                    </option>
                  </select>
                </div>
              </div>
            </div>


            <div *ngIf="item.value.dynamicOptions.length == 0" class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12"
              [formGroupName]="i">
              <div>
                <label class="posAbs aupLabel">
                  {{item.value.optionSetName}}
                </label>
                <div>
                  <select name="dynamicOptions" class="auform-control" formControlName="optionId">
                    <option>
                      <!-- {{option.centralizedOptions?.name}} -->
                    </option>
                  </select>
                </div>
                <div class="alert-danger">
                  {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld ausfüllen'}}!
                </div>
              </div>
            </div>

            <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12"
              *ngIf="item.value.optionType == 7 && deliveryDate?.rangeAvailable && deliveryDate?.dayType == 'multiple'">
              <div class="field" *ngIf="!deliveryDate?.wunstermin">
                <label class="posAbs aupLabel">{{languageData['Bestelloptionen'].Var_Date || 'Datum'}}</label>
                <input type="text" style="margin-bottom: 0px !important;" class="auform-control forLabel"
                  [disabled]="true" [value]="deliveryDate?.range" style="background:  #f2f2f2">
              </div>
              <div *ngIf="deliveryDate?.wunstermin"
                (click)="dynamicOptionUpdate('WUNSTERMIN','DYNAMICOPTIONS', item.value)">
                <label class="posAbs aupLabel">{{languageData['Bestelloptionen'].Var_Date || 'Datum'}}</label>
                <input type="text" style="margin-bottom: 0px !important; cursor: pointer;background:  #fff"
                  class="auform-control forLabel" [disabled]="false" [value]="deliveryDate?.range">
              </div>
            </div>


            <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12"
              *ngIf="item.value.optionType == 7 && deliveryDate?.dayType == 'single'">
              <div class="field">
                <label class="posAbs aupLabel">{{languageData['Bestelloptionen'].Var_Date || 'Datum'}}</label>
                <input type="text" style="margin-bottom: 0px !important;" class="auform-control forLabel"
                  [disabled]="true" [value]="deliveryDate?.toDate" style="background:  #f2f2f2">
              </div>

            </div>
          </div>
        </span>
        <!-- <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12">
          <div class="control_label aucol-sm-6 aucol-xs-3 smallscreens">
            Raten
          </div>
          <div class="aucol-sm-6 aucol-xs-8 smallscreenwidth">
            <select name="Ratenzahlung" class="auform-control" formControlName="raten"
            (change)="dynamicOptionUpdate($event,'RATEN','')"  [disabled]="showFullScreenLoading">
              <option [value]="'5ade00a02cb3a95cca554882'"></option>
              <option *ngFor="let prod of localSessionUpdatedData?.ratePaymentSettings?.zahlungspans" [ngValue]="prod.zahlungRate">
                {{ prod.zahlungRate }} {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}</option>
            </select>
          </div>
        </div> -->
        <div class="mb20 aucol-lg-12 aucol-md-12 aucol-sm-12"
          *ngIf="localSessionUpdatedData && localSessionUpdatedData?.ratePaymentSettings?.status ">
          <div>
            <label class="posAbs aupLabel">RATEN</label>
            <select name="Ratenzahlung" class="auform-control forLabel inptCls" formControlName="raten"
              (change)="dynamicOptionUpdate($event,'RATEN','')" [disabled]="showFullScreenLoading">
              <!--  <option [value]="'5ade00a02cb3a95cca554882'"></option> -->
              <option *ngFor="let prod of localSessionUpdatedData?.ratePaymentSettings?.zahlungspans"
                [ngValue]="prod.zahlungRate" [ngValue]="prod.zahlungRate">
                {{ prod.zahlungRate }} {{ languageData?.Bestelloptionen['Var_month'] || 'Monate'}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<p *ngIf="localSessionUpdatedData?.stations > 1"></p>

<!-- Theme 2 end -->

<div class="aupris_main aucol-xs-12 aucol-md-12 aucol-sm-12 aucol-lg-9 calcData rightSec size quality"
  style="position: relative;padding-right: 13px;">

  <div class="" *ngFor="let quality of priceCalculationData; let i = index ; let last=last"
    (click)="selectQuality($event ,i, quality,'on')"
    [ngStyle]="{'margin-bottom':(quality.qualities.co2Compensation?.compensate) ? '100px' : '60px'}">
    <div  [ngStyle]="{'pointer-events': preview? 'none': 'auto'}" (click)="$event.stopPropagation();"
      *ngIf="showPriceAlarm  && pricealarmActivation && (alarmIndex == i) && ( alarmIndex == 0 || alarmIndex == 1)"
      class="aucol-lg-12 panel panel-default aucol-md-12 pull-right aucol-sm-12 aucol-xs-12 boxShadowNormal pricealam">
      <div class="aurow hovereffect md" style="display: flex;align-items: center;">
        <div class="aucol-md-1 aucol-xs-2 aucol-sm-2 aucol-lg-1 alarmHeight" id="alarms">
          <span [innerHTML]="priceAlaram" *ngIf="priceAlaram" class="alarmM"></span>
          <img *ngIf="!priceAlaram" [src]="'assets/svgs/priceAlaram.svg' | assetsUrl">
        </div>
        <div class="aucol-md-8 aucol-xs-10 aucol-sm-10 aucol-lg-8 alarmw" (click)="showPreiswecker()" id="alarmtext">
          {{priceAlaramText}}
        </div>
        <div class="aucol-md-4 aucol-xs-12 aucol-sm-12 aucol-lg-3 mediaScreeen1 alarmHG"
          style="height:100%;padding:15px;cursor: pointer;" (click)="showPreiswecker()"
          [ngStyle]="{background: 'var(--primaryColour)'}">
          <div class="text-center" [ngStyle]="{color:'var(--primaryTextColour)'}">
            {{languageData['Hamburger']?.Var_pricealaram || 'Preiswecker'}}
          </div>
        </div>
      </div>
    </div>
    <div class="aucol-lg-12 panel panel-default aucol-md-12 pull-right aucol-sm-12 aucol-xs-12 containerm"
      *ngIf="(quality.product.label!= '')" [ngClass]="selectedIndex == i ? 'boxShadowActive' : 'boxShadowNormal'">
      <div class="panel-body-aupris" style="padding: 0px 0px 0px 0px;">
        <div class="aurow row-eq-height hovereffect selectb"
          [ngClass]="quality.qualities.co2Compensation?.compensate ? 'co2B' : 'select1'"
          style="margin-right: -15px!important; margin-left: -15px !important;">
          <div class="'aucol-md-8 aucol-xs-12 aucol-sm-12 aucol-lg-9"
            style="padding:5px;font-size:12px; padding-left: 19px;margin-top: 10px;">
            <div *ngIf="quality.qualities.favorite == true" class="textfix starM" style="display: block;">
              <span class="au-glyphicon au-glyphicon-star star">
                <span [innerHTML]="star" *ngIf="star"></span>
                <img *ngIf="!star" [src]="'assets/star.svg' | assetsUrl">
              </span>
              <span style="margin-left: 1%;position:relative">
                <small>{{languageData['Bestellubersicht'].Var_Unsere_Empfehlung || 'Unsere Empfehlung!'}}</small>
                <h4 style="margin-top:-2px;" class="qualN">
                  <b>{{quality.product.label}}</b>
                  <span class="au-glyphicon au-glyphicon-info-sign" *ngIf="quality.product.description"
                    (click)="specialtextpopup1(quality.product.description)">
                    <span [innerHTML]="infoSign" *ngIf="infoSign"></span>
                    <img *ngIf="!infoSign" [src]="'assets/infoSign.svg' | assetsUrl">
                  </span>
                  <span style="font-size: 12px; margin-left: 16px; float: right;" *ngIf="quality.voucherInfo">
                    <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                    <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                  </span>
                </h4>
              </span>
            </div>
            <div *ngIf="quality.qualities.favorite == false" class="textfix">
              <h4 class="product">
                <b>{{quality.product.label}}</b>
                <span class="au-glyphicon au-glyphicon-info-sign" *ngIf="quality.product.description"
                  (click)="specialtextpopup1(quality.product.description)">
                  <span [innerHTML]="infoSign" *ngIf="infoSign"></span>
                  <img *ngIf="!infoSign" [src]="'assets/infoSign.svg' | assetsUrl">
                </span>
                <span style="font-size: 12px; margin-left: 16px; float: right;" *ngIf="quality.voucherInfo">
                  <span [innerHTML]="voucherSVG" *ngIf="voucherSVG"></span>
                  <img *ngIf="!voucherSVG" [src]="'assets/svgs/voucherSVG.svg' | assetsUrl">
                </span>
              </h4>
            </div>
            <div class="aurow aucol-md-12">
              <div class="aucol-md-6 aucol-xs-6 aucol-sm-6 aucol-lg-6 auprisfont14 textfix conwidth btnheight"
                style="border-right: 1px solid rgba(0, 0, 0, 0.2);padding-left:1px;">
                <div *ngIf="quality?.ratePayment; else nettoSection">
                  {{languageData['Bestelloptionen'].Var_monthlyrate || ' Monatliche Rate'}}
                </div>
                <ng-template #nettoSection>
                  {{languageData['Bestelloptionen'].Var_Preis_pro || 'Preis pro'}}
                  <span class="prie"> {{priceFactor | replaced : languageVar}} {{label}} </span>
                  <b class="auprisfont14 inline-block pricee">
                    <span>
                      {{quality.currencySymbol}}
                    </span>
                    {{
                    (
                    (quality.voucherInfo && quality.voucherInfo.value && quality.identifyPackagesInPc ) ?
                    (quality.basicSurgargePriceFactorNett | langReplacer : languageVar) :
                    (!quality.pc100lWithoutFlatRate) ? (quality.basicSurgargePriceFactorNett | langReplacer :
                    languageVar) :(quality.basicPricePriceFactorNett | langReplacer : languageVar)
                    )
                    }}
                    {{(quality?.surcharge?.status && !quality.pc100lWithoutFlatRate && quality.identifyPackagesInPc) ?
                    '*' : ''}}
                  </b>
                  <span class="netto netoo">{{languageData['Bestelloptionen'].Var_netto || 'netto'}}</span>
                  <br>
                </ng-template>
                <div *ngIf="quality?.ratePayment; else bruttoSection" class="currencyalign2">
                  <!--Neee to check-->
                  <p style="font-size: 10px; display:inline-block">
                    {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                    <span [innerHTML]="currency_symbol"></span>
                    {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                    {{quality?.productTax?.status ? '**' : '' }}
                  </p>
                  <span class="auprisfont25 inline-block currencyalign1" style="white-space: nowrap;">
                    <b>
                      <span>{{quality.currencySymbol}}</span>
                      <!--Need to get this value from ratePayments-->
                      {{quality.installment | langReplacer : languageVar}}
                      {{(quality?.surcharge?.status && !quality.pc100lWithoutFlatRate && quality.identifyPackagesInPc) ?
                      '*' : ''}}
                    </b>
                  </span>
                </div>
                <ng-template #bruttoSection>
                  <small class="inklalign">
                    {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                    {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                    {{(quality.productTax.status ? '**' : '')}}
                  </small>
                  <span class="auprisfont25 inline-block pricee">
                    <b class="euroa">
                      <span>
                        {{quality.currencySymbol}}
                      </span>
                      {{quality.grossPriceFactor | langReplacer : languageVar}}
                      {{(quality?.surcharge?.status && !quality.pc100lWithoutFlatRate && quality.identifyPackagesInPc) ?
                      '*' : ''}}
                    </b>
                  </span>
                </ng-template>
                <span class="dblock pointer" *ngIf="quality?.productTax?.status"
                  (click)="steuerhinweisPopup(quality.productTax.infoText)">
                  <span class="Steualign">
                    **
                    {{ languageData?.Bestelloptionen?.Var_vat_notice ||'Steuerhinweis' }}
                  </span>
                </span>
              </div>
              <div class="aucol-md-6 aucol-xs-6 aucol-sm-6 aucol-lg-6 auprisfont14 textfix">
                <div class="currency1" *ngIf="quality?.ratePayment; else gesamtpreisNettoSection">
                  {{languageData['Bestelloptionen'].Var_Gesamtpreis || 'Gesamtpreis'}}
                  <b class="auprisfont14 inline-block pricee1" style="white-space: nowrap;">
                    <span>
                      {{quality.currencySymbol}}
                    </span>
                    {{quality.basicSurgargePriceNett | langReplacer : languageVar}}
                  </b>
                  <span class="nettoA1 nettoges1"> {{languageData['Bestelloptionen'].Var_netto || 'netto'}} </span>
                </div>
                <ng-template #gesamtpreisNettoSection>
                  {{languageData['Bestelloptionen'].Var_Gesamtpreis || 'Gesamtpreis'}}
                  <b class="currency pricee">
                    <span>
                      {{quality.currencySymbol}}
                    </span>
                    {{quality.basicSurgargePriceNett | langReplacer : languageVar}}
                    {{ quality?.surcharge?.name && quality.identifyPackagesInPc ? '*' :''}}
                  </b>
                  <span class="nettoges">{{languageData['Bestelloptionen'].Var_netto || 'netto'}}</span>
                  <br>
                </ng-template>
                <small class="inklalign">
                  {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                  {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                  {{quality?.productTax?.status ? '**' : '' }}
                </small>
                <span class="auprisfont25 inline-block gesprice" style="white-space: nowrap;">
                  <div *ngIf="quality?.ratePayment; else gesamtpreisBruttoSection">
                    <b>
                      <span>
                        {{quality.currencySymbol}}
                      </span>
                      {{quality.grossWithFinance | langReplacer : languageVar}}
                      {{quality?.surcharge?.name && quality.identifyPackagesInPc ? '*' : ''}}
                    </b>
                  </div>
                  <ng-template #gesamtpreisBruttoSection>
                    <b>
                      <span>
                        {{quality.currencySymbol}}
                      </span>
                      {{quality.gross | langReplacer : languageVar}}
                      {{quality?.surcharge?.name && quality.identifyPackagesInPc ? '*' : ''}}
                    </b>
                  </ng-template>
                </span>
                <span (click)="priceDetails(quality)" style="cursor: pointer;">
                  <span style="padding-left: 3px; position: absolute;margin-top: 8px;display: inline-block!important;"
                    class="calalign pricecal" *ngIf=" quality.showCustomers">
                    <span [innerHTML]="calculator1" *ngIf="calculator1"></span>
                    <img *ngIf="!calculator1" [src]="'assets/calculator1.svg' | assetsUrl">
                  </span>
                </span>
                <small class="paushalea">
                  <br>
                  {{quality?.surcharge?.name && quality.identifyPackagesInPc ? '* inkl' : ''}}
                  {{quality?.surcharge?.name && quality.identifyPackagesInPc ? quality?.surcharge?.name : ''}}
                  <!-- {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}  -->
                  <small [ngStyle]="{color:'var(--highlightColour)'}" *ngIf="quality.voucherInfo">
                    <br>
                    {{languageData['Bestelloptionen'].Var_grossValueVoucher || 'Gutschein i.H.v. '}}
                    <b class="auprisfont14 inline-block">
                      {{
                      ( quality.voucherInfo.conversion == 1) ?
                      (localSessionUpdatedData.quantity/100)*quality.voucherInfo.value:quality.voucherInfo.value
                      }}
                    </b>
                    <span>
                      {{quality.currencySymbol}}
                    </span>,
                    {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                    {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                    {{languageData['Bestelloptionen'].Var_enthalten }}
                  </small>
                </small>
                <!-- <small [ngStyle]="{color:colors?.Highlight_Farbe}"
                  *ngIf="!quality.Pauschalen_PC && quality.gutschein && quality.gutschein?.valid">
                  <div> <br> {{languageData['Bestelloptionen'].Var_grossValueVoucher || 'Gutschein i.H.v. '}}
                    <b class="auprisfont14 inline-block">
                      {{quality.gutschein.value}}
                    </b>
                    <span>€
                    </span>,
                    {{languageData['Bestelloptionen'].Var_inkl || 'inkl'}}
                    {{languageData['Bestelloptionen'].Var_MwSt || 'MwSt'}}
                    {{languageData['Bestelloptionen'].Var_enthalten }}
                  </div>
                </small> -->
              </div>
            </div>
          </div>

          <div class="aucol-md-4 aucol-xs-12 aucol-sm-12 aucol-lg-3 mediaScreeen select"
            *ngIf="!quality.qualities.co2Compensation?.compensate"
            style="margin-top: 0px;align-items: center;cursor: pointer;padding-left: 0px;padding-right: 0px;"
            (click)="$event.stopPropagation();">
            <div *ngIf="!quality.qualities.co2Compensation?.compensate && ratePayments" class="cotent1 btnborder"
              style="display: flex;height:50%;margin-top: 0px;align-items: center;cursor: pointer;padding-left: 0px;"
              [ngStyle]="{background: ratePaymentOptionSelected?'var(--primaryColour)':'grey'}"
              (click)="selectRateOption(!ratePaymentOptionSelected);$event.stopPropagation();">
              <div class="" [ngStyle]="{color:'var(--primaryTextColour)'}">
                <label class="checkbox-label" [ngClass]="{checked:ratePaymentOptionSelected }"
                  style=" cursor: pointer!important;vertical-align: middle;color: #707070;"
                  (click)="selectQuality($event ,i, quality,'on');$event.stopPropagation();">
                  <input type="checkbox" [(ngModel)]="ratePaymentOptionSelected"
                    (change)="selectRateOption($event.target.checked);">
                  <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                    <span class="checkbox-tick" [ngStyle]="{'border-color': 'var(--primaryColour)'}"></span>
                  </span>
                </label>
                <span style="vertical-align: inherit;padding-left: 7px;">
                  {{languageData['Bestelloptionen']['Var_ratepayment']|| 'Ratenzahlung' }}
                </span>
              </div>
            </div>
            <div class="content2" style="display: flex;align-items: center;" [style.height.%]='(ratePayments?50:100)'
              [ngStyle]="{background: quality.selected? 'var(--primaryColour)':'grey', color:'var(--primaryTextColour)'}"
              (click)="selectQuality($event ,i, quality,'on')"
              [ngClass]="{'content2': ratePayments,'content2Rate': !ratePayments}">
              <label class="checkbox-label" [ngClass]="{'checked': quality.selected}"
                style=" cursor: pointer!important;vertical-align: middle;color: #707070;">
                <input type="checkbox" (change)="selectQuality($event ,i, quality,$event.target.value)">
                <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                  <span class="checkbox-tick" [ngStyle]="{'border-color': 'var(--primaryColour)'}"></span>
                </span>
              </label>
              <span style="vertical-align: inherit;padding-left: 10px;">
                {{languageData['Bestelloptionen']['Var_select']|| 'Ausgewählt' }}
              </span>
            </div>
          </div>
          <div class="aucol-md-4 aucol-xs-12 aucol-sm-12 aucol-lg-3 clg mediaScreeen"
            style="padding-left: 0;font-family: sans-serif" *ngIf="quality.qualities.co2Compensation?.compensate">
            <div class="aurow" style="width: 100% !important;height: 100%">
              <div class="aucol-md-12 change1 " style="height: 100%">
                <div class="cotent aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12 rto"
                  [style.height.%]='(quality.qualities.co2Compensation?.compensate && ratePayments?34:50)'
                  style="display:flex;cursor: context-menu; padding-left:0px;vertical-align: middle;align-items: center;"
                  [ngStyle]="{'background-color': (check(quality?.qualities?._id))  || quality.qualities.co2Compensation?.type == 'Dauerhaft' ? ('var(--co2Colour)' || 'green') : 'gray'}">
                  <label *ngIf="!(quality.qualities.co2Compensation?.type == 'Dauerhaft')" class="checkbox-label"
                    [ngClass]="{checked: check(quality?.qualities?._id)}"
                    style=" cursor: pointer!important;vertical-align: middle;color: #707070;">
                    <input type="checkbox" [disabled]="quality?.qualities.co2Compensation?.type == 'Dauerhaft'"
                      (change)="co2Compensate(quality.qualities._id)" [checked]="check(quality?.qualities?._id)">
                    <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                      <span class="checkbox-tick"
                        [ngStyle]="{'border-color': check(quality?.qualities?._id) ? 'var(--primaryColour)' : 'var(--primaryTextColour)'}"></span>
                    </span>
                  </label>
                  <a [href]="quality.qualities.co2Project?.projectlink"
                    *ngIf="quality.qualities.co2Project?.projectlink"
                    [style.padding-left.px]='(quality.qualities.co2Compensation.type == "Dauerhaft" ?40:10)'
                    class="compensationtext" target="_blank">CO<sub>2</sub>
                    {{languageData['Bestelloptionen'].Var_Kompensation || 'Kompensation'}}</a>
                  <span *ngIf="!quality.qualities.co2Project?.projectlink"
                    [style.padding-left.px]='(quality.qualities.co2Compensation.type == "Dauerhaft" ?40:10)'
                    class="compensationtext co2" target="_blank">CO<sub>2</sub>
                    {{languageData['Bestelloptionen'].Var_Kompensation || 'Kompensation'}}
                  </span>
                  <img [src]="'assets/co2monkey.svg' | assetsUrl" class="sbg cl" style="margin-top: 8px;" />
                </div>
                <div class="aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12 cotent1 btnborder" *ngIf="ratePayments"
                  (click)="$event.stopPropagation();selectRateOption(!ratePaymentOptionSelected)"
                  style="display: flex;height:33%;margin-top: 0px;align-items: center;cursor: pointer;padding-left: 0px;"
                  [ngStyle]="{background: ratePaymentOptionSelected?'var(--primaryColour)':'grey'}">
                  <div class="" [ngStyle]="{color:'var(--primaryTextColour)'}">
                    <label class="checkbox-label" [ngClass]="{checked: ratePaymentOptionSelected}"
                      style=" cursor: pointer!important;vertical-align: middle;color: #707070;"
                      (click)="selectQuality($event ,i, quality,'on');$event.stopPropagation();">
                      <input type="checkbox" [checked]="ratePaymentOptionSelected"
                        (change)="selectRateOption($event.target.checked);">
                      <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                        <span class="checkbox-tick" [ngStyle]="{'border-color': 'var(--primaryColour)'}"></span>
                      </span>
                    </label>
                    <span style="vertical-align: inherit;padding-left: 7px;">
                      {{languageData['Bestelloptionen']['Var_ratepayment']|| 'Ratenzahlung' }}
                    </span>
                  </div>
                </div>
                <div class="aucol-md-12 aucol-xs-12 aucol-sm-12 aucol-lg-12 cotent1"
                  click="selectQuality($event ,i, quality,'on');$event.stopPropagation();"
                  style="display: inline-flex;margin-top: 0px;align-items: center;cursor: pointer;padding-left: 0px;"
                  [ngStyle]="{background: quality.selected ? 'var(--primaryColour)' : 'grey'}"
                  [style.height.%]='(quality.qualities.co2Compensation?.compensate&&ratePayments?33:50)'
                  [ngClass]="{'content2': ratePayments,'content2Rate': !ratePayments}">
                  <div class="" [ngStyle]="{color:'var(--primaryTextColour)'}">
                    <label class="checkbox-label" [ngClass]="{checked: quality.selected}"
                      style=" cursor: pointer!important;vertical-align: middle;color: #707070;">
                      <input type="checkbox" (change)="selectQuality($event ,i, quality,$event.target.value)">
                      <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                        <span class="checkbox-tick" [ngStyle]="{'border-color': 'var(--primaryColour)'}"></span>
                      </span>
                    </label>
                    <span style="vertical-align: inherit;padding-left: 8px;">
                      {{languageData['Bestelloptionen']['Var_select']|| 'Ausgewählt' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div (click)="$event.stopPropagation();"
      *ngIf="showPriceAlarm  && pricealarmActivation && (alarmIndex == i || checkShowPriceAlarm(priceCalculationData.length)) &&  alarmIndex != 0 && alarmIndex != 1"
      class="aucol-lg-12 panel panel-default  aucol-md-12 pull-right aucol-sm-12 aucol-xs-12 boxShadowNormal pricealam">
      <div class="aurow hovereffect md" style="display: flex;align-items: center;">
        <div class="aucol-md-1 aucol-xs-2 aucol-sm-2 aucol-lg-1 alarmHeight">
          <span [innerHTML]="priceAlaram" *ngIf="priceAlaram" class="alarmM"></span>
          <img *ngIf="!priceAlaram" [src]="'assets/svgs/priceAlaram.svg' | assetsUrl">
        </div>
        <div class="aucol-md-8 aucol-xs-10 aucol-sm-10 aucol-lg-8 alarmw" (click)="showPreiswecker()">
          {{priceAlaramText}}
        </div>
        <div class="aucol-md-4 aucol-xs-12 aucol-sm-12 aucol-lg-3 alarmHG"
          style="height:100%;padding:15px;cursor: pointer;" [ngStyle]="{background: 'var(--primaryColour)'}"
          (click)="showPreiswecker()">
          <div class="text-center" [ngStyle]="{color:'var(--primaryTextColour)'}">
            {{languageData['Hamburger']?.Var_pricealaram || 'Preiswecker'}}
          </div>
        </div>
      </div>
    </div>
    <div (click)="$event.stopPropagation();"
      *ngIf="showPriceAlarm  && pricealarmActivation && (staticApeend(priceCalculationData.length , i)) &&  alarmIndex != 0 && alarmIndex != 1 && last"
      class="aucol-lg-12 panel panel-default  aucol-md-12 pull-right aucol-sm-12 aucol-xs-12 boxShadowNormal pricealam">
      <div class="aurow hovereffect md" style="display: flex;align-items: center;">
        <div class="aucol-md-1 aucol-xs-2 aucol-sm-2 aucol-lg-1 alarmHeight">
          <span [innerHTML]="priceAlaram" *ngIf="priceAlaram" class="alarmM"></span>
          <img *ngIf="!priceAlaram" [src]="'assets/svgs/priceAlaram.svg' | assetsUrl">
        </div>
        <div class="aucol-md-8 aucol-xs-10 aucol-sm-10 aucol-lg-8 alarmw" (click)="showPreiswecker()">
          {{priceAlaramText}}
        </div>
        <div class="aucol-md-4 aucol-xs-12 aucol-sm-12 aucol-lg-3 alarmHG"
          style="height:100%;padding:15px;cursor: pointer;" [ngStyle]="{background: 'var(--primaryColour)'}"
          (click)="showPreiswecker()">
          <div class="text-center" [ngStyle]="{color:'var(--primaryTextColour)'}">
            {{languageData['Hamburger']?.Var_pricealaram || 'Preiswecker'}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="aucol-md-12 aucol-sm-12 aucol-xs-12 text-center alert-text">
    <p *ngIf="localSessionUpdatedData?.stations >= 2" class="sammeltext">
      <b>
        {{languageData?.Bestelloptionen['Var_Fillingerror2_Text'] || 'Sammelbestellung: Abladestellen dürfen
        nicht
        mehr als'}} {{maxDistance}} {{languageData?.Bestelloptionen['Var_Fillingerror3_Text'] || 'Km von der
        Bestelladresse
        entfernt sein.' }}
      </b>
    </p>
  </div>

  <div *ngIf="selectedQuality && (priceCalculationData && priceCalculationData.length > 0)">
    <button type="button" class="nxtBtn font20 pull-right smalBtn btn" id="welterbtn"
      [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)', 'pointer-events': preview? 'none': 'auto'}"
      [style.background]="'var(--primaryColour)'" (click)="bestellen()">
      {{languageData['Bestelloptionen'].Var_Bestellen || 'Bestellen'}}
    </button>
  </div>

</div>

<!-- <div>
  <p *ngIf="!dynamicPcActive" style="text-align:center;color: red;font-size: 14px;">
    <b>
      {{this.productInfoForWebPC?.pcText}}
    </b>
  </p>
</div> -->
<loader [full-screen]="true" *ngIf="loader" [color]="'var(--primaryColour)'"></loader>