import { Component, OnInit, Inject, } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AngularMyDatePickerDirective, IAngularMyDpOptions, IMyDateModel, IMyDefaultMonth } from 'angular-mydatepicker';
import { LanguageService } from '../../Services/language.service';
import { config } from 'cypress/types/bluebird';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {
  locale: string = 'de';
  mydp: AngularMyDatePickerDirective;
  startdata: any;
  enddata: any;
  colors: any;
  hostname: any;
  min: number = 0;
  max: number = 0;
  monthdays: { 1: number; 2: number; 3: number; 4: number; 5: number; 6: number; 7: number; 8: number; 9: number; 10: number; 11: number; 12: number; };
  extramonthdays: number;
  disalemonth: any;
  myOptions: IAngularMyDpOptions;
  disableweekdays: any;
  dayLabels: { su: string; mo: string; tu: string; we: string; th: string; fr: string; sa: string; };
  minextramonthdays: number;
  mindisalemonth: number;
  totalweekdays: any;
  startweekday: number;
  endweekday: number;
  mintotaldays: number;
  extraweekdays = 0;
  exactmax: number;
  minmaxvalues: any;
  cal: any;
  model: IMyDateModel = null;
  fromdate: any;
  todate: any;
  selDate: { year: any; month: any; day: any; };
  mindisableyear: number;
  disableyear: number;

  // datepicker:FormGroup;

  defaultMonth: IMyDefaultMonth = {
    defMonth: ''
  };
  myDateInit: boolean = true;
  languageData: any;


  wunsterminOptions = [];
  wunsOptionMinMaxTag: any;

  constructor(
    public dialogRef: MatDialogRef<DatePickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private langService: LanguageService
  ) {

    let weekoffs = {
      Mo: (data.weekoffs.indexOf(0) >= 0 ? false : true),
      Di: (data.weekoffs.indexOf(1) >= 0 ? false : true),
      Mi: (data.weekoffs.indexOf(2) >= 0 ? false : true),
      Do: (data.weekoffs.indexOf(3) >= 0 ? false : true),
      Fr: (data.weekoffs.indexOf(4) >= 0 ? false : true),
      Sa: (data.weekoffs.indexOf(5) >= 0 ? false : true),
      So: (data.weekoffs.indexOf(6) >= 0 ? false : true),
    };

    this.wunsterminOptions = data.wunsterminOptions;

    if (this.wunsterminOptions.length) {
      this.wunsOptionMinMaxTag = this.wunsterminOptions.filter((option: any) => option.staticId == 3);
      if (this.wunsOptionMinMaxTag) {
        this.min = this.wunsOptionMinMaxTag[0].additionalValueFrom;
        this.max = this.wunsOptionMinMaxTag[0].additionalValueTo;
      }
    };



    // this.min = data[3].min_tage;
    // this.max = data[3].max_tage;
    this.cal = weekoffs;
    let langData = this.langService.getLocalLanguageData();
    this.languageData = langData['Datepicker'] || {};
    this.langService.onLanguageChange.subscribe((data: any) => {
      this.languageData = data['Datepicker'] || {};
    });
  };

  ngOnInit() {
    let fromdate = new Date(this.data.fromDate);
    let todate = new Date(this.data.toDate);
    
    if (this.myDateInit) {
      this.model = {
        isRange: true,
        singleDate: null,
        dateRange: {
          beginDate: {
            year: fromdate.getFullYear(), month: (fromdate.getMonth() + 1), day: fromdate.getDate()
          },
          endDate: {
            year: todate.getFullYear(), month: (todate.getMonth() + 1), day: todate.getDate()
          },
        }
      };
    }    
    // this.disableweekdays = { "Mo": false, "Di": false, "Mi":true, "Do": false, "Fr":false, "Sa":false, "So":false }
    this.disableweekdays = {
      "Mo": this.cal.Mo,
      "Di": this.cal.Di,
      "Mi": this.cal.Mi,
      "Do": this.cal.Do,
      "Fr": this.cal.Fr,
      "Sa": this.cal.Sa,
      "So": this.cal.So
    };

    let days = Object.keys(this.disableweekdays);
    let enabledDays = days.filter(day => this.disableweekdays[day] == false);
    this.dayLabels = {
      su: 'So',
      mo: 'Mo',
      tu: 'Di',
      we: 'Mi',
      th: 'Do',
      fr: 'Fr',
      sa: 'Sa'
    };

    // Object
    let daylabelArray = Object.keys(this.dayLabels).reduce((prev, label) => {
      let labelPair = {}; // [{su: 'So}, {mo:'Mo'}]
      labelPair[label] = this.dayLabels[label];
      return prev = [...prev, labelPair]
    }, [])
    this.disableweekdays = enabledDays.reduce((prev, day) => {
      let dayLabelPair = daylabelArray.map(label => {
        let validLabels = []
        let values = Object.keys(label).map(key => label[key]);
        if (values.indexOf(day) >= 0) {
          validLabels = [...validLabels, ...Object.keys(label)]
        }
        return validLabels
      });

      dayLabelPair = [].concat.apply([], dayLabelPair);
      if (dayLabelPair) {
        prev = [...prev, ...dayLabelPair]
      }
      return prev
    }, []);
    // if this-day == sat || sun ? add +1 day
    // for loop from current day to min days
    // logic to calculate mindate start
    var disableweeks = { 'su': 0, 'mo': 1, 'tu': 2, 'we': 3, 'th': 4, 'fr': 5, 'sa': 6 };
    var disableweekvalues = this.disableweekdays.reduce((prev, day) => {
      return prev = [...prev, disableweeks[day]]
    }, []);

    let minDays = 0;
    var minextradisables = this.min - 1;

    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-indexed
    let currentDay = new Date().getDate();
    let filterDatesPastDates = this.data.holidays.filter((holiday: any) => {
      if (holiday.year > currentYear ||
        (holiday.year == currentYear && holiday.month > currentMonth) ||
        (holiday.year == currentYear && holiday.month == currentMonth && holiday.day >= currentDay)) {
        return holiday;
      }
    });
    let filterHolydays = filterDatesPastDates.map((holiday: any) => new Date(holiday.year, holiday.month - 1, holiday.day));

    for (let i = 0; i <= minextradisables; i++) {
      let date = new Date();
      date.setDate(date.getDate() + i);
      let dateExists = disableweekvalues.find(arr => arr == date.getDay());
      if (dateExists >= 0) {
        minDays += 1;
        minextradisables += 1
      } else {
        minDays++;
      }
    };

    let startDate = new Date();
    startDate.setDate(startDate.getDate() + minDays);
    this.minextramonthdays = startDate.getDate();
    this.mindisalemonth = startDate.getMonth() + 1;
    this.mindisableyear = startDate.getFullYear();
// console.log(startDate,646);

    // logic to calculate mindate end

    // logic to calculate maxdate start
    let maxDays = 0;
    var maxextradisables = this.max - this.min;
    // console.log(maxextradisables ,45);
    // console.log(disableweekvalues ,54546);
    

    for (let j = 1; j <= maxextradisables; j++) {
      let fdate = new Date();
      fdate.setDate(fdate.getDate() + minDays - 1);
      fdate.setDate(fdate.getDate() + j);
      // console.log(fdate, fdate.getDate(),664);
      
      let ab = filterHolydays.find(x => x.getDate() == fdate.getDate() && x.getMonth() == fdate.getMonth());
      let dateExists = disableweekvalues.find(arr => arr == fdate.getDay());
      if (dateExists >= 0) {
        maxDays += 1;
        maxextradisables += 1
      } 
      else if (ab) {
        maxDays += 1;
        maxextradisables += 1
      } else {
        maxDays++;
      }
    };
    
    let maxdate = new Date();
    maxdate.setDate(maxdate.getDate() + minDays);
    maxdate.setDate(maxdate.getDate() + maxDays);
    // console.log(maxdate ,64646);
    if (disableweekvalues.includes(maxdate.getDay())) {
      console.log("hello");
      // console.log(maxdate.getDay());
      // console.log(filterHolydays);
      function findNextNonHoliday(maxdate: Date) {
        let ab = filterHolydays.find(x => x.getDate() == maxdate.getDate() && x.getMonth() == maxdate.getMonth());
        console.log(ab);
        if (ab) {
          maxdate.setDate(maxdate.getDate() + 1);
          return findNextNonHoliday(maxdate); // Recursive call
        } else {
          return maxdate;
        }
      }
      if (maxdate.getDay() == 6) {
        maxdate.setDate(maxdate.getDate() + 2)
      } else {
        maxdate.setDate(maxdate.getDate() + 1);
      }
      console.log(maxdate.getDate());
      maxdate = findNextNonHoliday(maxdate);

    }
    this.extramonthdays = maxdate.getDate();
    this.disalemonth = maxdate.getMonth() + 1;
    this.disableyear = maxdate.getFullYear();
    var formattedNumber = ("0" + this.mindisalemonth).slice(-2);
    this.defaultMonth.defMonth = `${formattedNumber}/${this.mindisableyear}`;
    if (this.minextramonthdays - 1 == 0) {
      this.mindisalemonth = this.mindisalemonth - 1;
      let lastDayOfMonth = new Date(this.mindisableyear, this.mindisalemonth + 1, 0).getDate();
      this.minextramonthdays = lastDayOfMonth;
    }

    console.log("disableUntil", { year: this.mindisableyear, month: this.mindisalemonth, day: this.minextramonthdays - 1 });
    console.log("disableSince", { year: this.disableyear, month: this.disalemonth, day: this.extramonthdays + 1 },);
    this.myOptions = {
      dateFormat: 'dd.mm.yyyy',
      dayLabels: this.dayLabels,
      monthLabels: { 1: 'Januar', 2: 'Februar', 3: 'März', 4: 'April', 5: 'Mai', 6: 'Juni', 7: 'Juli', 8: 'August', 9: 'September', 10: 'Oktober', 11: 'November', 12: 'Dezember' },
      disableUntil: { year: this.mindisableyear, month: this.mindisalemonth, day: this.minextramonthdays - 1 },
      disableSince: { year: this.disableyear, month: this.disalemonth, day: this.extramonthdays + 1 },
      sunHighlight: false,
      monthSelector: false,
      yearSelector: false,
      firstDayOfWeek: 'mo',
      showWeekNumbers: false,
      inline: true,
      dateRange: true,
      disableWeekdays: this.disableweekdays,
      stylesData: {
        selector: 'dp1',
        styles: `
        dp1.myDpDisabled {
          cursor: no-drop !;
      }
      .ng-mydp{
        z-index:1!important;
      }
     dp1 .myDpDaycell, .myDpMonthLabel, .myDpMonthcell, .myDpYearLabel, .myDpYearcell {
        cursor: no-drop;
        pointer-events: none;
    }
    .myDpCurrMonth {
      background-color: #f6f6f600;
    }
    .myDpRangeColor{
      background-color: #dbeaff!important;
    }
    .myDpHeaderBtn , .myDpMonthBtn  {
      color: #222 !important;
      background-color:transparent;
    }
    }
        .dp1 .myDpIconLeftArrow,
        .dp1 .myDpIconRightArrow,
        .dp1 .myDpHeaderBtn , {
            color: #222 !important;
            background-color:transparent;
        }
        .dp1 .myDpHeaderBtn:focus,
        .dp1 .myDpMonthLabel:focus,
        .dp1 .myDpYearLabel:focus {
          color: ${this.colors.primaryTextColour};
        }
        .dp1 .myDpCurrMonth {
          color: black;
        } ,
       
        .dp1 .myDpMonthcell,
        .dp1 .myDpYearcell {
            color: black;
        }
        .dp1 .myDpPrevMonth,
        .dp1 .myDpNextMonth {
            color: black;
        }
        .dp1 .myDpWeekDayTitle {
            background-color:${this.colors.primaryColour};
            color: ${this.colors.primaryTextColour};
        }
        .dp1 .myDpHeaderBtnEnabled:hover,
        .dp1 .myDpMonthLabel:hover,
        .dp1 .myDpYearLabel:hover {
            color:${this.colors.primaryTextColour};;
        }
        .dp1 .myDpMarkCurrDay,
        .dp1 .myDpMarkCurrMonth,
        .dp1 .myDpMarkCurrYear {
            border-bottom: none;
        }
        .dp1 .myDpMarkCurrDay{
          border-bottom: 2px solid #036;
        }
        .dp1 .myDpDisabled {
            color: black;
            background: none!important;
            opacity: 0.3;
        }
        .dp1 .myDpHighlight {
            color: green;
        }
        .dp1 .myDpTableSingleDay:hover,
        .dp1 .myDpTableSingleMonth:hover,
        .dp1 .myDpTableSingleYear:hover {
            background-color:${this.colors.primaryColour} ;
            opacity:.8;
        }
        .dp1 .monthYearSelBar {
          background-color:${this.colors.primaryColour};
          color:${this.colors.primaryTextColour}!important;
        }
        .dp1 .myDpSelectedDay, .myDpSelectedMonth, .myDpSelectedYear{
          background-color:${this.colors.primaryColour}!important ;
          color:${this.colors.primaryTextColour}!important;
        }

      `

      },
    };
    if (this.data.holidays && this.data.holidays.length > 0) {
      this.myOptions = {
        ...this.myOptions,
        disableDates: this.data.holidays
      };
    }
  };

  pretext() {
    if ((this.fromdate != this.data.fromDate && this.todate != this.data.toDate)) {
      if (((this.data.fromDate == undefined || this.data.fromDate == '') && (this.data.toDate != undefined || this.data.toDate != ''))) {
        let object = { type: "cancel" };
        this.dialogRef.close({ data: object });
      } else {
        let object = { fromDate: this.data.fromDate, toDate: this.data.toDate, type: "save" };
        this.dialogRef.close({ data: object });
      }
    } else {
      let object = { fromDate: this.data.fromDate, toDate: this.data.toDate, type: "cancel" };
      this.dialogRef.close({ data: object });
    }
  };

  onRangeDateSelection(date) {
    if (date.isBegin == true) {
      this.fromdate = `${date.date.day}.${date.date.month}.${date.date.year}`;
    } else {
      this.todate = `${date.date.day}.${date.date.month}.${date.date.year}`;
    }
  };

  onCalendarViewChanged() {
  };

  wunster() {
    if (((this.fromdate == undefined || this.fromdate == '') && (this.todate == undefined || this.todate == ''))) {
      let object = { fromDate: this.data.fromDate, toDate: this.data.toDate };
      this.dialogRef.close({ data: object });
    } else {
      let object = { fromDate: this.fromdate, toDate: this.todate };
      this.dialogRef.close({ data: object });
    }
  };

  inputDateChange(dates) {
    console.log(dates);
  };
}