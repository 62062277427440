<div class="aupris_main" style="margin:auto">
  <div class="au-container">
    <div class="aurow " *ngIf="ftpassword">
      <div class="aucol-xs-12 aucol-sm-12 aucol-md-12 aucol-lg-12 " style="display: inline-flex;">
        <div [formGroup]="resetPasswordFrom" class="card" style="min-width: 400px;margin-right:30px;">
          <h4>
            {{languageData['login'].VarWeb-PcLogin11 || 'Neues Passwort festlegen'}}
          </h4>
          <div class="auform-group">
            <img class="passwort" [src]="'assets/svgs/password.svg' | assetsUrl">
            <input [type]="passwordVisibility ? 'text' : 'password'" class="auform-control"
              placeholder="{{languageData?.login?.Var_Passwort|| 'Passwort'}}" formControlName="password">
            <span class="material-icons WH-visibiltyIcon cursor-pointer eyeIcon"
                (click)="passwordVisibility = !passwordVisibility">
                {{passwordVisibility ? 'visibility' : 'visibility_off'}}
              </span>

            <!-- <div class="alert alert-warning"
            *ngIf="!resetPasswordFrom.controls.password.valid && resetPasswordFrom.controls.password.touched">{{languageData['login'].Var_Passwortwird || '
            Passwort wird benötigt'}}</div> -->
          </div>
          <div class="auform-group">
            <img class="repeat" [src]="'assets/svgs/repeatPassword.svg' | assetsUrl">
            <input class="auform-control" type="password"
              placeholder="{{languageData?.login?.Var_re_Passwort|| 'Wiederhole das Passwort'}}"
              formControlName="re_password" [type]="passwordVisibilityy ? 'text' : 'password'">
            <div class="alert alert-danger"
              *ngIf="!resetPasswordFrom.controls.re_password.valid && resetPasswordFrom.controls.re_password.touched">
              {{languageData['login'].Var_wiederhole|| 'Wiederhole das Passwort'}}
            </div>
            <span class="material-icons WH-visibiltyIcon cursor-pointer eyeIcon"
                (click)="passwordVisibilityy = !passwordVisibilityy">
                {{passwordVisibilityy ? 'visibility' : 'visibility_off'}}
              </span>
            <div class="alert alert-danger"
              *ngIf="resetPasswordFrom.controls.re_password.valid && resetPasswordFrom.errors && resetPasswordFrom.controls.re_password.touched">
              <!-- grtygiop -->
              {{languageData['login'].var_passworter|| 'thPasswörter stimmen nicht überein'}}
            </div>
          </div>
          <div style="display: inline-flex;align-items: center;width:100%;">
            <a style="float:left;text-align: left;width: 45%;" (click)="abbrechen()">
              {{languageData['login'].Var_Abbrechen|| 'Abbrechen'}}
            </a>
            <span style="float:right;text-align: right;width: 55%;">
              <button class='au-btn btn-lg' [disabled]="!resetPasswordFrom.valid && loading " (click)="resetpassword()"
                [style.cursor]="(resetPasswordFrom.invalid || resetPasswordFrom.get('password')?.errors?.pattern) ? 'no-drop' : ''"
                [ngStyle]="{'background-color':'var(--primaryColour)'}">
                {{languageData['login'].Var_fortsetzen|| 'Fortsetzen'}}
              </button>
            </span>
          </div>
          <!-- <div style="padding-top: 25px;">
              <button class='btn btn-lg' [disabled]="!resetPasswordFrom.valid" (click)="resetpassword()"  [ngStyle]="{'background-color': colors?.Highlight_Farbe}">Fortsetzen</button>
          </div> -->
        </div>
        <div style="min-width: 250px;position: relative;top: 100px;">
          <div style="display: flex;" *ngIf="!(resetPasswordFrom.get('password').value?.length >= 8)"><img src="../../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin4'] || 'Mind. 8 Zeichen'}}</span></div>
          <div style="display: flex;" *ngIf="(resetPasswordFrom.get('password').value?.length >= 8)"><img src="../../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin4'] || 'Mind. 8 Zeichen'}}</span></div>
          <div style="display: flex;" *ngIf="!(resetPasswordFrom.get('password').value?.length <= 20)"><img src="../../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin9'] || 'Max. 20 Zeichen'}}</span></div>
          <div style="display: flex;" *ngIf="!(checkValidity(resetPasswordFrom.get('password').value, 'uppercase'))"><img src="../../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin5'] || 'Mind. 1 Großbuchstabe'}}</span></div>
          <div style="display: flex;" *ngIf="(checkValidity(resetPasswordFrom.get('password').value, 'uppercase'))"><img src="../../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin5'] || 'Mind. 1 Großbuchstabe'}}</span></div>
          <div style="display: flex;" *ngIf="!(checkValidity(resetPasswordFrom.get('password').value, 'lowercase'))"><img src="../../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin6'] || 'Mind. 1 Kleinbuchstabe'}}</span></div>
          <div style="display: flex;" *ngIf="(checkValidity(resetPasswordFrom.get('password').value, 'lowercase'))"><img src="../../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin6'] || 'Mind. 1 Kleinbuchstabe'}}</span></div>
          <div style="display: flex;" *ngIf="!(checkValidity(resetPasswordFrom.get('password').value, 'numeric'))"><img src="../../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin7'] || 'Mind. 1 Zahl'}}</span></div>
          <div style="display: flex;" *ngIf="(checkValidity(resetPasswordFrom.get('password').value, 'numeric'))"><img src="../../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin7'] || 'Mind. 1 Zahl'}}</span></div>
          <div style="display: flex;" *ngIf="(checkValidity(resetPasswordFrom.get('password').value, 'specialchar'))"><img src="../../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin8'] || 'Keine Sonderzeichen erlaubt'}}</span></div>
        </div>
      </div>
    </div>
    <div *ngIf="!ftpassword">Ihre Passwortbestätigungszeit ist abgelaufen</div>
  </div>
</div>

<loader [full-screen]="true" *ngIf="screenLoaderSet" [color]="'var(--primaryColour)'"></loader>