import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
import { SmallPcService } from './Services/small-pc.service';
import { WindowRefService, ICustomWindow } from './Services/window.service';
import { BigpcService } from 'app/Services/bigpc.service';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _window: ICustomWindow;
  url: string;
  domainname: string;
  Liter: string;
  Product: string;
  view: string;
  Lieferort: string;

  constructor(
    private windowRef: WindowRefService,
  ) {
    this._window = this.windowRef.nativeWindow;
    this.url = this._window.location.href;
    var params = new URLSearchParams(this.url);
    this.domainname = decodeURIComponent(params.get("Domainname"));
    this.Liter = decodeURIComponent(params.get("Liter"));
    this.Product = decodeURIComponent(params.get("Product"));
    this.view = decodeURIComponent(params.get("view"));
    this.Lieferort = decodeURIComponent(params.get("Lieferort"));
  };
  editId: any;
  orderIndex: any;


  loaderTime: any = 10000;

  // stopLoaderHere() {
  //   setTimeout(() => {
  //     return false;
  //   }, this.loaderTime);
  // }

  setEditAddressId(id) {
    this.editId = id;
  }

  public getViewType() {
    let url = this._window.location.href;/*testcalc*/
    var params = new URLSearchParams(url);/*testcalc*/
    return decodeURIComponent(params.get("view"))
  };

  public getCustomerId() {
    let url = this._window.location.href;
    var params = new URLSearchParams(url);
    return decodeURIComponent(params.get("id"))
  };

  getUrlParameters = (): UrlParameters => {
    return {
      Lieferort: this.Lieferort,
      domainname: this.domainname,
      Liter: this.Liter,
      Product: this.Product,
      view: this.view,
      url: this.url
    }
  };

  changeRouteParams(paramKey, paramValue) {
    this.url = this._window.location.href;
    if (!this.url.includes('#&')) {
      if (this.url.includes('&')) {
        let splitUrl = this.url.split('&');
        if (splitUrl.length > 0) {
          splitUrl[0] = `${splitUrl[0]}#`;
          this.url = splitUrl.join('&');
        }
      } else if (!this.url.includes('#')) {
        this.url = `${this.url}#`
      }
    };

    let url: string = this.url;
    let params = new URLSearchParams(this.url);
    let currentPage = decodeURIComponent(params.get(paramKey));
    currentPage = encodeURIComponent(currentPage);

    // filter the stage params in other pages
    if (['dynamicPcs'].includes(currentPage)) {
      let stage = params.get('stage');
      if (url.includes(`&stage=${stage}`)) {
        url = url.replace(`&stage=${stage}`, '')
      } else if (url.includes(`stage=${stage}`)) {
        url = url.replace(`stage=${stage}`, '')
      }
    };

    if (['pricealarm'].includes(currentPage)) {
      let priceAlarmStage = params.get('priceAlarmStage');
      if (url.includes('priceAlarmStage')) {
        url = url.replace(`&priceAlarmStage=${priceAlarmStage}`, '');
      }
    };


    let regex = `&${paramKey}=${currentPage}`
    window.history.pushState('', document.title, this.url);
    if (url.includes(currentPage)) {
      url = url.replace(regex, '');
    };

    let newUrl = url;
    newUrl = `${newUrl}&${paramKey}=${paramValue}`;
    if (['dynamicPcs'].includes(paramValue)) {
      if (newUrl.includes('stage')) {
        newUrl = newUrl.replace(`&stage=two`, '');
        newUrl = newUrl.replace(`&stage=three`, '');
        newUrl = newUrl.replace(`&stage=four`, '');
      } else {
        newUrl = `${newUrl}&stage=one`;
      }
    };

    if (newUrl.includes('subPage')) {
      if (newUrl.includes('subPage=addAdressen')) {
        newUrl = newUrl.replace(`&subPage=addAdressen`, '');
      } else if (newUrl.includes('subPage=editAdressen')) {
        this.editId = decodeURIComponent(params.get("_id"));
        newUrl = newUrl.replace(`&subPage=editAdressen&_id=${this.editId}`, "");

        let addType = decodeURIComponent(params.get("addressType"));
        newUrl = newUrl.replace(`&addressType=${addType}`, "");

        let addressIndex = decodeURIComponent(params.get("addressIndex"));
        newUrl = newUrl.replace(`&addressIndex=${addressIndex}`, "");
      } else if (newUrl.includes('subPage=showDetails')) {
        this.orderIndex = decodeURIComponent(params.get("orderIndex"));
        newUrl = newUrl.replace(`&subPage=showDetails&orderIndex=${this.orderIndex}`, "");
      } else if (newUrl.includes('subPage=showInvoice')) {
        this.editId = decodeURIComponent(params.get("_id"));
        newUrl = newUrl.replace(`&subPage=showInvoice&_id=${this.editId}`, "");
      } else if (newUrl.includes('subPage=emilEdit')) {
        newUrl = newUrl.replace(`&subPage=emilEdit`, "");
      } else if (newUrl.includes('subPage=passwordEdit')) {
        newUrl = newUrl.replace(`&subPage=passwordEdit`, "");
      } else if (newUrl.includes('subPage=bankingDetailsEdit')) {
        newUrl = newUrl.replace(`&subPage=bankingDetailsEdit`, "");
      } else if (newUrl.includes('subPage=newsLetterEdit')) {
        newUrl = newUrl.replace(`&subPage=newsLetterEdit`, "");
      } else if (newUrl.includes('subPage=createPricealaram')) {
        newUrl = newUrl.replace(`&subPage=createPricealaram`, "");
      } else if (newUrl.includes('subPage=addOrEditProfile')) {
        newUrl = newUrl.replace(`&subPage=addOrEditProfile`, "");
      }
    };
    window.history.replaceState('', document.title, newUrl);
  };

  removeQueryParams(paramValues) {
    let url = this._window.location.href;
    let regex = `&${paramValues}`;

    if (url.includes(regex)) {
      window.history.pushState('', document.title, url);
      url = url.replace(regex, '');
      window.history.replaceState('', document.title, url);
    };
  };



  getPcLink() {
    let pcLink = localStorage.getItem("priceCalcUrl");
    return pcLink;
  };

  isSafari(): boolean {
    const userAgent = window.navigator.userAgent;
    return /Safari/i.test(userAgent) && !/Chrome|CriOS|FxiOS|OPiOS/i.test(userAgent);
  }
  collectParamas(url: string){
    let params = new URLSearchParams(this.url); 
    console.log(params,346);
    return decodeURIComponent(params.get("orderStatus"));
  }
}

export interface UrlParameters {
  url: string;
  Liter: string;
  Product: string;
  view: string;
  Lieferort: string;
  domainname: string;
};