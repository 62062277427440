<div class="aupris_main scrolltotopprogress stage">
    <div class="au-container section-container">
        <div class="aurow ml0 mr0">
            <div class=" aucol-lg-4 aucol-md-4  aucol-sm-4 aucol-xs-12 " style="padding-bottom:6px">
                <div class="card card2" style="text-align: center;text-align: -webkit-center;">
                    <div class="profile pointt" style=" padding: 2px 0px;">
                        <div
                            style="text-align: center;font-weight:small;font-family: Helvetica Neue, Helvetica, Arial, sans-serif; font-size:16px;padding-bottom: 0px;">
                            <span class="widthh" *ngIf="!editMode">{{Vorname}} <br> {{Name}} </span>
                            <input type="text" style="color:#555 !important;" class="editmode" *ngIf="editMode"
                                autocomplete="off" [(ngModel)]="Vorname" name="first">
                            <input type="text" style="color:#555 !important;" class="editmode" *ngIf="editMode"
                                autocomplete="off" [(ngModel)]="Name" name="first">
                            <span *ngIf="!editMode" (click)="editMode=true ">

                                <img class="width"
                                    style="padding-left: 5px;margin-bottom:24px;cursor:pointer;margin-top:21px;"
                                    [src]="'assets/ic_edit_24px.svg' | assetsUrl">
                            </span>
                            <span class="editmode mode" class="control " style="cursor:pointer;" *ngIf="editMode"
                                (click)="editMode=false; user(Vorname,Name)"
                                [ngStyle]="{background: 'var(--primaryColour)'}">
                                <img class="width"
                                    style="padding-left:5px;margin-bottom:24px !important;cursor:pointer;margin-top:21px !important;"
                                    [src]="'assets/svgs/tick.svg' | assetsUrl">
                            </span>
                        </div>

                        <div *ngIf="userProfileImage"
                            style="align-items: center;display: inline-grid;padding-top: 3px;margin-bottom: 5px !important;">
                            <img style="width:142px;height:142px;border-radius:50%;" [src]="userProfileImage"
                                onError="this.src='/assets/imgs/Spinner.svg' | assetsUrl">
                        </div>

                        <div class="name" style="margin-bottom: 0px !important;" *ngIf="!userProfileImage">
                            {{userinfo?.Vorname | nameInitials : userinfo?.Name}}
                        </div>

                        <div>
                            <button class="au-btn btn-default" style="outline: none;"
                                [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                                (click)="editProfileImage()">
                                {{languageData['Hamburger'].Var_Foto_bearbeiten || 'Foto bearbeiten'}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class=" aucol-lg-8 aucol-md-8 aucol-sm-8 aucol-xs-12 card4"
                style="padding-bottom: 20px;margin-left:0px">
                <div class="card" style="padding-left:0px;">
                    <div style="padding: 15px 20px 0px 20px;">
                        <div class="card-title1">
                            Email
                        </div>
                        <br *ngIf="editEmail" />
                        <span *ngIf="editEmail"> {{orginalEmail}}</span>

                        <div *ngIf="!editEmail" style="padding-bottom: 17px;padding-top:12px; padding-right: 20px; width: 55%;  min-width: 270px;
                                color: #b3aeae;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;">
                            {{email}}
                            <img class="editt pencil" (click)="editEmailHandler()"
                                [src]="'assets/ic_edit_24px.svg'| assetsUrl">
                            <div class="tooltipppp tippp" style="float: right;top: -2px;right: 10px;"
                                *ngIf="cautionicon">
                                <img style="height: 20px !important;
                                    width: 20px !important;
                                    float: right;cursor: pointer;"
                                    [src]="'assets/Caution-fillied-sign.svg' | assetsUrl">
                                <span class="tooltiptextttt " style=" top: 20px !important;">
                                    <pre style="background: white;color:#555;word-break: unset !important;">
                                        {{langData['kundeportal']['KundenEinstellungen']['VarKundeportalKundenEinstellungen7']}}
                                      </pre>
                                </span>
                            </div>
                        </div>
                        
                        <div style="margin-top: 15px;" *ngIf="editEmail">

                            <label class="posAbs  aupLabel">
                                Email
                            </label>

                            <div [formGroup]="newEmail">
                                <div>
                                    <input type="text" formControlName="newEmail" class="auform-control "
                                        autocomplete="off"
                                        style="color:#555 !important;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;  border: 1px solid #a99e9e"
                                        placeholder="Email">
                                    <div class="alert-danger" *ngIf="emailValidate ">
                                        {{langData['kundeportal']['KundenEinstellungen']['VarKundeportalKundenEinstellungen3']||"Bitte
                                        gültige e-Mail eintragen!"}}
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <label class="posAbs  aupLabel">
                                        Repeat Email
                                    </label>
                                </div>
                                <div class="auform-group">
                                    <input type="text" formControlName="repeatNewEmail" class="auform-control "
                                        autocomplete="off"
                                        style="color:#555 !important;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;  border: 1px solid #a99e9e"
                                        placeholder="Email">

                                    <div class="alert-danger" *ngIf="repeatEmailValidate">
                                        {{langData['kundeportal']['KundenEinstellungen']['VarKundeportalKundenEinstellungen4']||"e-Mail
                                        stimmt nicht überein!"}}
                                    </div>
                                </div>
                            </div>

                            <div class="aurow mr0 ml0">
                                <div style="margin-bottom:18px">
                                    <button class="au-btn btn-default cancelButton" (click)="cancelEmailBtn()">
                                        {{languageData['Hamburger'].Var_cancel || 'Abbrechen'}} </button>
                                    <button class="au-btn"
                                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                                        style="margin-left:10px;" [disabled]="newEmail.invalid"
                                        (click)="!newEmail.controls.newEmail.valid && editEmail = !editEmail; saveEmail()">
                                        {{languageData['Hamburger'].Var_save || 'Speichern'}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [formGroup]="resetPasswordForm">
                        <div class="icon">

                            <div class="card-title1">
                                {{languageData['Hamburger'].Var_password || 'Passwort'}}
                            </div>
                            <div style="padding-bottom: 6px;padding-top:12px;color: #b3aeae;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;"
                                *ngIf="!editPassword">
                                {{languageData['Hamburger'].Var_changepassword || 'Passwort ändern'}}
                            </div>

                            <div class="card-title1"
                                style="padding-bottom: 12px;padding-top:0px;margin-left: 1px;color: #b3aeae;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;"
                                *ngIf="!editPassword"> <span *ngIf="!editPassword"></span>
                                <span>
                                    <img class="edit " (click)="editPswordHandler()" style="margin-top: -28px;"
                                        [src]="'assets/ic_edit_24px.svg'  | assetsUrl">
                                </span>
                            </div>
                        </div>

                        <div style=" display: flex;">
                        <div *ngIf="editPassword" style='padding-left:20px;margin-right: 5px;width: 50%'>
                            <div style="margin-bottom: 20px;" *ngIf="editPassword">

                                <label class="posAbs  aupLabel">
                                    {{languageData['Hamburger'].Var_oldpassword || 'Altes Passwort'}}
                                </label>
                                <input type="password" formControlName="oldpassword" autocomplete="off"
                                    style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif;color:#555 !important; border: 1px solid #a99e9e;min-width: 160px !important;"
                                    name="first" class="auform-control" [type]="!isShow ? 'password' : 'text'">
                                    <img class="eyeIcon" *ngIf="isShow" (click)="isShow = !isShow"
                                src="../../../assets/show Password.svg">
                            <img class="eyeIcon" *ngIf="!isShow" (click)="isShow = !isShow"
                                src="../../../assets/hide Password.svg">
                                <div class="alert-danger"
                                    *ngIf="!resetPasswordForm.controls.oldpassword.valid && resetPasswordForm.controls.oldpassword.touched">

                                    {{languageData['Hamburger'].Var_passwordrequired || ' Passwort wird benötigt'}}
                                </div>
                                <div class="alert-danger" *ngIf="user_not_found">
                                    {{languageData?.login['Var_'+loginErrorMessage] || (loginErrorMessage)}}</div>
                            </div>
                                
                            <div style="margin-bottom: 20px;" *ngIf="editPassword">
                                <label class="posAbs  aupLabel">
                                    {{languageData['Hamburger'].Var_newpassword || 'Neues Passwort'}}
                                </label>
                                <input type="password" formControlName="newpassword" autocomplete="off"
                                    style="border: 1px solid #a99e9e;color:#555 !important;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;min-width: 160px !important;"
                                    name="first" class="auform-control" [type]="!isShoww ? 'password' : 'text'">
                                    <img class="eyeIcon" *ngIf="isShoww" (click)="isShoww = !isShoww"
                                src="../../../assets/show Password.svg">
                            <img class="eyeIcon" *ngIf="!isShoww" (click)="isShoww = !isShoww"
                                src="../../../assets/hide Password.svg">
                                <div class="alert-danger"
                                    *ngIf="!resetPasswordForm.controls.newpassword.valid && resetPasswordForm.controls.newpassword.touched">
                                    <!-- password is required -->
                                     <div *ngIf="resetPasswordForm.controls.newpassword.required">
                                        {{languageData['Hamburger'].Var_passwordrequired || ' Passwort wird benötigt'}}
                                     </div>
                                     <!-- <div *ngIf="resetPasswordForm.controls.newpassword.errors.pattern"> -->
                                     <!-- password is invalid format -->
                                     <!-- Invalid Format -->
                                 <!-- </div> -->
                                </div>
                            </div>
                            <div style="margin-bottom: 20px;" *ngIf="editPassword">

                                <label class="posAbs  aupLabel">
                                    {{languageData['Hamburger'].Var_confirmpassword || 'Passwort wiederholen'}}
                                </label>
                                <input type="password" formControlName="re_password" autocomplete="off"
                                    style="border: 1px solid #a99e9e;color:#555 !important;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;min-width: 160px !important;"
                                    name="first" class="auform-control" [type]="!isShowww ? 'password' : 'text'">
                                    <img class="eyeIcon" *ngIf="isShowww" (click)="isShowww = !isShowww"
                                    src="../../../assets/show Password.svg">
                                <img class="eyeIcon" *ngIf="!isShowww" (click)="isShowww = !isShowww"
                                    src="../../../assets/hide Password.svg">

                                <div class="alert-danger"
                                    *ngIf="!resetPasswordForm.controls.re_password.valid && resetPasswordForm.controls.re_password.touched">
                                    <!-- Repeat Password -->
                                    {{languageData['Hamburger'].Var_repeatpassword || 'Passwort wiederholen'}}
                                </div>
                                <div class="alert-danger"
                                    *ngIf="resetPasswordForm.controls.re_password.valid && resetPasswordForm.errors && resetPasswordForm.controls.re_password.touched">
                                    <!-- passwords don't match -->
                                    {{languageData['Hamburger'].Var_matchpassword || 'Passwort stimmt nicht überein'}}
                                </div>
                            </div>
                            <div class="aurow mr0 ml0">
                                <div style="margin-bottom:22px;display: flex;">
                                    <button class="au-btn btn-default cancelButton" (click)="cancelPasswordSection()">
                                        {{ languageData['Hamburger'].Var_cancel || 'Abbrechen' }}
                                    </button>

                                    <button class="au-btn" type="submit"
                                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                                        style="margin-right:20px;" [disabled]="!resetPasswordForm.valid"
                                        (click)="savePassword()">
                                        {{ languageData['Hamburger'].Var_save || 'Speichern' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style="min-width: 250px;position: relative;top: 51px;" *ngIf="editPassword">
                            <div style="display: flex;" *ngIf="!(resetPasswordForm.get('newpassword').value?.length >= 8)"><img src="../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin4'] || 'Mind. 8 Zeichen'}}</span></div>
                            <div style="display: flex;" *ngIf="(resetPasswordForm.get('newpassword').value?.length >= 8)"><img src="../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin4'] || 'Mind. 8 Zeichen'}}</span></div>
                            <div style="display: flex;" *ngIf="!(resetPasswordForm.get('newpassword').value?.length <= 20)"><img src="../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin9'] || 'Max. 20 Zeichen'}}</span></div>
                            <div style="display: flex;" *ngIf="!(checkValidity(resetPasswordForm.get('newpassword').value, 'uppercase'))"><img src="../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin5'] || 'Mind. 1 Großbuchstabe'}}</span></div>
                            <div style="display: flex;" *ngIf="(checkValidity(resetPasswordForm.get('newpassword').value, 'uppercase'))"><img src="../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin5'] || 'Mind. 1 Großbuchstabe'}}</span></div>
                            <div style="display: flex;" *ngIf="!(checkValidity(resetPasswordForm.get('newpassword').value, 'lowercase'))"><img src="../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin6'] || 'Mind. 1 Kleinbuchstabe'}}</span></div>
                            <div style="display: flex;" *ngIf="(checkValidity(resetPasswordForm.get('newpassword').value, 'lowercase'))"><img src="../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin6'] || 'Mind. 1 Kleinbuchstabe'}}</span></div>
                            <div style="display: flex;" *ngIf="!(checkValidity(resetPasswordForm.get('newpassword').value, 'numeric'))"><img src="../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin7'] || 'Mind. 1 Zahl'}}</span></div>
                            <div style="display: flex;" *ngIf="(checkValidity(resetPasswordForm.get('newpassword').value, 'numeric'))"><img src="../../assets/ta_checkMark_Icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: green;">{{languageDataCheck['Login']['VarKundeportalLogin7'] || 'Mind. 1 Zahl'}}</span></div>
                            <div style="display: flex;" *ngIf="(checkValidity(resetPasswordForm.get('newpassword').value, 'specialchar'))"><img src="../../assets/ta_cancel_icon.svg" class="ta_cancel_icon">&nbsp;<span style="color: red;">{{languageDataCheck['Login']['VarKundeportalLogin8'] || 'Keine Sonderzeichen erlaubt'}}</span></div>
                          </div>
                        </div>
                        <div class="icon">
                            <div class="card-title1">
                                {{languageData['Hamburger'].Var_bankdetails || 'Bankverbindung'}}
                            </div>
                            <div style="padding-bottom: 6px;padding-top:12px;color: #b3aeae;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;"
                                *ngIf="!editBankdetails">
                                {{languageData['Hamburger'].Var_changeBankdetailsStatus || 'Bankverbindung ändern'}}
                            </div>

                            <div class="card-title1"
                                style="padding-bottom: 12px;margin-left: 1px;color: #b3aeae;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;"
                                *ngIf="!editBankdetails"> <span *ngIf="!editBankdetails"></span>
                                <span>
                                    <img class="edit " style="margin-top: -28px;"
                                        [src]="'assets/ic_edit_24px.svg' | assetsUrl" (click)="editBankingHandler()">
                                </span>
                            </div>
                        </div>

                        <div *ngIf="editBankdetails" style='padding-left:19px;'>
                            <form [formGroup]="BankverbindungForm" style="margin-right: 3%;">
                                <div class="auform-group field" style="margin-top:20px;">
                                    <div style="width: 100%; display: table;">
                                        <div style="display: table-row">
                                            <div style="display: table-cell;">
                                                <label class="posAbs aupLabel" *ngIf="lang['Labels']">{{
                                                    lang['Labels']['Var_Bankverbindung_Kto'] ||'Kto. Inhaber'}}*</label>
                                                <input type="text" class="auform-control forLabel"
                                                    formControlName="KtoInhaber" [(ngModel)]="KtoInhaber"
                                                    (keyup)="editchangeBankdetails()">
                                            </div>

                                        </div>
                                        <div *ngIf="BankverbindungForm.controls.KtoInhaber.touched && BankverbindungForm.controls.KtoInhaber.status =='INVALID'"
                                            class="alert-danger">
                                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld
                                            ausfüllen'}}!
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div style="margin: 10px;">
                                    </div>

                                    <div class="auform-group field" style="margin-top:20px;">
                                        <div style="width: 100%; display: table;">
                                            <div style="display: table-row">
                                                <div style="display: table-cell;">
                                                    <label class="posAbs aupLabel">IBAN*</label>
                                                    <input class="auform-control forLabel" formControlName="IBAN"
                                                        [(ngModel)]="IBAN" (blur)="checkIban();"
                                                        (keyup)="ibanKeyUp($event);editchangeBankdetails()">
                                                </div>
                                                <div style="position: absolute;right: 24px;margin-top: 5px;"
                                                    *ngIf="loader" class="loader"> </div>
                                            </div>
                                        </div>
                                        <div class='alert-danger'>
                                            {{msg}}
                                        </div>
                                        <div *ngIf="BankverbindungForm.controls.IBAN.touched && BankverbindungForm.controls.IBAN.status == 'INVALID'"
                                            class="alert-danger">
                                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld
                                            ausfüllen'}}!
                                        </div>
                                    </div>
                                    <div class="auform-group field">
                                        <label class="posAbs aupLabel">BIC*</label>
                                        <input class="auform-control forLabel" formControlName="BIC" [(ngModel)]="BIC"
                                            disabled>
                                        <div *ngIf="(BankverbindungForm.controls.bic?.errors && 
                              (BankverbindungForm.controls.bic.touched)) ||
                              mandatoryCheck == true && 
                              BankverbindungForm.controls.bic.status == 'INVALID'" class="alert-danger">
                                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld
                                            ausfüllen'}}!
                                        </div>
                                    </div>
                                    <div class="auform-group field">
                                        <label class="posAbs aupLabel">
                                            {{languageData['Adresseingabe'].Var_finance || 'Finanzinstitut'}}*
                                        </label>
                                        <input class="auform-control forLabel" formControlName="bank_name"
                                            [(ngModel)]="bank_name" disabled>
                                        <div *ngIf="(BankverbindungForm.controls.bank_name?.errors && 
                              (BankverbindungForm.controls.bank_name.touched)) ||
                              mandatoryCheck == true && 
                              BankverbindungForm.controls.bank_name.status == 'INVALID'" class="alert-danger">
                                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld
                                            ausfüllen'}}!
                                        </div>
                                    </div>
                                    <div class="edge">
                                        <h5 style="text-align: center;">
                                            - oder -
                                        </h5>
                                    </div>
                                    <div class="auform-group field" style="margin-top:20px;">
                                        <label class="posAbs aupLabel">
                                            {{languageData['Adresseingabe'].Var_accountno || 'Kontonummer'}}*
                                        </label>
                                        <input class="auform-control forLabel" type="number"
                                            formControlName="account_number" (blur)="checkAccount()"
                                            (keyup)="accountKeyUp($event);editchangeBankdetails()"
                                            [(ngModel)]="account_number">
                                        <div
                                            *ngIf="BankverbindungForm.controls.account_number.touched &&  BankverbindungForm.controls.account_number.status == 'INVALID'"
                                            class="alert-danger">
                                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld
                                            ausfüllen'}}!
                                        </div>

                                    </div>
                                    <div class="auform-group field">
                                        <label class="posAbs aupLabel">
                                            {{languageData['Adresseingabe'].Var_bankcode || 'Bankleitzahl'}}*
                                        </label>
                                        <input class="auform-control forLabel" formControlName="bank_code"
                                            (blur)="checkAccount()"
                                            (keypress)="validate($event);editchangeBankdetails()"
                                            [(ngModel)]="bank_code">
                                        <div *ngIf="(BankverbindungForm.controls.bank_code?.errors && 
                              (BankverbindungForm.controls.bank_code.touched)) ||
                              mandatoryCheck == true && 
                              BankverbindungForm.controls.bank_code.status == 'INVALID'" class="alert-danger">
                                            {{languageData['Adresseingabe'].Var_Pflichtfeld_ausfullen || 'Pflichtfeld
                                            ausfüllen'}}!
                                        </div>
                                        <div class='alert-danger' *ngIf="error == true">
                                            {{msgg}}
                                        </div>
                                    </div>

                                </div>
                            </form>
                            <div class="aurow mr0 ml0">
                                <div style="margin-bottom:22px">
                                    <button class="au-btn btn-default cancelButton"
                                        (click)="cancelBankingDetailsSection()">
                                        {{ languageData['Hamburger'].Var_cancel || 'Abbrechen' }}</button>
                                    <button class="au-btn" type="submit"
                                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                                        [disabled]="!changeBankdetails" style="margin-right:20px;"
                                        (click)="saveNewBankdetails()">
                                        {{ languageData['Hamburger'].Var_save || 'Speichern' }}</button>
                                </div>
                            </div>
                        </div>

                        <div class="icon">

                            <div class="card-title1">
                                {{languageData['Hamburger'].Var_newsletter || 'Newsletter'}}
                            </div>
                            <div style="padding-bottom: 6px;padding-top:12px;color: #b3aeae;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;"
                                *ngIf="!editNewsletter">
                                {{languageData['Hamburger'].Var_changeNewsletterStatus || 'Nesletter an-/abmelden'}}
                            </div>

                            <div class="card-title1"
                                style="padding-bottom: 12px;margin-left: 1px;color: #b3aeae;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;"
                                *ngIf="!editNewsletter"> <span *ngIf="!editNewsletter"></span>
                                <span>
                                    <img class="edit " style="margin-top: -28px;"
                                        [src]="'assets/ic_edit_24px.svg' | assetsUrl" (click)="editNewsLetterSection()">
                                </span>
                            </div>
                        </div>


                        <div *ngIf="editNewsletter" style='padding-left:19px;'>
                            <div style="margin-bottom: 20px;" *ngIf="editNewsletter">
                                <div class="checkSec">
                                    <label class="checkbox-label" [ngClass]="{checked:checkEmailMarketingNewsletter}">
                                        <input type="checkbox" class="checkBox"
                                            [checked]="checkEmailMarketingNewsletter"
                                            (change)="emailMarketingChecked($event.target.checked)" />
                                        <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                                            <span class="checkbox-tick"
                                                [ngStyle]="{'border-color': 'black','margin': '-1px'}">
                                            </span>
                                        </span>
                                    </label>
                                    <span class="checkDesc" style=" color: #b3aeae;">
                                        {{languageData['Hamburger'].Var_newsletterActive || 'Newsletter aktiviert'}}
                                    </span>
                                </div>
                                <div class="checkSec">
                                    <label class="checkbox-label" [ngClass]="{checked:!checkEmailMarketingNewsletter}">
                                        <input type="checkbox" class="checkBox"
                                            [checked]="!checkEmailMarketingNewsletter"
                                            (change)="emailMarketingChecked(!$event.target.checked)" />
                                        <span class="checkbox-bg" [ngStyle]="{'background-color':  'white'}">
                                            <span class="checkbox-tick"
                                                [ngStyle]="{'border-color': 'black','margin': '-1px'}">
                                            </span>
                                        </span>
                                    </label>
                                    <span class="checkDesc" style=" color: #b3aeae;">
                                        {{languageData['Hamburger'].Var_newsletterInactive || 'Newsletter deaktiviert'}}
                                    </span>
                                </div>
                            </div>

                            <div class="aurow mr0 ml0">
                                <div style="margin-bottom:22px">
                                    <button class="au-btn btn-default cancelButton" (click)="canceNewsletterBtn()">
                                        {{ languageData['Hamburger'].Var_cancel || 'Abbrechen' }}
                                    </button>

                                    <button class="au-btn" type="submit"
                                        [ngStyle]="{background: 'var(--primaryColour)',color: 'var(--primaryTextColour)'}"
                                        [disabled]="!changeEmailMarketing" style="margin-right:20px;"
                                        (click)="editNewsletter = !editNewsletter; saveNewEmailMarketing()">
                                        {{ languageData['Hamburger'].Var_save || 'Speichern' }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="subscribeText" class="snackbars">
                            {{languageData['Hamburger'].Var_newsletterActiveSnackbar ||'Sie haben sich erfolgreich zum
                            Newsletter angemeldet!'}}
                        </div>
                        <div *ngIf="unSubscribeText" class="snackbars">
                            {{languageData['Hamburger'].Var_newsletterDeactiveSnackbar ||'Sie haben sich erfolgreich vom
                            Newsletter abgemeldet!'}}
                        </div>



                        <div class="icon"
                            style="padding-bottom: 25px;color: gray;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;">
                            {{languageData['Hamburger'].Var_OnlineKonto || 'Online Konto'}} <span class="edit"
                                id="generator">AP{{onlineId}}</span></div>

                        <div class="icon"
                            style="padding-bottom:31px;color: gray;font-family: Helvetica Neue, Helvetica, Arial, sans-serif;">
                            {{languageData['Hamburger'].Var_Kundennummer || 'Kundennummer'}}
                            <span class="edit">{{kundennummer}}
                            </span>
                        </div>

                        <div class="oAD">
                            <a (click)="delelteonlineaccount()" style="color: gray;">
                                {{languageData?.login?.Var_delete_online_account || 'Online Konto löschen'}}</a>
                        </div>
                    </div>

                    <div [ngClass]="{snackbar: true, show: showSnack}"> {{ snackbarMsg }}</div>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <loader [full-screen]="true" *ngIf="loaderForScreen" [color]="'var(--primaryColour)'"></loader>
</div>